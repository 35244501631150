import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchHashtags from "../../components/search/SearchHashtags";
import { Filter } from "./Filter";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import { useLocation } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { getAllSearchAgainstKeywordApi } from "../../services/searchApis";
import InfiniteScroll from "react-infinite-scroller";
import { JobCard } from "../../components/search/JobsResultComp";
import { toast } from "react-toastify";
import { ShimmerText } from "react-shimmer-effects";
import { useContext } from "react";
import { LoginUserData } from "../../App";

export default function JobsSearch() {
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  const { splashData } = useContext(LoginUserData);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["search-jobs", keyword],
      queryFn: ({ pageParam = 1 }) =>
        getAllSearchAgainstKeywordApi(keyword, "jobs", pageParam),
      enabled: !!keyword,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const jobs = data?.pages?.flatMap((page) => page.data.data);
  const ads = data?.pages?.flatMap((page) => page.ads);
  const hashTags = data?.pages?.flatMap((page) => page.hash_tags);
  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
          <div className="position-relative px-0">
            <Filter
              title="Jobs"
              options={[
                { title: "Category", options: splashData?.home?.categories },
                {
                  title: "Subcategory",
                  options: splashData?.home?.subcategories,
                },
                { title: "Hospital", options: [] },
                { title: "Location", options: [] },
              ]}
            />
          </div>
        </Row>
        <div className="d-flex justify-content-center gap-3">
          <div>
            <div
              className="position-sticky"
              style={{ width: "335px", top: "8.2rem" }}
            >
              <FeedAdvertisement ads={ads} />
            </div>
          </div>
          <div>
            <JobsList
              loading={isLoading}
              data={jobs}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
          <div className="d-flex flex-column gap-3" style={{ width: "335px" }}>
            <SearchHashtags data={hashTags} />
            <FeedAdvertisement ads={ads} />
          </div>
        </div>
      </Container>
    </>
  );
}

const JobsList = ({
  loading,
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  return (
    <div
      className="bg-white border rounded"
      style={{ marginTop: "1rem", width: "570px" }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">Jobs</h5>
      </div>
      <div className="p-2">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!isFetchingNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={
            <div className="p-2">
              <ShimmerText line={5} gap={10} />
            </div>
          }
        >
          {loading
            ? [1, 2, 3, 4, 5].map((item, index) => (
                <div className="p-2" key={index}>
                  <ShimmerText line={5} gap={10} />
                </div>
              ))
            : data?.map((item, index) => (
                <div className="p-2" key={index}>
                  <JobCard job={item} />
                </div>
              ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
