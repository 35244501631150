import { _imagesURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import ImageComponent from "../common/ImageComponent";

const Message = ({ text, time, isSender, attachment, showAvatar, showTime, avatar, message, isGroup, fullname }) => {
  return (
    <>
      <div
        className={`d-flex align-self-${isSender ? "end" : "start"}`}
        style={{ maxWidth: "50%" }}
      >
        {/* Conditionally render the avatar only if it's a group chat and the user is not the sender */}
        {isGroup && !isSender && showAvatar && (
          <img
            src={avatar ? `${_imagesURL}${avatar}` : Avatar}
            alt="Avatar"
            style={{
              maxWidth: "25px",
              maxHeight: "25px",
              borderRadius: "8px",
              marginRight: "5px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)"
            }}
          />
        )}
        <div
          className={`${attachment ? "p-0 " : " py-1"} border`}
          style={{
            backgroundColor: isSender ? "#1DC1DD" : "#F5F5F5",
            borderRadius: `10px 10px ${isSender ? "0px 10px" : "10px 0px"}`,
          }}
        >
          {attachment && (
            <div
              onClick={() => {
                window.open(`${_imagesURL}${attachment}`, "_blank");
              }}
            >
              <ImageComponent
                src={`${_imagesURL}${attachment}`}
                compClassName="cursor object-cover rounded"
                img_width="300px"
                img_height="300px"
                loader_height="300px"
                loader_width="300px"
              />
            </div>
          )}
          {text && (
            <p
              className={`mb-0 mx-1 roboto-medium fs-14 ${
                isSender ? "text-white" : ""
              }`}
            >
              <span style={{ whiteSpace: "pre-wrap" }}>{text}</span>
            </p>
          )}
        </div>
      </div>
      {showTime && (
        <p
          className={`mb-0 roboto-regular fs-12 text-secondary ${
            isSender ? "text-end" : ""
          }`}
        >
          {/* Render the fullname and time if the user is not the sender */}
          {isGroup && !isSender && <span>{fullname} - </span>}
          {new Date(time * 1000).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      )}
    </>
  );
};

export default Message;
