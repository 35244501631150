import React from "react";
// import CourseCard from "./CourseCard";
import { fetchClinicalSessionData, fetchPurchasedCourses } from "../../services/coursesApi";
import { toast } from "react-toastify";
import { ShimmerPostItem } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery, useQuery } from "react-query";
import CourseCard from "../../components/courses/CourseCard";
import ClinicalSessionCard from "./ClinicalSessionCard";
import ShimmerClinicalSessionCard from "./ShimmerClinicalSessionCard";

const InvitedSessions = ({ count }) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ["invitedClinicalSessionData"],
        queryFn: () => fetchClinicalSessionData({type: "invited"}),
        refetchOnWindowFocus: false, 
      });
      if (isError) {
        toast.error("Error fetching Clinical Session data");
      }
  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-wrap justify-content-start gap-3">
        {new Array(count).fill(0).map((_, index) => (
          <div className="clinical_card" key={index}>
           <ShimmerClinicalSessionCard/>
          </div>
        ))}
      </div>
      ) : (
        
            <div className="mt-2">
            <div className="d-flex flex-wrap justify-content-start gap-3">
              {data&&data?.data.length>0 && data?.data?.map((session, index) => (
                <div key={index} className="clinical_card">
                  <ClinicalSessionCard session={session} />
                </div>
              ))}
            </div>
          </div>
      )}
        
     
    </>
  );
};

export default InvitedSessions;
