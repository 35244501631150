import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import './PayPalDetailsModal.css'; // Import custom styles for this modal

const PayPalDetailsModal = ({ isOpen, toggle, email, onRemove,isLoading }) => {
    // useEffect(() => {
    //     if (!email && isOpen) {
    //       window.location.href = process.env.REACT_APP_PAYPAL_URL; // Replace with the desired URL
    //     }
    //   }, [email, isOpen]);
    useEffect(() => {
        if (!email && isOpen) {
            const redirectUri = `${window.location.origin}/paypal-callback`; // Define the callback route
            const paypalLoginUrl = `${process.env.REACT_APP_PAYPAL_URL}`
            // &redirect_uri=${encodeURIComponent(redirectUri)}`;
            console.log("paypalLoginUrl",paypalLoginUrl)
            window.location.href = paypalLoginUrl; // Redirects to PayPal login

        }
    }, [email, isOpen]);
  return (
    <Modal isOpen={isOpen && email} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>PayPal Details</ModalHeader>
      <ModalBody>
        <p>Your connected PayPal account is:</p>
        <p className="paypal-email">{email}</p>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Col>
            <button className='btn  p-3 w-100' style={{backgroundColor:"#031316",color:"#ffff"}}  onClick={onRemove}>
            {isLoading ? 'Removing...' : ' Remove Account'}   
            </button>
          </Col>
          <Col>
            <Button className='btn  p-3 w-100' style={{backgroundColor:"#1DC1DD",color:"#ffff"}} onClick={toggle}>
              Okay
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default PayPalDetailsModal;
