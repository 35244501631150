import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Search from "../../assets/images/icon/search.png";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { Language, LoginUserData } from "../../App";
import axios from "axios";
import { _imagesURL, adminBaseURL, GetGroupParticipants } from "../../constants/API";
import { toast } from "react-toastify";
import ImageComponent from "../common/ImageComponent";
import { CreateGroupModalContext } from "./CreateGroupModal";
import { ChatsContext } from "./ChatsProvider";
import { getToken } from "../../shared/token";
import { debounce } from "lodash";

export default function AddParticipantsModal({ isOpen, toggle, clearInputs, createModalToggle }) {
  const { groupImage, groupName } = useContext(CreateGroupModalContext);
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const { fetchChatss } = useContext(ChatsContext);

  const [state, setState] = useState({
    search: "",
    isLoading: false,
    page: 1,
    searchPage: 1,
    hasMoreFollowing: true,
    hasMoreSearchResults: true,
    groupParticipants: [],
    selectedParticipants: [],
    searchResults: [],
  });
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const {
    search,
    isLoading,
    page,
    searchPage,
    hasMoreFollowing,
    hasMoreSearchResults,
    groupParticipants,
    searchResults,
    selectedParticipants,
  } = state;

  const authorize = {
    headers: {
      Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },
  };

  const handleCheckboxChange = (item) => {
    const userId = item.id;
    setState((prevState) => {
      const isSelected = prevState.selectedParticipants.includes(userId);
      return {
        ...prevState,
        selectedParticipants: isSelected
          ? prevState.selectedParticipants.filter((id) => id !== userId)
          : [...prevState.selectedParticipants, userId],
      };
    });
  };

  const handleGroupImage = async (id) => {
    await axios.post(
      `${adminBaseURL}user/group-chat/update`,
      {
        group_id: id,
        group_name: groupName,
        image: groupImage,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  };

  const handleCreateGroup = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${adminBaseURL}user/group-chat/create`,
        {
          group_name: groupName,
          user: splashData?.user?.id,
          users: selectedParticipants,
        },
        authorize
      );

      if (res.data.status) {
        if (groupImage) {
          await handleGroupImage(res?.data?.data?.id);
        }
        setLoading(false);
        await fetchChatss();
        toggle();
        clearInputs();
        createModalToggle();
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (searchValue, searchPage) => {
      if (!searchValue.trim()) {
        setState((prevState) => ({ ...prevState, searchResults: [], hasMoreSearchResults: true }));
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      try {
        const response = await axios.post(
          `${adminBaseURL}user/global/search?page=${searchPage}`,
          {
            keyword: searchValue,
            type: "user",
          },
          authorize
        );
        
        const updatedSearchResults = response.data.data.data;
        setState((prevState) => ({
          ...prevState,
          searchResults:
            searchPage === 1
              ? updatedSearchResults
              : [...prevState.searchResults, ...updatedSearchResults],
          hasMoreSearchResults: response.data.data.current_page < response.data.data.last_page,
        }));
      } catch (error) {
        toast.error("Failed to fetch search results. Please try again.");
      } finally {
        setIsSearching(false);
      }
    }, 500),
    [splashData?.user?.id]
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setState((prevState) => ({ ...prevState, search: searchValue, searchPage: 1 }));
    debouncedSearch(searchValue, 1);
  };

  const fetchData = useCallback(
    debounce(async () => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const res = await axios.get(
          `${GetGroupParticipants}?page=${page}`,
          authorize
        );

        const updatedValue = res?.data?.data?.data;
        setState((prevState) => ({
          ...prevState,
          groupParticipants:
            page === 1
              ? updatedValue
              : [...prevState.groupParticipants, ...updatedValue],
          hasMoreFollowing:
            res?.data?.data?.current_page < res?.data?.data?.last_page,
          isLoading: false,
        }));
      } catch (err) {
        toast.error(err?.response?.data?.message);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    }, 1000),
    [page]
  );

  useEffect(() => {
    if (hasMoreFollowing && !search) {
      fetchData();
    }
  }, [page, fetchData, hasMoreFollowing, search]);

  const loadMore = useCallback(() => {
    if (search) {
      setState((prevState) => ({
        ...prevState,
        searchPage: prevState.searchPage + 1,
      }));
      debouncedSearch(search, searchPage + 1);
    } else {
      setState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [search, searchPage, page, debouncedSearch]);

  const renderParticipants = (participants) =>
    participants.map((item) => (
      <div
        className="d-flex align-items-center justify-content-between p-2 border-bottom border-inherit"
        key={item.id}
      >
        <div className="d-flex align-items-center gap-3">
          <div>
            <ImageComponent
              src={item.image ? _imagesURL + item.image : Avatar}
              alt="..."
              loader_height="60px"
              loader_width="60px"
              img_height="60px"
              img_width="60px"
              compClassName="rounded-circle"
              roundShimmerSize={40}
            />
          </div>
          <div>
            <h3 className="fs-18 fs-md-15 roboto-medium mb-0 text-ellips-110px">
              {item.name}
            </h3>
            <h6 className="fs-14 fs-md-12 roboto-regular text-gray mb-0 text-ellips-110px">
              {item.user_name}
            </h6>
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(item)}
            className="bg-blue border-0 pxy-17 br-6 text-white rounded-circle fs-18 fs-md-16"
            style={{ transform: "scale(1.5)" }}
          />
        </div>
      </div>
    ));

  return (
    <Modal scrollable zIndex={9} size="lg" centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h4 className="mb-0 roboto-bold fs-18">{lang?.add_participants}</h4>
      </ModalHeader>
      <ModalBody>
        <div className="py-2">
          <div className="position-relative">
            <img
              src={Search}
              className="position-absolute start-0 ms-3 mt-11"
              width={20}
              height={20}
              alt="..."
            />
            <input
              type="text"
              className="form-control searchgroupmembers py-2 ps-5 rounded-pill outline-none"
              placeholder={lang?.search}
              onChange={handleSearch}
              value={search}
            />
          </div>
        </div>

        <Container fluid>
          {isLoading || isSearching ? (
           <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
           <Spinner />
         </div>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={search ? hasMoreSearchResults : hasMoreFollowing}
              dataLength={search ? searchResults.length : groupParticipants.length}
              loader={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Spinner />
            </div>}
              useWindow={false}
            >
              {renderParticipants(search ? searchResults : groupParticipants)}
            </InfiniteScroll>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn-blue border-0 roboto-medium fs-16 px-4 py-2"
          onClick={handleCreateGroup}
          disabled={loading}
        >
          {loading ? "Creating..." : lang?.create_group}
        </button>
      </ModalFooter>
    </Modal>
  );
}
