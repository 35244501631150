import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { Button, Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import {
  _imagesURL,
  adminBaseURL,
  BaseURL,
  DetailCourseApi,
  GetPublishCourse,
  PostPurchaseCourse,
  SpecificCourseContentDetails,
} from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import ArrowUp from "../../assets/images/icon/arrow-up.svg";
import ArrowDown from "../../assets/images/icon/arrow-down.svg";
import ArrowRight from "../../assets/images/icon/arrow-right.png";
import Lock from "../../assets/images/icon/lock.svg";
import { Carousel } from "react-responsive-carousel";
import Spinner from "../Spinner";
import PlaceholderIcon from "../../assets/images/icon/medicalradar.png";
import YoutubeIcon from "../../assets/images/icon/youtube.png";
import PdfIcon from "../../assets/images/icon/pdf.png";
import playIcon from "../../assets/images/icon/play.png";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";
import { getToken } from "../../shared/token";
import { fetchPaypalToken, generateNonce, generateNonceWithDropIn } from "../../services/paypalApi";

const CourseDetail = () => {
  const course_id = sessionStorage.getItem("CourseId");
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;
  const [data, setData] = useState({});
  const [spin, setSpin] = useState(false);
  const [open, setOpen] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [publish, setPublish] = useState(false);
  const [contentLock, setContentLock] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState(0);
  const [contentModal, setContentModal] = useState(false);
  const [courseContent, setCourseContent] = useState({});
  const [imgPreview, setImgPreview] = useState(false);
  const [dropInModal, setDropInModal] = useState(false);
  const { lang } = useContext(Language);
  const authorize ={
        
    headers: {
        Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },

}
  const handlePurchase = async() => {

    setIsLoading(true);
    if(formattedPrice>0){
    const userToken = await fetchPaypalToken(); // Replace with your API call
    setDropInModal(true)
    const nonce = await generateNonceWithDropIn(userToken, "dropin-container",formattedPrice);
    axios
      .post(`${adminBaseURL}user/course/purchase`, {
        
        course_id,
        nonce
      },authorize)
      .then((res) => {
        if (res.data.status) {
          setPurchaseModal(false);
          setContentLock(false);
          setIsLoading(false);
          setDropInModal(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setPurchaseModal(false);
        setIsLoading(false);
        setDropInModal(false)
      });}else{
        axios
        .post(`${adminBaseURL}user/course/purchase`, {
          
          course_id,
        },authorize)
        .then((res) => {
          if (res.data.status) {
            setPurchaseModal(false);
            setContentLock(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setPurchaseModal(false);
          setIsLoading(false);
        });
      }
  };
  const handlePublish = () => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/publish/${course_id}`,authorize)
      .then((res) => {
        setPublish(res.data.status);
        setPublishModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPublishModal(false);
        setIsLoading(false);
      });
  };
  const handleDetails = () => {
    setSpin(true);
    axios
      .get(`${adminBaseURL}user/course/detail/${course_id}`,authorize)
      .then((res) => {
        const strNumber = res.data.data.price;
        const doubleNumber = parseFloat(strNumber);
        const hasDecimal = strNumber.includes(".");
        var formattedNumber = hasDecimal
          ? doubleNumber.toString()
          : doubleNumber.toFixed(2);
        setData(res.data.data);
        setFormattedPrice(formattedNumber);
        if (user_id === res?.data?.data?.user.id) {
          setContentLock(false);
        } else if (res.data.data.isPurchase) {
          setContentLock(false);
        }
        setTimeout(() => {
          setSpin(false);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        setSpin(false);
      });
  };
        // SpecificCourseContentDetails + id + "/" + user_id

  const handleModal = (id) => {
    axios
      .get(`${adminBaseURL}user/course/media/content/${id}`,authorize)
      .then((res) => {
        setCourseContent(res.data.data);
        setContentModal(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleDetails();
  }, [contentLock, publish]);

  return (
    <>
      <div>
        {/* Modal */}
        <Modal isOpen={publishModal || purchaseModal} zIndex={9} centered>
          <ModalBody>
            <div className="pr-62px">
              <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
                {publishModal
                  ? lang?.do_want_publish_cours
                  : purchaseModal
                  ? lang?.do_want_enrol_course
                  : ""}
              </h2>
              <p className="fs-16 fs-md-15 roboto-regular">
                {publishModal
                  ? lang?.publish_course_para
                  : purchaseModal
                  ? lang?.enrol_course_para
                  : ""}
              </p>
            </div>
            <hr />
            <div className="justify-content-end gap-3 d-flex">
              <button
                className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                onClick={() => {
                  publishModal
                    ? setPublishModal(false)
                    : purchaseModal && setPurchaseModal(false);
                }}
              >
                {lang?.cancel}
              </button>
              <button
              id="submit-button"
                className="btn-red border-0 rounded w-100 py-2 fs-md-14"
                disabled={isLoading}
                onClick={() => {
                  publishModal
                    ? handlePublish()
                    : purchaseModal && handlePurchase();
                }}
              >
                {isLoading ? (
                  <div className="loader"></div>
                ) : publishModal ? (
                  lang?.publish
                ) : purchaseModal ? (
                  lang?.enrol_now
                ) : (
                  ""
                )}
              </button>
            </div>
          </ModalBody>
        </Modal>
        {/* Modal Ended */}

        <>
          {spin ? (
            <div className="ticketchatspinner">
              <Spinner />
            </div>
          ) : (
            <Row className="bg-white p-0 ms-xl-3 ms-lg-0 ms-md-0 ms-sm-0 ms-0 rounded border border-1 border-inherit">
              <Col lg={4} className="p-0">
                <div>
                  <div onClick={() => setImgPreview(true)}>
                    <ImageComponent
                      src={_imagesURL + data?.cover}
                      compClassName="w-100 br-tl-12"
                      loader_width="100%"
                      loader_height="530px"
                    />
                    <Modal
                      isOpen={imgPreview}
                      centered
                      toggle={() => {
                        setImgPreview(false);
                      }}
                    >
                      <ImageComponent
                        src={_imagesURL + data?.cover}
                        compClassName="img-fluid rounded"
                      />
                    </Modal>
                  </div>
                  <div className="px-4 pt-3">
                    <p
                      className="fs-14 roboto-medium"
                      style={{ color: "#2677E5" }}
                    >
                      {data?.category} - {data?.subcategory}
                    </p>
                    <h3
                      className="roboto-bold fs-22 fs-md-16"
                      style={{ color: "rgba(0, 0, 52, 0.7)" }}
                    >
                      {data?.title}
                    </h3>
                    {data.certificates && data.certificates.length > 0 && (
                      <h6
                        className="fs-14 roboto-bold py-3"
                        style={{ color: "#555555" }}
                      >
                        {lang?.certified_by}
                      </h6>
                    )}
                    <Carousel
                      autoPlay={true}
                      infiniteLoop={true}
                      interval={2000}
                      showArrows={false}
                      showStatus={false}
                      showIndicators={false}
                      showThumbs={false}
                    >
                      {data.certificates &&
                        data?.certificates.map((value) => (
                          <Row key={value.id}>
                            <Col className="d-flex gap-3">
                              <div>
                                <div>
                                  <ImageComponent
                                    src={_imagesURL + value.media}
                                    compClassName="size"
                                    img_height="50px"
                                    img_width="50px"
                                    loader_height="50px"
                                    loader_width="50px"
                                  />
                                </div>
                              </div>
                              <div className="align-self-center">
                                <div
                                  className="fs-16 roboto-regular ellips"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {value.title}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </Carousel>
                  </div>
                  <hr style={{ opacity: "15%" }} />
                  <div className="px-4">
                    <h3
                      className="fs-24 fs-md-16 roboto-bold py-2"
                      style={{ color: "rgba(0, 0, 52, 0.7)" }}
                    >
                      {data?.price === "0"
                        ? "€0.0 - Free"
                        : "€" + formattedPrice}
                    </h3>
                  </div>
                  {!data?.isPurchase &&
                    user_id !== data?.user?.id &&
                    data?.price === "0" && (
                      <div className="px-4 pb-3">
                        <button
                          onClick={() => setPurchaseModal(true)}
                          className="bg-blue fs-20 fs-md-16 roboto-bold btn btn-block br-4 text-white w-100 py-3"
                        >
                          {isLoading ? (
                            <div className="loader"></div>
                          ) : (
                            <strong>{lang?.enrol_now_free}</strong>
                          )}
                        </button>
                      </div>
                    )}
                  {!data?.isPurchase &&
                    user_id !== data?.user?.id &&
                    data?.price !== "0" && (
                      <div className="px-4 pb-3">
                        <button
                          onClick={() => setPurchaseModal(true)}
                          className="bg-blue fs-20 fs-md-16 roboto-bold btn btn-block br-4 text-white w-100 py-3"
                        >
                          {isLoading ? (
                            <div className="loader"></div>
                          ) : (
                            <strong>
                              {lang?.enrol_now} - €{formattedPrice}
                            </strong>
                          )}
                        </button>
                      </div>
                    )}
                  {user_id === data?.user?.id && data?.publish === 0 && (
                    <div className="px-4 pb-3">
                      <button
                        onClick={() => setPublishModal(true)}
                        className="bg-blue fs-20 fs-md-16 roboto-bold btn btn-block br-4 text-white w-100 py-3"
                      >
                        {isLoading ? (
                          <div className="loader"></div>
                        ) : (
                          <strong>{lang?.pub_course}</strong>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={8}>
                <div className="px-4 mt-3">
                  <div>
                    <h5
                      className="fs-24 fs-md-16 roboto-bold"
                      style={{ color: "rgba(0, 0, 52, 0.7)" }}
                    >
                      {lang?.description}
                    </h5>
                  </div>

                  <span
                    className="fs-14 roboto-regular"
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  >
                    {data?.short_description &&
                    !expanded &&
                    data.short_description.length > 364
                      ? data.short_description.slice(0, 364)
                      : data.short_description}
                  </span>
                  {data.short_description &&
                    data.short_description.length > 364 && (
                      <div className="pb-3">
                        <span
                          className="fs-14 roboto-regular cursor"
                          style={{ color: "#5BA4FC" }}
                          onClick={() => {
                            setExpanded(!expanded);
                          }}
                        >
                          {expanded ? lang?.show_less : lang?.show_more}
                        </span>
                      </div>
                    )}
                </div>

                <hr style={{ opacity: "15%" }} />
                <div
                  className={`px-4 ${
                    data.sections && data.sections.length === 0
                      ? "pb-4"
                      : "py-2"
                  }`}
                >
                  <h3
                    className="fs-24 fs-md-16 roboto-bold"
                    style={{ color: "rgba(0, 0, 52, 0.7)" }}
                  >
                    {lang?.course_owner}
                  </h3>
                  <div className="d-flex mt-2">
                    <div>
                      <div
                        className="bg-white dropdown-avatar-shadow rounded-2"
                        style={{
                          padding: "2px",
                          width: "4rem",
                          height: "4rem",
                        }}
                      >
                        <Link to={PROFILE + "/" + data?.user?.id}>
                          <ImageComponent
                            src={
                              data?.user?.image
                                ? _imagesURL + data?.user?.image
                                : Avatar
                            }
                            loader_height="100%"
                            loader_width="100%"
                            compClassName="rounded-2 img-fluid"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="align-self-center ps-3">
                      <h4
                        className="fs-16 roboto-bold mb-0"
                        style={{ color: "rgba(0, 0, 52, 0.7)" }}
                      >
                        {data?.user?.name}
                      </h4>
                      <span
                        className="fs-12 roboto-regular"
                        style={{ color: "#555555" }}
                      >
                        {data?.user?.category} {`(${data?.user?.subcategory})`}
                      </span>
                    </div>
                  </div>
                </div>
                {data.sections && data.sections.length > 0 && (
                  <>
                    <hr style={{ opacity: "15%" }} />
                    <div className="px-4 py-2">
                      <h3
                        className="fs-24 fs-md-16 roboto-bold pb-2"
                        style={{ color: "rgba(0, 0, 52, 0.7)" }}
                      >
                        {lang?.course_content}
                      </h3>
                      {data.sections &&
                        data.sections.map((value) => (
                          <div key={value.id} className="mb-3">
                            <button
                              disabled={contentLock}
                              className="btn border border-1 bg-transparent d-flex w-100 py-3 rounded justify-content-between text-start"
                              onClick={() => {
                                open === value.id
                                  ? setOpen(0)
                                  : setOpen(value.id);
                              }}
                            >
                              <div className="d-flex gap-3">
                                <div>
                                  {open === value.id ? (
                                    <img
                                      src={ArrowUp}
                                      alt="picture"
                                      width={15}
                                    />
                                  ) : (
                                    <img
                                      src={ArrowDown}
                                      alt="picture"
                                      width={15}
                                    />
                                  )}
                                </div>
                                <div>
                                  <span className="fs-14 roboto-regular">
                                    {value.name}
                                  </span>
                                </div>
                              </div>
                              <div>
                                {contentLock && (
                                  <img src={Lock} alt="picture" width={15} />
                                )}
                              </div>
                            </button>
                            {value.videos.map((data, index) => (
                              <Collapse
                                key={index}
                                isOpen={open === data.section_id ? true : false}
                              >
                                <button
                                  className="btn border border-1 bg-transparent d-flex w-100 py-3 gap-3 rounded-0 justify-content-between text-start"
                                  onClick={() => handleModal(data.id)}
                                >
                                  <div style={{ marginLeft: "40px" }}>
                                    <span className="fs-14 roboto-regular">
                                      {data.title}
                                    </span>
                                  </div>
                                  <div>
                                    <img src={ArrowRight} alt="picture" />
                                  </div>
                                </button>
                              </Collapse>
                            ))}
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          )}
        </>
      </div>

      <Modal
        isOpen={contentModal}
        toggle={() => setContentModal(!contentModal)}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9}
      >
        <ModalBody className="p-5">
          <Row className="justify-content-between">
            <Col md={6}>
              <a
                href={
                  courseContent.media === "" ||
                  courseContent.media === undefined
                    ? "javascript:void(0)"
                    : _imagesURL + courseContent.media
                }
                target={
                  courseContent.media === "" ||
                  courseContent.media === undefined
                    ? ""
                    : "_blank"
                }
                rel="noopener noreferrer"
              >
                <div
                  className="text-center position-relative"
                  style={{
                    border: "1px solid rgba(22,34,36,0.1)",
                    opacity: "70%",
                    borderRadius: "10px",
                    backgroundColor: "#F5F7F9",
                    minHeight: "200px",
                    minWidth: "200px",
                  }}
                >
                  {courseContent.media === undefined ||
                  courseContent.media === "" ? (
                    <></>
                  ) : (
                    <img
                      src={playIcon}
                      className="position-absolute m-auto top-0 bottom-0 start-0 end-0"
                      width={60}
                      height={60}
                      alt="picture"
                    />
                  )}

                  {courseContent.media === undefined ||
                  courseContent.media === "" ? (
                    <img
                      src={PlaceholderIcon}
                      alt="picture"
                      className="img-fluid br-10 mt-5"
                      width={100}
                      height={100}
                    />
                  ) : (
                    <img
                      src={_imagesURL + courseContent.thumbnail}
                      alt="picture"
                      className="img-fluid br-10 w-100"
                      style={{ objectFit: "cover", height: "12.4rem" }}
                    />
                  )}
                </div>
              </a>
              <div>
                <p className="text-center fs-18 fs-md-16 roboto-medium pt-3">
                  {lang?.video}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <a
                href={
                  courseContent.image === undefined ||
                  courseContent.image === ""
                    ? "javascript:void(0)"
                    : _imagesURL + courseContent.image
                }
                target={
                  courseContent.image === "" ||
                  courseContent.image === undefined
                    ? ""
                    : "_blank"
                }
                rel="noopener noreferrer"
              >
                <div
                  className="text-center"
                  style={{
                    border: "1px solid rgba(22,34,36,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#F5F7F9",
                    minHeight: "200px",
                    minWidth: "200px",
                  }}
                >
                  {courseContent.image === undefined ||
                  courseContent.image === "" ? (
                    <img
                      src={PlaceholderIcon}
                      alt="picture"
                      className="img-fluid br-10 mt-5"
                      width={100}
                      height={100}
                    />
                  ) : (
                    <img
                      src={_imagesURL + courseContent.image}
                      alt="picture"
                      className="img-fluid br-10"
                      style={{ objectFit: "cover", height: "12.4rem" }}
                    />
                  )}
                </div>
              </a>
              <div>
                <p className="text-center fs-18 fs-md-16 roboto-medium pt-3">
                  {lang?.image}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <a
                href={
                  courseContent.pdf === undefined || courseContent.pdf === ""
                    ? "javascript:void(0)"
                    : _imagesURL + courseContent.pdf
                }
                target={
                  courseContent.pdf === "" ||
                  courseContent.pdf === undefined
                    ? ""
                    : "_blank"
                }
                rel="noopener noreferrer"
              >
                <div
                  className="text-center"
                  style={{
                    border: "1px solid rgba(22,34,36,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#F5F7F9",
                    minHeight: "200px",
                    minWidth: "200px",
                  }}
                >
                  <img
                    src={
                      courseContent.pdf === undefined ||
                      courseContent.pdf === ""
                        ? PlaceholderIcon
                        : PdfIcon
                    }
                    alt="picture"
                    width={100}
                    height={100}
                    className="mt-5"
                  />
                </div>
              </a>
              <div>
                <p className="text-center fs-18 fs-md-16 roboto-medium pt-3">
                  {lang?.pdf_file}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <a
                href={
                  courseContent.link === undefined || courseContent.link === ""
                    ? "javascript:void(0)"
                    : courseContent.link
                }
                target={
                  courseContent.link === "" ||
                  courseContent.link === undefined
                    ? ""
                    : "_blank"
                }
                rel="noopener noreferrer"
              >
                <div
                  className="text-center"
                  style={{
                    border: "1px solid rgba(22,34,36,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#F5F7F9",
                    minHeight: "200px",
                    minWidth: "200px",
                  }}
                >
                  {courseContent.link === undefined ||
                  courseContent.link === "" ? (
                    <img
                      src={PlaceholderIcon}
                      alt="picture"
                      width={100}
                      height={100}
                      className="mt-5"
                    />
                  ) : (
                    <img
                      src={YoutubeIcon}
                      alt="picture"
                      width={100}
                      style={{ marginTop: "4rem" }}
                    />
                  )}
                </div>
              </a>
              <div>
                <p className="text-center fs-18 fs-md-16 roboto-medium pt-3">
                  {lang?.youtube_url}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <button
                className="fs-20 fs-md-16 roboto-bold w-100 btn-blue border-0 py-3"
                onClick={() => {
                  setContentModal(false);
                }}
              >
                {lang?.go_back}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={dropInModal} toggle={() => {
  setDropInModal(false);
  setIsLoading(false);
}} size="lg" centered>
      <ModalHeader toggle={()=>setDropInModal(!dropInModal)}>Payment</ModalHeader>
      <ModalBody>
        {/* Drop-In Container */}
        <div id="dropin-container"></div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={()=>{setDropInModal(false);setIsLoading(false)}}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default CourseDetail;
