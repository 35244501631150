import React, { useContext, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { Language } from "../../App";
import axios from "axios";
import { adminBaseURL } from "../../constants/API";
import { authorize } from "../../shared/authorize";
import { toast } from "react-toastify";

const ReportGroupModal = ({ isOpen, toggle,currentId }) => {
 const {lang} = useContext(Language)
  const [reportText, setReportText] = useState("");


  const handleSubmit = async() => {
    console.log("Report Submitted:", reportText);
    // Add your logic for report submission here
    try {
        const response = await axios.post(adminBaseURL+"app/report",{feature_id:currentId,report:reportText,type:"group"},authorize()); 
        toast.success("Group Reported Successfully")
      
       setReportText(""); // Clear the input after submission
       toggle(); // Close the modal  
    } catch (error) {
        toast.error(error)
    }
 
  };
  return (
  
      <Modal isOpen={isOpen} centered toggle={toggle}>
        <ModalHeader toggle={toggle}>{lang.report_this_group}</ModalHeader>
        <ModalBody>
          <p>
           {lang.report_group_para}
          </p>
          <Input
            type="textarea"
            placeholder={lang.enter_report}
            value={reportText}
            onChange={(e) => setReportText(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          
          <Button color="secondary" onClick={toggle}>
            {lang.cancel}
          </Button>
          <button className="btn" style={{backgroundColor:"#1dc1dd",color:"white"}} onClick={handleSubmit}>
            {lang.submit_report}
          </button>
        </ModalFooter>
      </Modal>
  );
};

export default ReportGroupModal;
