import React, { useCallback,useMemo, useContext, useEffect, useState } from "react";
import { Container, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { Language, LoginUserData } from "../../App";
import Search from "../../assets/images/icons/search.svg";
import InfiniteScroll from "react-infinite-scroller";
import ImageComponent from "../common/ImageComponent";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../../shared/token";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { debounce } from "lodash";
// Function to fetch participants
const fetchParticipants = async ({ page, currentId }) => {
  try {
    const response= await axios.get(
      `${adminBaseURL}user/group-chat/user/list/${currentId}?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error)
  }
 
};

// Function to fetch search results
const fetchSearchResults = async ({ page,search,userId }) => {
  // const [, { searchValue, userId,page }] = queryKey;
  const { data } = await axios.post(
    `${adminBaseURL}user/global/search?page=${page}`,
    {
      user_id: userId,
      keyword: search,
      type: "user",
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

  return data
};

const addMemberToGroupChat = async ({ currentId, userId }) => {
  try {
    // Construct the API endpoint URL using template literals
    const apiUrl = `${adminBaseURL}user/group-chat/member/add/${currentId}/${userId}`;
    
    // Perform the POST request
    const { data } = await axios.get(
      apiUrl,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include auth token
        },
      }
    );

    // Log the response data for debugging purposes
    console.log("Add Member to Group Chat Response:", data);

    // Return the response data
    return data;

  } catch (error) {
    console.error("Error adding member to group chat:", error);
    throw error; // Rethrow the error to handle it in the component or elsewhere
  }
};


const EditGroupParticipantsModal = ({ isOpen, toggle, currentId }) => {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const [search, setSearch] = useState("");
  const userId = splashData?.user?.id;
  const queryClient = useQueryClient();
  const [state, setState] = useState({
    selectedParticipants: [],
  });
  const handleModalDismiss = () => {
    // Remove specific query caches
    queryClient.removeQueries("searchResults"); // Remove cached data for search results
    queryClient.removeQueries("groupParticipantsEdit"); // Remove cached data for group participants
  
    toggle(); // Close the modal
  }; 
  const { data, isLoading, fetchNextPage, hasNextPage,isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["groupParticipantsEdit"],
      queryFn: ({ pageParam = 1 }) =>
        fetchParticipants({ page: pageParam, currentId }),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage?.data?.current_page + 1;

        return nextPage <= lastPage?.data?.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: isOpen,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  // Debounced search handler
  const {
    data: searchResults,
    isFetching: isFetchingSearch,
    isLoading:isLoadingSearch,
    fetchNextPage: fetchNextSearchPage,
    hasNextPage: hasNextSearchPage,
    isFetchingNextPage: isFetchingNextSearchPage,
    refetch:refetchSearch
  } = useInfiniteQuery({
    queryKey:["searchResults",search],
    queryFn:({pageParam = 1 })=> fetchSearchResults({page:pageParam,search,userId }),
    
      enabled: !!search, // Enable only when there is a search query
      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage?.data?.current_page + 1;
        return nextPage <= lastPage?.data?.last_page ? nextPage : undefined;
      },
    
});
  
  const debouncedSearchHandler = useMemo(
    () =>
      debounce((value) => {
        if (value.trim() !== "") {
          refetchSearch();
        }
      }, 300), // 300ms debounce duration
    [refetchSearch]
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    debouncedSearchHandler(searchValue); // Call debounced handler
  };

  useEffect(() => {
    // Cleanup debounce on component unmount
    return () => debouncedSearchHandler.cancel();
  }, [debouncedSearchHandler]);


  const handleCheckboxChange = (item) => {
    const userId = item.id;
 
  
    // Ensure currentId is valid before proceeding
    if (currentId) {
      // Check if the user is already in the selected list (prevents multiple API calls for the same participant)
      setState((prevState) => {
        if (!prevState.selectedParticipants.includes(userId)) {
          // Add user to the selected list if not already present
          return {
            ...prevState,
            selectedParticipants: [...prevState.selectedParticipants, userId],
          };
        }
        // return prevState; // No change if the user is already selected
      });
  
      // Now make the API call to add the member to the group chat
      // Ensure the API call happens only once when the user is selected
      addMemberToGroupChat({currentId, userId});
    } else {
      console.error("Invalid group ID (currentId). Cannot add member.");
    }
  };

  const _searchResults = searchResults?.pages?.flatMap((page) => page.data.data);
  const _participantsData = data?.pages?.flatMap((page) => page.data.data);
  const renderParticipants = (participants) =>
    participants.map((item) => (
      <div
        className="d-flex align-items-center justify-content-between p-2 border-bottom border-inherit"
        key={item.id}
      >
        <div className="d-flex align-items-center gap-3">
          <div>
            <ImageComponent
              src={item.image ? _imagesURL + item.image : Avatar}
              alt="..."
              loader_height="60px"
              loader_width="60px"
              img_height="60px"
              img_width="60px"
              compClassName="rounded-circle"
              roundShimmerSize={40}
            />
          </div>
          <div>
            <h3 className="fs-18 fs-md-15 roboto-medium mb-0 text-ellips-110px">
              {item.name}
            </h3>
            <h6 className="fs-14 fs-md-12 roboto-regular text-gray mb-0 text-ellips-110px">
              {item.user_name}
            </h6>
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(item)}
            // checked={state.selectedParticipants.includes(item.id)}
            className="bg-blue border-0 pxy-17 br-6 text-white rounded-circle fs-18 fs-md-16"
            style={{ transform: "scale(1.5)" }}
          />
        </div>
      </div>
    ));


   const style= {
      maxHeight: "300px", /* Adjust the height as needed */
      overFlowY: "auto",
      // padding-right: 15px; /* To avoid content being hidden under the scrollbar */
    }
    const handleLoadMore = () => {
      // Check if there are more pages and the API call is not already in progress
      if (search.length>0) {
        if (hasNextSearchPage && !isFetchingNextSearchPage) {
          fetchNextSearchPage();
        }
      } else {
        if (hasNextPage && !isFetchingNextPage) {
       
          fetchNextPage();
        }
      }
    };
  return (
    <Modal scrollable size="lg" centered isOpen={isOpen} toggle={handleModalDismiss}>
      <ModalHeader toggle={handleModalDismiss}>
        <h4 className="mb-0 roboto-bold fs-18">{lang?.add_participants}</h4>
      </ModalHeader>
      <ModalBody className=""  >
        <div className="py-2">
          <div className="position-relative">
            <img
              src={Search}
              className="position-absolute start-0 ms-3 mt-11"
              width={20}
              height={20}
              alt="..."
            />
            <input
              type="text"
              className="form-control searchgroupmembers py-2 ps-5 rounded-pill outline-none"
              placeholder={lang?.search}
              onChange={handleSearch}
              value={search}
            />
          </div>
        </div>

        {/* <Container fluid> */}
          { isLoading||isLoadingSearch   ? (
            
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
            <Spinner />
          </div>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={
                handleLoadMore
            }
              hasMore={search.length>0 ? hasNextSearchPage : hasNextPage} 
              loader={ <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
              <Spinner />
            </div>}
              useWindow={false}
            >
              <>
                {search && _searchResults?.length > 0
                  ? renderParticipants(_searchResults || [])
                  : renderParticipants(_participantsData || [])}
              </>
            </InfiniteScroll>
           )}
        {/* </Container> */}
      </ModalBody>
      <ModalFooter>
        <button onClick={handleModalDismiss} className="btn-blue border-0 roboto-medium fs-16 px-4 py-2">
          {lang.save}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default EditGroupParticipantsModal;