// URLS
import {
  CHANGE_PASSWORD,
  CHAT,
  COURSES,
  COURSE_DETAILS,
  COURSE_CONTENT,
  EVENTS,
  FEED,
  FORGOT_PASSWORD,
  JOBS,
  LOGIN,
  OTP_VERIFICATION,
  PROFILE,
  SIGNUP,
  SPECIALIZATION,
  ADDPOST,
  SETTINGMODULE,
  WEBINAR,
  EDIT_PROFILE,
  NOTIFICATIONS,
  SEARCH,
  EVENT_DETAILS,
  JOBDETAIL,
  MyReports,
  ADD_CLINICAL,
  PROFILEJOB,
  PROFILEEVENTS,
  PROFILECOURSES,
  LIVE_WEBINARS,
  PROFILEPOSTS,
  GROUPSETTING,
  CONTESTS,
  CONTEST_DETAILS,
  CLINICAL_SESSIONS,
  CLINICAL_SESSIONS_DETAILS,
  POST_DETAILS,
  POLL_DETAILS,
  WEBINAR_DETAILS,
  CLINICAL_DETAILS,
  CLINICAL_SESSION_DETAILS,
  SEE_ALL_COURSES,
  CONTEST_LIST,
  NEW_CONTEST,
  COURSE_SHARED_POST,
  EVENT_SHARED_POST,
  SEE_ALL_TRENDING_PAGE,
  SEE_ALL_FOLLOWING_DOCTOR_PAGE,
  SEE_ALL_POPULAR_COURSES_PAGE,
  SEE_ALL_PURCHASED_COURSES_PAGE,
  NEW_COURSE_DETAILS,
  NEW_MANAGE_CONTENT,
  SEE_ALL_SUGGESTED_EVENTS,
  WALLET,
  payPalCallback,
  PAYPAL_PAYMENT,
} from "./RoutesUrl";
import LoggedInRoute from "./LoggedInRoute";
import ProtectedRoute from "./ProtectedRoute";
// Routing Package
import { createBrowserRouter } from "react-router-dom";
// Pages
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Specialization from "../pages/auth/Specialization";
import ForgotPassword from "../pages/auth/ForgotPassword";
import OtpVerification from "../pages/auth/OtpVerification";
import ChangePassword from "../pages/auth/ChangePassword";
import Feed from "../pages/Feed";
import Courses from "../pages/course/Courses";
import Events from "../pages/Events";
import Jobs from "../pages/Jobs";
import Profile from "../pages/Profile";
import CourseDetail from "../pages/course/CoursesDetail";
import ChatModule from "../pages/chat/ChatModule";
import { AddPost } from "../uploadContent/AddPost";
import SettingModule from "../pages/setting/SettingModule";
import CourseContent from "../components/CourseContent";
import WebinarVideoCall from "../components/webinar/WebinarVideoCall";
import EditProfileInfo from "../pages/setting/EditProfileInfo";
import Index from "../pages/notifications/Index";
import Search from "../pages/search/Search";
import EventsDetails from "../pages/events/EventsDetails";
import JobDetails from "../components/jobs/JobDetails";
import Create_Complaint from "../components/manage_account/Create_Complaint";
import AddForm from "../pages/clinical_studies/AddForm";
import ProfileJob from "../components/jobs/ProfileJob";
import ProfileEvents from "../components/events/ProfileEvents";
import ProfileCourses from "../components/courses/ProfileCourses";
import LiveWebinars from "../components/live-webinars/LiveWebinars";
import ProfilePost from "../components/feeds/ProfilePost";
import GroupSettings from "../pages/GroupSetting/GroupSettings";
import Contest from "../pages/sponsor-post/Contest";
import ContestDetail from "../components/sponsor-post/ContestDetail";
import ClinicalSessions from "../pages/clinical-sessions/ClinicalSessions";
import ClinicalSessionsDetails from "../pages/clinical-sessions/ClinicalSessionsDetails";
import PostsDetails from "../pages/post-details/PostsDetails";
import SeeAllCourses from "../components/courses/SeeAllCourses";
import ContestList from "../components/sponsor-post/ContestList";
import NewContest from "../components/sponsor-post/NewContest";
import CourseSharedPost from "../pages/post-details/CourseSharedPost";
import EventSharedPost from "../pages/post-details/EventSharedPost";
import DoctorsSearch from "../pages/search/DoctorsSearch";
import PostsSearch from "../pages/search/PostsSearch";
import WebinarsSearch from "../pages/search/WebinarsSearch";
import ClinicalStudiesSearch from "../pages/search/ClinicalStudiesSearch";
import JobsSearch from "../pages/search/JobsSearch";
import CoursesSearch from "../pages/search/CoursesSearch";
import EventsSearch from "../pages/search/EventsSearch";
import SeeAllCoursesPage from "../components/courses/See-All-Components/SeeAllTrendingPage";
import SeeAllTrendingPage from "../components/courses/See-All-Components/SeeAllTrendingPage";
import SeeAllFollowingDoctorPage from "../components/courses/See-All-Components/SeeAllFollowingDoctorPage";
import SeeAllPopularCoursesPage from "../components/courses/See-All-Components/SeeAllPopularCoursesPage";
import SeeAllPurchasedCourses from "../components/courses/See-All-Components/SeeAllPurchasedCourses";
import NewCourseDetail from "../components/course-details/NewCourseDetail";
import SeeAllSuggestedEvents from "../pages/events/see-all-events/SeeAllSuggestedEvents";
import Wallet from "../pages/setting/wallet/Wallet";
import PayPalCallback from "../pages/setting/wallet/PayPalCallback";

import NewLogin from '../pages/auth/newauth/NewLogin'
import NewForgotPassword from "../pages/auth/newauth/NewForgotPassword";
import NewResetPassword from "../pages/auth/newauth/NewResetPassword";
import NewSignup from "../pages/auth/newauth/NewSignup";
import PaypalPaymentPage from "../components/course-details/PaypalPaymentPage";
import NewManageContentPage from "../components/course-details/NewManageContentPage";


const Router = createBrowserRouter([
  {
    path: LOGIN,
    element: (
      <LoggedInRoute>
        {/* <Login /> */}
        <NewLogin />
      </LoggedInRoute>
    ),
  },
  {
    path: SIGNUP,
    element: (
      <LoggedInRoute>
        {/* <Signup /> */}
        <NewSignup />
      </LoggedInRoute>
    ),
  },
  // New Auth Page Routing Start
  // {
  //   path: '/newAuth',
  //   element: (
  //     <NewLogin />
  //   ),
  // },
  // {
  //   path: '/newForgot',
  //   element: (
  //     <NewForgotPassword />
  //   ),
  // },
  // {
  //   path: '/newResetPassword',
  //   element: (
  //     <NewResetPassword />
  //   ),
  // },
  // {
  //   path: '/newSignup',
  //   element: (
  //     <NewSignup />
  //   ),
  // },
   // New Auth Page Routing End
  {
    path: JOBDETAIL + "/:id",
    element: (
      <ProtectedRoute>
        <JobDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/doctors',
    element: (
      <ProtectedRoute>
        <DoctorsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/posts',
    element: (
      <ProtectedRoute>
        <PostsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/webinars',
    element: (
      <ProtectedRoute>
        <WebinarsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/clinicals',
    element: (
      <ProtectedRoute>
        <ClinicalStudiesSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/jobs',
    element: (
      <ProtectedRoute>
        <JobsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/courses',
    element: (
      <ProtectedRoute>
        <CoursesSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/events',
    element: (
      <ProtectedRoute>
        <EventsSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: MyReports,
    element: (
      <ProtectedRoute>
        <Create_Complaint />
      </ProtectedRoute>
    ),
  },
  {
    path: SPECIALIZATION,
    element: (
      <ProtectedRoute>
        <Specialization />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILE + "/:id",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: FORGOT_PASSWORD,
    element: (
      <LoggedInRoute>
        {/* <ForgotPassword /> */}
        <NewForgotPassword />
      </LoggedInRoute>
    ),
  },
  // {
  //   path: OTP_VERIFICATION,
  //   element: (
  //     <LoggedInRoute>
  //       <OtpVerification />
  //     </LoggedInRoute>
  //   ),
  // },
  {
    path: CHANGE_PASSWORD,
    element: (
      <LoggedInRoute>
        {/* <ChangePassword /> */}
         <NewResetPassword />
      </LoggedInRoute>
    ),
  },
  {
    path: FEED,
    element: (
      <ProtectedRoute>
        <Feed />
      </ProtectedRoute>
    ),
  },
  {
    path: EVENTS + "/:id",
    element: (
      <ProtectedRoute>
        <Events />
      </ProtectedRoute>
    ),
  },
  {
    path: JOBS + "/:id",
    element: (
      <ProtectedRoute>
        <Jobs />
      </ProtectedRoute>
    ),
  },

  {
    path: PROFILEJOB + "/:id",
    element: (
      <ProtectedRoute>
        <ProfileJob />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILEEVENTS + "/:id",
    element: (
      <ProtectedRoute>
        <ProfileEvents />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILECOURSES + "/:id",
    element: (
      <ProtectedRoute>
        <ProfileCourses />
      </ProtectedRoute>
    ),
  },
  {
    path: PROFILEPOSTS + "/:id",
    element: (
      <ProtectedRoute>
        <ProfilePost />
      </ProtectedRoute>
    ),
  },
  {
    path: COURSES + "/:id",
    element: (
      <ProtectedRoute>
        <Courses />
      </ProtectedRoute>
    ),
  },
  {
    path: CHAT,
    element: (
      <ProtectedRoute>
        <ChatModule />
      </ProtectedRoute>
    ),
  },

  {
    path: EDIT_PROFILE,
    element: (
      <ProtectedRoute>
        <EditProfileInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: ADDPOST + "/:id",
    element: (
      <ProtectedRoute>
        <AddPost />
      </ProtectedRoute>
    ),
  },
  {
    path: COURSE_DETAILS,
    element: (
      <ProtectedRoute>
        <CourseDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: SETTINGMODULE + "/:id",
    element: (
      <ProtectedRoute>
        <SettingModule />
      </ProtectedRoute>
    ),
  },
  {
    path: WALLET,
    element: (
      <ProtectedRoute>
        <Wallet />
      </ProtectedRoute>
    ),
  },
  {
    path: payPalCallback,
    element: (
      <ProtectedRoute>
        <PayPalCallback/>
      </ProtectedRoute>
    ),
  },
  
  {
    path: COURSE_CONTENT,
    element: (
      <ProtectedRoute>
        <CourseContent />
      </ProtectedRoute>
    ),
  },
  {
    path: NOTIFICATIONS,
    element: (
      <ProtectedRoute>
        <Index /> {/* notifications index page */}
      </ProtectedRoute>
    ),
  },
  {
    path: WEBINAR,
    element: (
      <ProtectedRoute>
        <WebinarVideoCall />
      </ProtectedRoute>
    ),
  },
  {
    path: SEARCH,
    element: (
      <ProtectedRoute>
        <Search />
      </ProtectedRoute>
    ),
  },
  {
    path: EVENT_DETAILS + ":id",
    element: (
      <ProtectedRoute>
        <EventsDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: ADD_CLINICAL,
    element: (
      <ProtectedRoute>
        <AddForm />
      </ProtectedRoute>
    ),
  },
  {
    path: LIVE_WEBINARS,
    element: (
      <ProtectedRoute>
        <LiveWebinars />
      </ProtectedRoute>
    ),
  },
  {
    path: GROUPSETTING,
    element: (
      <ProtectedRoute>
        <GroupSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: CONTESTS,
    element: (
      <ProtectedRoute>
        <Contest />
      </ProtectedRoute>
    ),
  },
  {
    path: CONTEST_LIST,
    element: (
      <ProtectedRoute>
        <ContestList />
      </ProtectedRoute>
    ),
  },
  {
    path: CONTEST_DETAILS + ":type",
    element: (
      <ProtectedRoute>
        <ContestDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: CLINICAL_SESSIONS,
    element: (
      <ProtectedRoute>
        <ClinicalSessions />
      </ProtectedRoute>
    ),
  },
  {
    path: CLINICAL_SESSIONS_DETAILS + ":id",
    element: (
      <ProtectedRoute>
        <ClinicalSessionsDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: POST_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: WEBINAR_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: CLINICAL_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: CLINICAL_SESSION_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: POLL_DETAILS + ":post_id",
    element: <PostsDetails />,
  },
  {
    path: COURSE_SHARED_POST + ":course_id",
    element: <CourseSharedPost />,
  },
  {
    path: EVENT_SHARED_POST + ":event_id",
    element: <EventSharedPost />,
  },
  {
    path: SEE_ALL_COURSES,
    element: (
      <ProtectedRoute>
        <SeeAllCourses />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_CONTEST,
    element: (
      <ProtectedRoute>
        <NewContest />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_TRENDING_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllTrendingPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_FOLLOWING_DOCTOR_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllFollowingDoctorPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_POPULAR_COURSES_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllPopularCoursesPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_PURCHASED_COURSES_PAGE,
    element: (
      <ProtectedRoute>
        <SeeAllPurchasedCourses />
      </ProtectedRoute>
    ),
  },
  {
    path: SEE_ALL_SUGGESTED_EVENTS,
    element: (
      <ProtectedRoute>
        <SeeAllSuggestedEvents />
      </ProtectedRoute>
    ),
  },
  {
    path: NEW_COURSE_DETAILS,
    element: (
      <ProtectedRoute>
        <NewCourseDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: PAYPAL_PAYMENT,
    element: (
      <ProtectedRoute>
        <PaypalPaymentPage />
      </ProtectedRoute>
    ),
  },
  // Manage Content New Page
  {
    path: NEW_MANAGE_CONTENT,
    element: (
      <ProtectedRoute>
        <NewManageContentPage />
      </ProtectedRoute>
    ),
  },
  
]);

export default Router;
