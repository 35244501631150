import React, { useEffect, useRef } from "react";

interface VideoWithAspectRatioProps {
  aspectRatio: number;
  src: string;
  index: number;
  setPlayingVideo: (index: number | null) => void;
  playingVideo: number | null;
}

const VideoWithAspectRatio: React.FC<VideoWithAspectRatioProps> = ({
  aspectRatio,
  src,
  index,
  setPlayingVideo,
  playingVideo,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const height = 555 / aspectRatio;

  useEffect(() => {
    // Cleanup existing observer
    if (observerRef.current && videoRef.current) {
      observerRef.current.unobserve(videoRef.current);
    }

    // Create a new observer to monitor visibility
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
            setPlayingVideo(index); // Notify parent that this video is in view
          }
        });
      },
      {
        threshold: [0.75], // Play only when 75% of the video is visible
        rootMargin: "0px",
      }
    );

    if (videoRef.current) {
      observerRef.current.observe(videoRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [index, setPlayingVideo]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    if (playingVideo === index) {
      // Play the current video
      videoElement.play().catch((err) => {
        console.error("Error playing video:", err);
      });
    } else {
      // Pause other videos
      videoElement.pause();
      videoElement.currentTime = 0; // Reset to the beginning
    }
  }, [playingVideo, index]);

  return (
    <>
    <div
      className="video-container"
      style={{
        // height: height > 630 ? "630px" : height + "px",
        backgroundColor: "#f5f5f5",
        position: "relative",
      }}
    >
      <video
        ref={videoRef}
        src={src}
        controls
        muted
        autoPlay
        preload="auto" // Preloads the video automatically
        playsInline
        style={{
          width: "100%",
          // height: height > 630 ? "630px" : height + "px",
          objectFit: height > 630 ? "contain" : "cover",
        }}
      />
    </div>
    </>
  );
};

export default VideoWithAspectRatio;




// import React, { useEffect, useRef } from "react";

// interface VideoWithAspectRatioProps {
//   aspectRatio: number;
//   src: string;
//   index: number; // Use index as identifier
//   setPlayingVideo: (index: number) => void; // Callback to notify parent
//   playingVideo: number; // Currently playing video index
// }

// const VideoWithAspectRatio: React.FC<VideoWithAspectRatioProps> = ({
//   aspectRatio,
//   src,
//   index,
//   setPlayingVideo,
//   playingVideo,
// }) => {
//   const videoRef = useRef<HTMLVideoElement>(null);
//   const height = 555 / aspectRatio;

//   useEffect(() => {
//     // Intersection Observer to detect when the video is in view
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setPlayingVideo(index); // Notify parent this video is in view
//           }
//         });
//       },
//       { threshold: 0.5 } // Trigger when 50% of the video is visible
//     );

//     if (videoRef.current) {
//       observer.observe(videoRef.current);
//     }

//     return () => {
//       if (videoRef.current) {
//         observer.unobserve(videoRef.current);
//       }
//     };
//   }, [index, setPlayingVideo]);

//   useEffect(() => {
//     // Play or pause video based on playingVideo state
//     if (videoRef.current) {
//       if (playingVideo === index) {
//         videoRef.current.play().catch((err) => {
//           console.error("Error playing video:", err);
//         }); // Play only the currently visible video
//       } else {
//         videoRef.current.pause(); // Pause all other videos
//         videoRef.current.currentTime = 0; // Reset the video for consistency
//       }
//     }
//   }, [playingVideo, index]);

//   return (
//     <div
//       style={{
//         height: height > 630 ? "630px" : height + "px",
//         backgroundColor: "#f5f5f5",
//       }}
//     >
//       <video
//         ref={videoRef}
//         src={src}
//         controls
//         muted
//         style={{
//           width: "100%",
//           height: height > 630 ? "630px" : height + "px",
//           objectFit: height > 630 ? "contain" : "cover",
//         }}
//       />
//     </div>
//   );
// };

// export default VideoWithAspectRatio;


// import React from "react";

// interface VideoWithAspectRatioProps {
//   aspectRatio: number;
//   src: string;
// }

// const VideoWithAspectRatio: React.FC<VideoWithAspectRatioProps> = ({
//   aspectRatio,
//   src,
// }) => {
//   const height = 555 / aspectRatio;

//   return (
//     <div
//       style={{
//         height: height > 630 ? "630px" : height + "px",
//         backgroundColor: "#f5f5f5",
//       }}
//     >
//       <video
//         src={src}
//         controls
//         autoPlay
//         muted
//         style={{
//           width: "100%",
//           height: height > 630 ? "630px" : height + "px",
//           objectFit: height > 630 ? "contain" : "cover",
//         }}
//       />
//       {/* <video
//         src={src}
//         controls
//         autoPlay
       
//         style={{
//           width: "100%",
//           height: height > 630 ? "630px" : height + "px",
//           objectFit: height > 630 ? "contain" : "cover",
//         }}
//         // Enable video caching
//         preload="auto"
//         // crossOrigin="anonymous"
//         playsInline
//       /> */}
//     </div>
//   );
// };

// export default VideoWithAspectRatio;
