import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import EventsCard from "./EventsCard";
import { fetchSavedEvents } from "../../services/eventsApis";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Language } from "../../App";

const SavedEvents = () => {
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useContext(Language);

  const handleEvents = async () => {
    try {
      setIsLoading(true);
      const data = await fetchSavedEvents();
      if (data.status) {
        setEventsData(data.data);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error("Error fetching events:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleEvents();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <ShimmerContentBlock
            title
            text
            thumbnailWidth={250}
            thumbnailHeight={250}
          />
          <ShimmerContentBlock
            title
            text
            thumbnailWidth={250}
            thumbnailHeight={250}
          />
        </>
      ) : eventsData && eventsData.length > 0 ? (
        eventsData?.map((value) =>
          value.data.map((val) => <EventsCard key={val.id} data={val} />)
        )
      ) : (
        <div className="text-center my-3">
          <p className="roboto-medium fs-14 text-muted">{lang.no_events_found}</p>
        </div>
      )}
    </>
  );
};

export default SavedEvents;
