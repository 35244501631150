
import React, { useContext, useState } from 'react';
import { Container, Row } from 'reactstrap';
import NavBar from '../../NavBar';
import CourseHeader from './CourseHeader';
import SubcategoryCards from '../SubcategoryCards';
import { useInfiniteQuery } from 'react-query';
import { fetchAllCoursesTypeData } from '../../../services/coursesApi';
import SeeAllCoursesCard from './SeeAllCoursesCard';
import ScrollToTop from './ScrollToTop';
import { toast } from 'react-toastify';
import { Language } from '../../../App';



const SeeAllPurchasedCourses = () => {
  const [coursesHeading, setCoursesHeading] = useState("Purchased Courses");
  const [categoryId, setCategoryId] = useState(null); // Track category selection
  const [internalLoading, setInternalLoading] = useState(false);
  const {lang} = useContext(Language)

  const details = {
    title: "Purchased Courses",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    buttonTitle: lang.go_back_to_main_page
  };
  // Fetch trending or category courses using useInfiniteQuery
  const {
    data: trendingData,
    isLoading: isTrendingLoading,
    isFetchingNextPage: isFetchingTrendingNextPage,
    fetchNextPage: fetchNextTrendingPage,
    hasNextPage: hasNextTrendingPage
  } = useInfiniteQuery({
    queryKey: ["purchasedCourses", categoryId],
    queryFn: ({ pageParam = 1 }) => fetchAllCoursesTypeData({ type: "purchased", page: pageParam }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage?.data?.current_page + 1;
      return nextPage <= lastPage?.data?.last_page ? nextPage : undefined; // Correctly return the next page
    },
    keepPreviousData: true,
    enabled: !categoryId, // Only fetch trending data when no category is selected
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // Fetch courses based on category ID if selected
  const {
    data: categoryData,
    isLoading: isCategoryLoading,
    isFetchingNextPage: isFetchingCategoryNextPage,
    fetchNextPage: fetchNextCategoryPage,
    hasNextPage: hasNextCategoryNextPage
  } = useInfiniteQuery({
    queryKey: [categoryId],
    queryFn: ({ pageParam = 1 }) => fetchAllCoursesTypeData({ category_id: categoryId, page: pageParam }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage?.data?.current_page + 1;
      return nextPage <= lastPage?.data?.last_page ? nextPage : undefined; // Correct next page logic
    },
    keepPreviousData: true,
    enabled: !!categoryId, // Only fetch when a category is selected
    onError: (error) => {
      toast.error(error.message);
    },
    onSettled: () => {
      setInternalLoading(false); // Set internal loading to false after fetch completes
    }
  });

  // Flatten the paginated data
  const trendingCourses = trendingData?.pages?.flatMap((page) => page.data.data) || [];
  const categoryCourses = categoryData?.pages?.flatMap((page) => page.data.data) || [];

  const handleCategoryData = (data, name, loading, id) => {
    setCategoryId(id); // Set the selected category
    setCoursesHeading(name || coursesHeading); // Update heading
    if(id){
    setInternalLoading(true);} 
  };

  const handleLoadMore = () => {
    // Check if there are more pages and the API call is not already in progress
    if (categoryId) {
      if (hasNextCategoryNextPage && !isFetchingCategoryNextPage) {
        fetchNextCategoryPage();
      }
    } else {
      if (hasNextTrendingPage && !isFetchingTrendingNextPage) {
        console.log("isFetchingTrendingNextPage",isFetchingTrendingNextPage)
        fetchNextTrendingPage();
      }
    }
  };
  

  return (
    <>
      <ScrollToTop />
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row>
          <div className="mt-5">
            <div className='container my-5'>
              <CourseHeader
                title={details.title}
                description={details.description}
                buttonTitle={details.buttonTitle}
                onClick={() => {}}
              />
              <SubcategoryCards
                categories={trendingData?.pages[0]?.subcategories} // Pass subcategories from the first API call
                loading={isTrendingLoading}
                onCategoryDataFetched={handleCategoryData} // Handle category selection
                defaultCoursesData={trendingData?.pages[0]?.data?.data}
                defaultHeading={details.title}
              />
              <SeeAllCoursesCard
                heading={coursesHeading}
                cardData={categoryId ? categoryCourses : trendingCourses} // Show category courses if a category is selected, otherwise trending
                // loading={categoryId ? isCategoryLoading  : isTrendingLoading }
                loading={internalLoading || (categoryId ? isCategoryLoading : isTrendingLoading)} // Combine internal and query loading states
                loadMore={handleLoadMore} // Load more pages based on selection
                hasMore={categoryId ? hasNextCategoryNextPage : hasNextTrendingPage} // Check if more pages exist
              />
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SeeAllPurchasedCourses;
