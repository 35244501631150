import React, { useContext, useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import UploadIcon from "../../assets/images/icon/upload.png";
import Cropper from "../../cropper/components/CroppersWizard.tsx";
import axios from "axios";
import { AddClinicalStudies, AddSurvay } from "../../constants/API.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FEED } from "../../constants/RoutesUrl.js";
import { Language } from "../../App.js";

const AddForm = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  
  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = SplashData?.user?.id;
  const sub_category = SplashData?.home?.subcategories;
  const {data:userToken} = JSON.parse(localStorage.getItem("SignupData"));

  const [qsModal, setQsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [qs, setQs] = useState([{ question: "", answers: ["", "", ""] }]);
  const [savedQs, setSavedQs] = useState([]);
  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [ar, setAR] = useState(0.86);
  const [imageModal, setImageModal] = useState(false);
  const [pdf, setPdf] = useState("");
  const [fileUploadRefs, setFileUploadRefs] = useState({});
  const [resizable, setResizable] = useState(true);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [hasSettings, setHasSettings] = useState(false);
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const [top, setTop] = useState(undefined);
  const [left, setLeft] = useState(undefined);
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(false);
  const [numberOfImages, setNumberOfImages] = useState(
    Object.keys(images).length < 5 ? Object.keys(images).length : 5
  );
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    subcategory_id: "",
    isChecked: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      title: formData.title,
      type: "clinical-image",
      category: "3",
      subcategory: formData.subcategory_id,
      caption: formData.description,
      mediaBase64: images,
    };

    axios
      .post(
        AddClinicalStudies ,
        data,

        {
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
       
          headers: { "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${userToken?.token}`,
           },
        }
      )
      .then((res) => {
        if (res.data.status) {
          handleSurvay(res.data.post.id,qs);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSurvay = (post_id,questionsData) => {
    console.log("post_id",post_id)
    const questions = [];
    const option1 = [];
    const option2 = [];
    const option3 = [];
console.log("savedQs",savedQs)
questionsData.forEach((item) => {
  questions.push(item.question);
  option1.push(item.answers[0]);
  option2.push(item.answers[1]);
  option3.push(item.answers[2]);
});
    // savedQs.forEach((item) => {
    //   questions.push(item.question);
    //   option1.push(item.answers[0]);
    //   option2.push(item.answers[1]);
    //   option3.push(item.answers[2]);
    // });

    const data = { pdf, questions, option1, option2, option3 ,post_id};

    axios
      .post(AddSurvay, data, {
        headers: { "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken?.token}`,
         },
      })
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          navigate(FEED);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Col
        xl={8}
        className="border border-1 border-inherit bg-white rounded p-3"
      >
        <div className="text-center">
          <h5>{lang?.create_case_study}</h5>
        </div>

        <form>
          <Row className="my-3">
            <Col>
              <div
                className="cursor"
                style={{
                  border: "1px dashed #A2A2A2",
                  backgroundColor: "#F2FCFE",
                  minHeight: "12rem",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  fileUploadRefs[numberOfImages - 1].current.click();
                }}
              >
                <div className="text-center mt-5">
                  <img src={UploadIcon} width={40} height={40} alt="picture" />
                  <h4
                    className="fs-14 roboto-medium pt-3"
                    style={{ color: "#00c5de" }}
                  >
                    {lang?.add_clinical_studies_images}
                  </h4>
                </div>
              </div>
            </Col>
          </Row>

          {images && images.length > 0 && (
            <>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex gap-2">
                  {images.map((value, index) => (
                    <div
                      key={index}
                      className="cursor position-relative"
                      style={{
                        border: "1px dashed #A2A2A2",
                        backgroundColor: "#F2FCFE",
                        minHeight: "6rem",
                        width: "6rem",
                        borderRadius: "6px",
                      }}
                    >
                      <button
                        className="fw-bold btn btn-sm btn-secondary position-absolute end-0 px-1 py-0 me-1 mt-1"
                        type="button"
                        onClick={() => {
                          const data = [...images];
                          data.splice(index, 1);
                          setImages(data);
                        }}
                      >
                        x
                      </button>
                      <img
                        src={value}
                        alt="picture"
                        onClick={() => {
                          setImagePreview(images[index]);
                          setImageModal(true);
                        }}
                        className="img_fluid"
                        style={{ height: "5.8rem", width: "5.8rem" }}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col>
              <hr />
            </Col>
          </Row>

          <Row className="my-3">
            <Col>
              <label className="mb-1">{lang?.title}</label>
              <input
                type="text"
                value={formData?.title}
                onChange={(e) => {
                  setFormData({ ...formData, title: e.target.value });
                }}
                className="form-control py-2"
                placeholder={lang?.case_study_title}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <label className="mb-1">{lang?.category}</label>
              <select
                className="form-select py-2"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    subcategory_id: e.target.value,
                  });
                }}
              >
                <option value="">{lang?.case_study_category}</option>
                {sub_category.map((value) => (
                  <option value={value.id} key={value.id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <label className="mb-1">{lang?.add_study_desc}</label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setFormData({ ...formData, description: e.target.value });
                }}
                rows={5}
                placeholder={lang?.case_study_short_desc}
              ></textarea>
            </Col>
          </Row>

          <Row className="my-3">
            <Col md={12}>{lang?.atach_com_case_study_pdf} </Col>
            <Col className="my-2 text-blue align-self-center">
              <h6
                className="cursor"
                onClick={() => {
                  document.getElementById("pdfinput").click();
                }}
              >
                <FaPlus className="mb-1" /> <span>{lang?.add_pdf_file}</span>
              </h6>
              <input
                type="file"
                id="pdfinput"
                className="d-none"
                accept=".pdf"
                onChange={(e) => {
                  const pdfFile = e.target.files[0];
                  setPdf(pdfFile);
                }}
              />
            </Col>
            {pdf && (
              <Col md={1} className="align-self-center">
                <FaFilePdf
                  size={30}
                  color="red"
                  className="cursor"
                  onClick={() => {
                    const fileURL = URL.createObjectURL(pdf);
                    window.open(fileURL, "_blank");
                    URL.revokeObjectURL(fileURL);
                  }}
                />
              </Col>
            )}
          </Row>

          <Row className="my-3">
            <Col md={12}>
              {lang?.add_survay} <span className="text-gray">{lang?.opti}</span>
            </Col>
            <Col className="my-2 text-blue align-self-center">
              <h6 className="cursor" onClick={() => setQsModal(true)}>
                <FaPlus className="mb-1" /> <span>{lang?.add_question}</span>
              </h6>
            </Col>
          </Row>

          <Row className="my-3">
            <Col>
              <input
                className="form-check-input p-10"
                type="checkbox"
                checked={formData.isChecked}
                onChange={(e) => {
                  const { checked } = e.target;
                  setFormData({ ...formData, isChecked: checked });
                }}
              />
              <label className="fs-16 roboto-medium form-check-label ps-1 pt-1">
                {lang?.to_publish_study_patient_clinical_cse}
              </label>
            </Col>
          </Row>

          {!isLoading ? (
            <Row>
              <Col>
                <button
                  type="submit"
                  disabled={
                    !images ||
                    images.length === 0 ||
                    !pdf ||
                    !formData.description ||
                    !formData.subcategory_id ||
                    !formData.title ||
                    !formData.isChecked
                  }
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  className="btn-blue border-0 rounded w-100 py-3 my-3"
                >
                  {lang?.save}
                </button>
              </Col>
            </Row>
          ) : (
            <Row className="my-3">
              <Col>
                <div className="w-50 pb-2">
                  <p>
                    {lang?.uploaded_para}
                    <br />- {progress} {lang?.uploaded}
                  </p>
                </div>
                <div>
                  <Progress animated color="primary" striped value={progress} />
                </div>
              </Col>
            </Row>
          )}
        </form>
      </Col>

      <Modal isOpen={imageModal} zIndex={9} centered>
        <ModalHeader toggle={() => setImageModal(false)}></ModalHeader>
        <ModalBody>
          <img src={imagePreview} className="img-fluid" alt="picture" />
        </ModalBody>
      </Modal>

      <Modal isOpen={qsModal} zIndex={9} centered scrollable>
        <ModalHeader toggle={() => setQsModal(false)}>
          {lang?.add_question}
        </ModalHeader>
        <ModalBody>
          {qs.map((value, index) => (
            <div>
              <div className="d-flex gap-2">
                <h6 className="align-self-end">{index + 1}.</h6>{" "}
                <input
                  type="text"
                  className="form-control"
                  placeholder={lang?.write_question_here}
                  value={value.question}
                  onChange={(e) => {
                    const updatedQuestionsData = [...qs];
                    updatedQuestionsData[index] = {
                      question: e.target.value,
                      answers: qs[index].answers,
                    };
                    setQs(updatedQuestionsData);
                  }}
                />
              </div>
              <div className="my-2">
                <ol type="i">
                  {value.answers.map((data, id) => (
                    <li>
                      <input
                        type="text"
                        className="form-control my-2"
                        placeholder={lang?.write_option_here}
                        value={data}
                        onChange={(e) => {
                          const updatedQuestionsData = [...qs];
                          updatedQuestionsData[index].answers[id] =
                            e.target.value;
                          setQs(updatedQuestionsData);
                        }}
                      />
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          ))}

          <div className="text-end">
            <button
              className="bg-transparent text-blue border-0"
              onClick={() => {
                var disable = false;
                qs.map((value) => {
                  if (value.question === "") {
                    disable = true;
                    toast.error(lang?.enter_question_please);
                  }
                  value.answers.map((data) => {
                    if (data === "") {
                      disable = true;
                      toast.error(lang?.enter_option_please);
                    }
                  });
                });
                if (!disable) {
                  setSavedQs([...qs]);
                  const newQuestion = { question: "", answers: ["", "", ""] };
                  const updatedQuestionsData = [...qs];
                  updatedQuestionsData.push(newQuestion);
                  setQs(updatedQuestionsData);
                }
              }}
            >
              <FaPlus /> {lang?.add_question}
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex w-100 gap-3">
            <button
              className="btn btn-secondary py-2 w-100 rounded"
              onClick={() => setQsModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue rounded py-2 w-100 border-0"
              onClick={() => {
                setQsModal(false);
              }}
            >
              {lang?.save}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      <Cropper
        images={images}
        setImages={setImages}
        setImagesArr={setImages}
        max={5}
        allowCrop={true}
        ar={ar}
        setAR={setAR}
        resizable={resizable}
        setResizable={setResizable}
        handleClose={() => setCropperOpen(false)}
        fileUploadRefs={fileUploadRefs}
        setFileUploadRefs={setFileUploadRefs}
        numberOfImages={numberOfImages}
        setNumberOfImages={setNumberOfImages}
        hasSettings={hasSettings}
        setHasSettings={setHasSettings}
        updateCoordinatesCheck={updateCoordinatesCheck}
        setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
        cropperOpen={cropperOpen}
        setCropperOpen={setCropperOpen}
        height={height}
        setHeight={setHeight}
        width={width}
        setWidth={setWidth}
        top={top}
        setTop={setTop}
        left={left}
        setLeft={setLeft}
      />
    </>
  );
};

export default AddForm;
