"use client";

import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Language } from "../../App";

const NewAuthFooter = ({ position }) => {
  const { lang, updateLang } = useContext(Language);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  // Set default language based on saved value or default
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    setSelectedLanguage(savedLanguage || "English");
  }, []);

  // Handle language selection
  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    updateLang(language);
  };

  return (
    <div
      className="d-lg-flex d-md-flex d-sm-flex d-xsm-none d-none align-items-center justify-content-center w-100 footer_btn"
      style={{ paddingTop: "25px", paddingBottom: "10px", backgroundColor: "#00c5de" }}
    >
      <div
        className={`${position} footer-text scroll-hide d-flex align-items-center gap-3`}
      >
        <p
          className="text-white mb-0"
          style={{ fontFamily: "NotoSans-regular", fontSize: "18px" }}
        >
          &copy; {lang?.new_rights}
        </p>
        <Link href="#" className="footer-link text-white">
          {lang?.new_user_aggrement}
        </Link>
        <Link href="#" className="footer-link text-white">
          {lang?.privacy_policy}
        </Link>
        <Link href="#" className="footer-link text-white">
          {lang?.new_community}
        </Link>
        <Link href="#" className="footer-link text-white">
          {lang?.new_cookie}
        </Link>
        <Link href="#" className="footer-link text-white">
          {lang?.new_feedback}
        </Link>
        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction="up">
          <DropdownToggle caret>
            {lang?.new_language}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleLanguageSelect("English")}
              active={selectedLanguage === "English"}
            >
              {lang?.new_english}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() => handleLanguageSelect("Spanish")}
              active={selectedLanguage === "Spanish"}
            >
              {lang?.new_spanish}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default NewAuthFooter;
