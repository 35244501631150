import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Spinner, Row, Col, Modal, ModalBody, Collapse } from 'reactstrap';
import { BsSendFill } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png";
import { Link } from 'react-router-dom';
import { TimestampConverter } from '../../Feed';
import ImageComponent from '../../../components/common/ImageComponent';
import axios from 'axios';
import { authorize } from '../../../shared/authorize';
import { _imagesURL, AddCommentApi, AddCommentRepliesApi, DeleteCommentApi, GetCommentsApi, GetRepliesApi } from '../../../constants/API';
import { PROFILE } from '../../../constants/RoutesUrl';
import { Language } from '../../../App';

const CommentCanvas = ({
  postModal,
  setPostModal,
 
  postId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [postComments, setPostComments] = useState({data:[]});
  const [comment, setComment] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [replyUser, setReplyUser] = useState(null);
  const [commentId, setCommentId] = useState('');
  const [commentReplies, setCommentReplies] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [replyDropdown, setReplyDropdown] = useState(false);
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const { lang } = useContext(Language);

  const handleGetComments = useCallback(
    async (post_id) => {
      setIsLoading(true);
      const id = post_id || postId;

      try {
        const response = await axios.get(
          `${GetCommentsApi}${id}?page=1`
          , authorize());
        if (response.data.status) {
          setPostComments(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postId, user_id]
  );
  // Handle adding a new comment
  const handleAddComment = useCallback(async () => {
    try {
      const response = await axios.post(
        `${AddCommentApi}`,
        { comment, post_id: postId }
        , authorize());
      if (response.data.status) {
        setComment("");
        handleGetComments();
      }
    } catch (error) {
      console.error(error);
    }
  }, [comment, postId, user_id, handleGetComments]);

  const handleDeleteComment = useCallback(async () => {
    try {
      const response = await axios.get(
        `${DeleteCommentApi}${commentId}`
        , authorize());
      if (response.data.status) {
        setCommentId("");
        setDeleteModal(false);
        handleGetComments();
      }
    } catch (error) {
      console.error(error);
    }
  }, [commentId, handleGetComments]);

  const handleGetReplies = useCallback(
    async (comment_id) => {
      try {
        const response = await axios.get(
          `${GetRepliesApi}${comment_id}`
          , authorize());
        if (response.data.status) {
          setCommentReplies(response.data.data);
          setReplyDropdown(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [user_id]
  );

  const handleAddReplies = useCallback(
    async (comment_id) => {
      try {
        const response = await axios.post(
          `${AddCommentRepliesApi}`,
          {
            parent: comment_id,
            post_id: postId,
            comment,
          }, authorize()
        );
        if (response.data.status) {
          setComment("");
          handleGetComments(postId);
          handleGetReplies(comment_id);
          setIsReply(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [comment, postId, user_id, handleGetComments, handleGetReplies]
  );
useEffect(()=>{
    handleGetComments()
},[])
  return (
    <>
      <Offcanvas
        direction="end"
        scrollable
        isOpen={postModal}
        // zIndex={9}
        style={{ width: "480px" }}
        toggle={() => setPostModal(false)}
      >
        <OffcanvasHeader
          className="bg-grey"
          toggle={() => setPostModal(false)}
        >
          {lang?.comments}
        </OffcanvasHeader>
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <OffcanvasBody className="bg-grey scroll-hide" style={{ paddingBottom: "5rem" }}>
          {isLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                {postComments?.data &&
                  postComments.data.map((content, index) => (
                    <div className="bg-white my-3 p-2 br-12" key={index}>
                      <div className="d-flex gap-3">
                        <div>
                          <Link to={`${PROFILE}/${content?.user?.id}`} className="d-flex gap-2 text-dark w-100">
                            <div className="bg-white dropdown-avatar-shadow align-self-center rounded" style={{ maxWidth: "8rem", maxHeight: "8rem", padding: "1.8px" }}>
                              <ImageComponent
                                src={content?.user?.image ? `${_imagesURL}${content?.user?.image}` : Avatar}
                                compClassName="img-fluid rounded"
                                loader_height="8rem"
                                loader_width="8rem"
                              />
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={`${PROFILE}/${content?.user?.id}`} className="roboto-bold mb-0 text-dark fs-16">
                            {content?.user?.name}
                          </Link>
                          <p className="roboto-regular text-gray fs-14">{content?.comment}</p>
                          <div className="d-flex gap-4">
                            <p className="roboto-medium fs-12">
                              <TimestampConverter timestamp={content?.time} />
                            </p>
                            <p className="roboto-medium text-blue cursor fs-12" onClick={() => { setIsReply(true); setReplyUser(content?.user); setCommentId(content?.id); }}>
                              {lang?.reply}
                            </p>
                            <p className="roboto-medium text-red cursor fs-12" onClick={() => { setCommentId(content?.id); setDeleteModal(true); }}>
                              {lang?.delete}
                            </p>
                          </div>
                        </div>
                      </div>
                      {content?.replies > 0 && (
                        <div>
                          <p className="mb-0 fw-bold text-end text-gray cursor fs-12 me-3" onClick={() => {
                            if (content?.id === commentId) {
                              setCommentId("");
                              setCommentReplies([]);
                            } else {
                              setCommentId(content?.id);
                              handleGetReplies(content?.id);
                            }
                          }}>
                            {content.id === commentId ? "Hide" : "View"} {content.replies} {content.replies > 1 ? "replies" : "reply"}
                          </p>
                          {content?.id === commentId && commentReplies.map((val) => (
                            <div key={val.id}>
                              <Collapse horizontal isOpen={true}>
                                <div className="mt-4 pt-3 ms-5">
                                  <div className="d-flex gap-3">
                                    <div>
                                      <div className="bg-white dropdown-avatar-shadow br-16 cursor" style={{ maxWidth: "3rem", maxHeight: "3rem", padding: "0.8px" }}>
                                        <ImageComponent
                                          src={val.user.image ? `${_imagesURL}${val.user.image}` : Avatar}
                                          loader_height="3rem"
                                          loader_width="3rem"
                                          compClassName="br-14 img-fluid"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <p className="roboto-bold mb-0 cursor fs-16">{val.user.name}</p>
                                      <p className="roboto-regular text-gray fs-14">{val.comment}</p>
                                      <div className="d-flex gap-4">
                                        <p className="roboto-medium fs-12"><TimestampConverter timestamp={val.time} /></p>
                                        <p className="roboto-medium text-blue cursor fs-12" onClick={() => { setIsReply(true); setReplyUser(val.user); setCommentId(content.id); }}>
                                          {lang?.reply}
                                        </p>
                                        <p className="roboto-medium text-red cursor fs-12" onClick={() => { setCommentId(val.id); setDeleteModal(true); }}>
                                          {lang?.delete}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                              <hr className="ms-5" style={{ opacity: "0.1" }} />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {postComments.data && postComments.data.length === 0 && (
                <div className="text-center">
                  <h3 className="text-gray">{lang?.there_no_comment}</h3>
                  <p className="text-gray">{lang?.no_one_commented_post}</p>
                </div>
              )}
            </>
          )}
          <div className="bottom-0 position-absolute m-auto end-0 start-0">
            {isReply && (
              <Row>
                <Col className="d-flex justify-content-between bg-blue br-12" style={{ marginLeft: "1.3rem", marginRight: "6.3rem" }}>
                  <h6 className="mb-0 py-2 text-white">{lang?.replying_to} {replyUser?.name}</h6>
                  <div className="align-self-center cursor" onClick={() => setIsReply(false)}>
                    <MdOutlineCancel color="white" size={20} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex">
                <input type="text" placeholder={lang?.write_your_comment} className="form-control m-2 py-3" value={comment} onChange={(e) => setComment(e.target.value)} />
                <div className="align-self-center">
                  <button type="button" disabled={!comment} className="border-0 px-4 py-3 btn-blue me-2 ms-1" onClick={() => { isReply ? handleAddReplies(commentId) : handleAddComment(); }}>
                    <BsSendFill size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      <Modal isOpen={deleteModal} centered 
      // zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">{lang?.do_want_delete_comment}</h2>
            <p className="fs-16 fs-md-15 roboto-regular">{lang?.after_comment_able_comment}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14" onClick={() => setDeleteModal(false)}>{lang?.cancel}</button>
            <button className="btn-red border-0 rounded w-100 py-2 fs-md-14" onClick={handleDeleteComment}>{lang?.yes_delete}</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CommentCanvas;
