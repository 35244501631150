
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RiImageAddFill } from 'react-icons/ri';
import { Language } from '../../App';

const CertificateModal = ({ isOpen, toggle, onSave, initialCertificate }) => {
    const [file, setFile] = useState(null); // Store the complete file object
    const [fileSrc, setFileSrc] = useState(null); // For the image preview
    const [certificateTitle, setCertificateTitle] = useState("");
    const fileInputRef = useRef();
const{lang} = useContext(Language)
    useEffect(() => {
        if (initialCertificate) {
            setFile(initialCertificate.file); // Assuming file is passed as part of initialCertificate
            setFileSrc(initialCertificate.fileSrc); // Assuming fileSrc is passed as part of initialCertificate
            setCertificateTitle(initialCertificate.title);
        } else {
            // Reset states if no initial certificate
            setFile(null);
            setFileSrc(null);
            setCertificateTitle("");
        }
    }, [initialCertificate]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile); // Set the file object directly
            const reader = new FileReader();
            reader.onloadend = () => {
                setFileSrc(reader.result); // Set the base64 string for preview
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setFileSrc(null); // Reset the preview source
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleSave = () => {
        const newCertificate = {
            title: certificateTitle,
            file, // Pass the complete file object here
            fileSrc, // Pass the base64 string for preview
        };

        // If we are editing an existing certificate, pass the index for update
        if (initialCertificate) {
            onSave(initialCertificate.index, newCertificate); // Update existing certificate
        } else {
            onSave(null, newCertificate); // Add new certificate
        }

        toggle(); // Close the modal
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="custom-modal-size">
            <ModalHeader className="text-center" toggle={toggle}>
               {lang.certificate_by}
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <div
                        style={{
                            cursor: "pointer",
                            border: "1px dashed #A2A2A2",
                            minHeight: "128px",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                        }}
                        onClick={() => {
                            if (!file) {
                                fileInputRef.current.click();
                            }
                        }}
                    >
                        {fileSrc ? (
                            <div style={{ position: 'relative', width: '100%', height: '128px' }}>
                                <img
                                    src={fileSrc} // Use the base64 string for the image preview
                                    alt="File Preview"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "6px"
                                    }} 
                                />
                                <button
                                    className='bg-white position-absolute end-0 me-3 mt-3 br-6 border-0 px-2 py-0 fs-16'
                                    onClick={handleRemoveFile}
                                >
                                    x
                                </button>
                            </div>
                        ) : (
                            <div className="d-flex flex-column align-items-center">
                                <RiImageAddFill size={24} color="#848798" />
                                <h4 className="mt-2 fs-14" style={{ color: "#848798" }}>
                                    {lang.add_certificate_image}
                                </h4>
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        className="d-none"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </div>

                <div className="my-3 row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control py-2"
                            placeholder="Certificate Title"
                            value={certificateTitle}
                            onChange={(e) => setCertificateTitle(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col">
                        <button disabled={!file} className="btn-blue border-0 py-2 px-5 w-100" onClick={handleSave}>
                            {lang.save}
                        </button>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col">
                        <button className="btn btn-secondary px-5 w-100" onClick={toggle}>
                            {lang.cancel}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default CertificateModal;
