import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Authlogo from "../../../assets/images/auth/newAuthLogo.svg";
import { GoDotFill } from "react-icons/go";
import NewAuthFooter from "../../../components/auth/NewAuthFooter";
import Doctor from "./Doctor";
import Hospital from "./Hospital";
import { toast } from "react-toastify";
import { Language } from "../../../App";

const NewSignup = () => {
  const { lang } = useContext(Language);
  const [accountType, setAccountType] = useState(0);
  const [checkIndex, setCheckIndex] = useState(null);

  const data = [
    {
      title: `1. ${lang?.doctors}`,
      description: lang?.new_doctor_desc,
    },
    {
      title: `2. ${lang?.new_hospital}`,
      description: lang?.new_hospital_desc,
    },
    {
      title: `3. ${lang?.new_organization}`,
      description: lang?.new_organization_desc,
    },
  ];

  console.log(checkIndex);

  return (
    <>
      <div className={`new_login_bg position-relative scroll-hide`}>
        <img
          src={Authlogo}
          className="position-absolute w_12"
          alt="LOGO"
          style={{ left: "40px", top: "40px" }}
        />
        <div
          className={`d-flex flex-column justify-content-center ${
            checkIndex === null || checkIndex === 2 || checkIndex === 1 ? "signup_height" : ""
          }`}
        >
          {/* <div
            className={`${
              checkIndex === null || checkIndex === 2 ? "d-block" : "d-none "
            }`}
          > */}
          <div className={`container  ${
            checkIndex === null || checkIndex === 2 ? "d-block" : "d-none"
          }`}>
            <div className="row">
              <div className="setup-size">
                <div className="log-box p-4 pt-5 mb-lg-0 mb-md-0 mb-sm-5 mb-xsm-5 mb-5" style={{ marginTop: "75px" }}>
                  <h5>
                    {lang?.select_account_type}
                    </h5>
                  <p className="mb-4">
                    {lang?.welcome_to} Medical Radar! {lang?.new_signup_desc}
                  </p>

                  {data.map((val, index) => (
                    <div
                      onClick={() => setAccountType(index)}
                      key={index}
                      className={`select-type py-2 px-3 d-flex align-items-center justify-content-between mb-3 ${
                        accountType === index ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex flex-column account">
                        <h5>{val.title}</h5>
                        <p>{val.description}</p>
                      </div>
                      <span
                        className={`d-flex align-items-center justify-content-center${
                          accountType === index ? " bg-transparent" : ""
                        }`}
                      >
                        {accountType === index && (
                          <GoDotFill
                            style={{ color: "#000000", fontSize: "14px" }}
                          />
                        )}
                      </span>
                    </div>
                  ))}

                  <button
                    onClick={() => {
                      setCheckIndex(accountType);
                      if (accountType === 2) {
                        toast.info(lang?.this_sec_available_soon);
                      }
                    }}
                    className="new_main_btn text-decoration-none w-100 d-flex align-items-center justify-content-center text-white p-12 w-100 my-3"
                  >
                    {lang?.new_continue_signup}
                  </button>

                  <small className="d-flex justify-content-center my-2 mb-3 res-pass w-100">
                    {lang?.new_already_account}
                    <Link to="/" className="ms-1 new_link">
                      {lang?.sign_in}
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {checkIndex === 1 && (<Hospital /> )}
        {checkIndex === 0 && (<Doctor /> )}
        </div>
        {/* {checkIndex === 0 ? <Doctor /> : checkIndex === 1 ? <Hospital /> : ""} */}
        <NewAuthFooter position={""} />
      </div>
    </>
  );
};

export default NewSignup;
