
import React, { createContext, useEffect, useState, useContext, useRef } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import { useQuery } from 'react-query';
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import { adminBaseURL } from "../../constants/API";
import { selectedChatContext } from "../../pages/chat/ChatModule";
import { LoginUserData } from "../../App";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { ChatsContext } from "./ChatsProvider";
import { getToken } from "../../shared/token";
import Message from "./Message";

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const groupMessagesByDate = (messages) => {
  return messages.reduce((acc, message) => {
    const date = formatDate(message.time);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});
};

export const RefetchMessagesContext = createContext();

export default function Chats() {
  const { selectedChat } = useContext(selectedChatContext);
  const { splashData } = useContext(LoginUserData);
  const { updateChatMessage } = useContext(ChatsContext);
  const [messages, setMessages] = useState([]);  // Current chat messages
  const [groupMessages, setGroupMessages] = useState([]);  // Current chat messages
  const [createdAt, setCreatedAt] = useState(null);  // Current chat messages
  const [participentsCount, setParticipentsCount] = useState(null);  // Current chat messages
  const [loading, setLoading] = useState(false); // Loading state
  const chatContainerRef = useRef(null);
  // Define the functions for fetching user and group messages
  const fetchUserMessages = async (userId) => {
    try {
      const response = await axios.post(`${adminBaseURL}user/chat/all/messages`, {
        to: userId,
      }, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        }
      });

      if (response.data.status === true) {
        return response.data.data.data.reverse(); // Return the messages for React Query
      } else {
        toast.error(response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch messages. Please try again.");
      throw error;
    }
  };

  const fetchGroupMessages = async (groupId) => {
    try {
      const response = await axios.post(`${adminBaseURL}user/group-chat/all/messages`, {
        group: String(groupId),
      }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        }
      });

      if (response.data.status === true) {
        setCreatedAt(response?.data?.data?.created_at)
        setParticipentsCount(response?.data?.data?.participants_count)
        return response.data.data.messages.reverse(); // Return the messages for React Query
      } else {
        toast.error(response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch group messages. Please try again.");
      throw error;
    }
  };

  // React Query hooks for fetching messages
  const { data: userMessagesData, refetch: refetchUserMessages } = useQuery(
    ['userMessages', selectedChat?.user?.id],
    () => fetchUserMessages(selectedChat?.user?.id),
    {
      enabled: !!selectedChat?.user?.id && selectedChat?.group_id === 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: groupMessagesData, refetch: refetchGroupMessages } = useQuery(
    ['groupMessages', selectedChat?.group_id],
    () => fetchGroupMessages(selectedChat?.group_id),
    {
      enabled: !!selectedChat?.group_id && selectedChat?.group_id !== 0,
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (selectedChat) {
        setLoading(true);
        setMessages([]); // Clear messages immediately when chat changes
        setGroupMessages([])
        if (selectedChat.group_id === 0) {
            if (userMessagesData) {
                setMessages(userMessagesData);
                setLoading(false);
            }
        } else {
            if (groupMessagesData) {
              setGroupMessages(groupMessagesData)
             
                // setMessages(groupMessagesData);
                setLoading(false);
            }
        }
    }
}, [selectedChat, userMessagesData, groupMessagesData]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
    });
    let channel;

    if (selectedChat?.group_id === 0) {
      channel = pusher.subscribe(String(selectedChat.from_to));
    } else {
      channel = pusher.subscribe(`group-${selectedChat?.group_id}`);
    }

    if (selectedChat.group_id !== 0) {
      channel.bind("new-group-message", function (data) {
        console.log("group pusher run",data)
        setGroupMessages((prevMessages) => [...prevMessages, data[0]]);
        // updateChatMessage(data.message, selectedChat.group_id);
        scrollToBottom();
      });
    } else {
      channel.bind("new-message", function (data) {
        setMessages((prevMessages) => [...prevMessages, data[0]]);
        // updateChatMessage(data.message, selectedChat.user.id);
        scrollToBottom();
      });
    }

    return () => {
      if (selectedChat?.group_id === 0) {
        pusher.unsubscribe(String(selectedChat?.user.id));
      } else {
        pusher.unsubscribe(`group-${selectedChat?.group_id}`);
      }
      pusher.unsubscribe(String(selectedChat?.from_to));
    };
  }, [selectedChat]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const shouldShowTime = (currentMessage, nextMessage) => {
    if (!nextMessage) return true;

    const currentTime = parseInt(currentMessage.time) * 1000;
    const nextTime = parseInt(nextMessage.time) * 1000;

    if (currentMessage.user_id === nextMessage.user_id) {
      const currentFormattedTime = new Date(currentTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      const nextFormattedTime = new Date(nextTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

      if (currentFormattedTime === nextFormattedTime) {
        return false;
      }
    }

    return true;
  };

  const shouldShowAvatar = (currentMessage, previousMessage) => {
    if (!previousMessage) return true;

    if (currentMessage.user_id === previousMessage.user_id) {
      const currentTime = parseInt(currentMessage.time);
      const previousTime = parseInt(previousMessage.time);

      if ((currentTime - previousTime) > 60) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const groupedMessages = groupMessagesByDate(messages);
  const _groupedMessages = groupMessagesByDate(groupMessages);

  const formatMessage = (message) => {
    if (typeof message !== 'string' || !message) {
      return '';
    }

    if (message.includes("\r\n") || message.includes("\n")) {
      return message.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    }

    return message;
  };

  return (
    <RefetchMessagesContext.Provider
      value={{ refetchUserMessages, refetchGroupMessages }}
    >
      <ChatHeader createdAt={createdAt} participentsCount={participentsCount} refetchGroupMessages={refetchGroupMessages}/>
      <div
        style={{ height: "calc(100vh - 210px)", overflowX: "hidden" }}
        ref={chatContainerRef}
      >
        {loading ? (
          <div className="spinner-container my-5">
            <Spinner />
          </div>
        ) : (
          <div>
            {selectedChat.group_id===0?Object.keys(groupedMessages).map((date) => (
              <div
                className="p-2 d-flex flex-column justify-content-end gap-2"
                key={date}
              >
                <div className="text-center text-secondary my-2">{date}</div>
                {groupedMessages[date].map((message, index, array) => (
                <Message
                    key={message.id}
                    text={formatMessage(message.message)}
                    time={message.time}
                    isSender={
                      selectedChat.group_id === 0 ? message.from === splashData?.user.id : message.user_id === splashData?.user.id
                    }
                    attachment={message.attachment_type === "image" ? message.attachment : null}
                    showTime={shouldShowTime(message, array[index + 1])}
                    avatar={selectedChat.group_id !== 0 ? message?.user?.image : ""}
                    message={message}
                    isGroup={selectedChat.group_id !== 0}
                    fullname={message?.user?.name}
                    showAvatar={shouldShowAvatar(message, array[index - 1])}
                  />
                ))}
              </div>
            )):Object.keys(_groupedMessages).map((date) => (
              <div
                className="p-2 d-flex flex-column justify-content-end gap-2"
                key={date}
              >
                <div className="text-center text-secondary my-2">{date}</div>
                {_groupedMessages[date].map((message, index, array) => (
                <Message
                    key={message.id}
                    text={formatMessage(message.message)}
                    time={message.time}
                    isSender={
                      selectedChat.group_id === 0 ? message.from === splashData?.user.id : message.user_id === splashData?.user.id
                    }
                    attachment={message.attachment_type === "image" ? message.attachment : null}
                    showTime={shouldShowTime(message, array[index + 1])}
                    avatar={selectedChat.group_id !== 0 ? message?.user?.image : ""}
                    message={message}
                    isGroup={selectedChat.group_id !== 0}
                    fullname={message?.user?.name}
                    showAvatar={shouldShowAvatar(message, array[index - 1])}
                  />
                ))}
              </div>
            ))}
          </div>
          
        )}
      </div>
      <ChatInput />
    </RefetchMessagesContext.Provider>
  );
}
