import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse, Container, Row } from "reactstrap";
import NavBar from "../components/NavBar";
import CreateNewJob from "../components/AllJobs/CreateNewJob";
import { useParams } from "react-router-dom";
import AllJobs from "../components/jobs/AllJobs";
import MyJobs from "../components/jobs/MyJobs";
import SavedJobs from "../components/jobs/SavedJobs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../App";

const Jobs = () => {
  const param = useParams();
  const [tabs, setTabs] = useState(param.id);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );
  const { lang } = useContext(Language);

  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row style={{ paddingTop: "4rem" }}>
        <Col xl={3} className="overflowY-auto scroll-hide pt-3">
          <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
            <div
              onClick={() => setOpenSidebar(!openSidebar)}
              className="p-3 d-flex justify-content-between align-items-center cursor"
            >
              <h1 className="fs-20 fs-sm-16 m-0 roboto-bold">
                {lang?.medical_jobs}
              </h1>
              <span className="d-xl-none">
                {openSidebar ? (
                  <CiCircleRemove size={22} />
                ) : (
                  <MdOutlineKeyboardArrowRight size={26} />
                )}
              </span>
            </div>

            <Collapse isOpen={openSidebar}>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 1 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(1);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(1);
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.all_jobs}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 2 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(2);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(2);
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.my_jobs}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 3 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(3);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(3);
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.saved_jobs}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 4 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(4);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(4);
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.create_new_job}
                </h1>
              </div>
            </Collapse>
          </div>
        </Col>
        <Col
          xl={9}
          lg={12}
          md={12}
          sm={12}
          className="overflowY-auto vh-100 pb-15-rem pt-3 scroll-hide"
        >
          {tabs == 1 ? (
            <AllJobs />
          ) : tabs == 2 ? (
            <MyJobs />
          ) : tabs == 3 ? (
            <SavedJobs />
          ) : (
            tabs == 4 && <CreateNewJob />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Jobs;
