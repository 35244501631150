import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { _imagesURL, adminBaseURL, BaseURL, GetCommentsApi, RepostApi } from "../../constants/API";
import { PostCarousel } from "./PostsResultsComp";
import SearchedPostHeader from "./SearchedPostHeader";
import SearchResultCard from "./SearchResultCard";
import { useCallback, useContext, useEffect, useState } from "react";
import { Language, LoginUserData } from "../../App";
import PostFooter from "../feeds/PostFooter";
import { ReadMore } from "../../pages/Feed";
import axios from "axios";
import { toast } from "react-toastify";
import { likePostApi, savePostApi, usersLikedPostApi } from "../../services/feedApis";
import { authorize } from "../../shared/authorize";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png"
import { Link, useNavigate } from "react-router-dom";
import CommentCanvas from "../../pages/post-details/comment-details/CommentCanvas";
import InfiniteScroll from "react-infinite-scroller";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";

export default function ClinicalStudiesResults({ data, loading, keyword }) {
  const {lang} = useContext(Language)
  return (
    <SearchResultCard
      title={lang.clinical_studies}
      link={`/search/clinicals?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      <div>
        {data?.map((item) => (
          <ClinicalStudiesCard key={item.id} post={item} />
        ))}
      </div>
    </SearchResultCard>
  );
}

export const ClinicalStudiesCard = ({ post }) => {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const [data, setData] = useState({});
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likePage, setLikePage] = useState(1);
  const [postId, setPostId] = useState("");
  const [page, setPage] = useState(1);
  const [likeUsers, setLikeUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [postModal, setPostModal] = useState(false);

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const navigate = useNavigate()
  const handleRepost = async () => {
    try {
      setData({ ...data, isShare: !data.isShare });
      const response = await axios.get(
        `${RepostApi}${post?.id}`,authorize()
      );
      if (response.data.status) {
        toast.success(response.data.action);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = async () => {
    try {
      setData({ ...data, isSave: !data.isSave });
      const response = await savePostApi({post_id:data?.id});
      if (!response.data.status) {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLike = async () => {
    try {
      const updatedData = {
        ...data,
        isLike: !data.isLike,
        likes_count: data.isLike ? data.likes_count - 1 : data.likes_count + 1,
      };
      setData(updatedData);
      await likePostApi({post_id:data.id});
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleLikeUsers = useCallback(
    async (otherId) => {
      setLikeSpinner(true);
      setLikeUsersModal(true);
      try {
        const response = await
          axios.get(
            `${adminBaseURL}user/post/likes/${otherId}`, authorize()
          );
        if (response.data.status) {
          setLikeUsers(response.data.data);
        } else {
          toast.error(response.data.error);
          setLikeUsersModal(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLikeSpinner(false);
      }
    },
    [user_id]
  );
  const handleNewLikeUsers = useCallback(async () => {
    try {
      const response = await usersLikedPostApi({ post_id: postId, page })
      if (response.data.status) {
        const update = likeUsers.data.concat(response.data.data.data);
        setLikeUsers((prevUsers) => ({ ...prevUsers, data: update }));
        setLikePage((prevPage) => prevPage + 1);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  }, [likePage, likeUsers, postId, user_id]);
  const handleGetComments = useCallback(
    async (post_id) => {
      setIsLoading(true);
      const id = post_id || postId;
      try {
        const response = await axios.get(
          `${GetCommentsApi}${id}?page=1`
          , authorize());
        if (response.data.status) {
          // setPostComments(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postId, user_id]
  );
  useEffect(() => {
    setData(post);
  }, [post]);
  return (
    <>
    <div className="border border-inherit p-2">
      <SearchedPostHeader postDetails={post} />
      <PostCarousel post={post} />
      <Row className="justify-content-between p-2">
        <Col className="align-self-center">
          <h4 className="mb-0">
            {post?.title && post?.title?.length > 26
              ? post?.title?.slice(0, 26) + "..."
              : post?.title}
          </h4>
        </Col>
        {post?.pdf && (
          <Col className="text-end">
            <button
              className="border-0 px-3 py-1 text-white btn-blue"
              onClick={() => {
                window.open(_imagesURL + post.pdf, "_blank");
              }}
            >
              {lang?.view_Case_Study}
            </button>
          </Col>
        )}
      </Row>
      {post?.caption && (
        <>
          <Row className="p-2">
            <Col>
              <ReadMore text={post?.caption} />
            </Col>
          </Row>
          <hr className="m-0" />
        </>
      )}
      <PostFooter
        likes_count={post?.likes_count}
        setPostId={setPostId}
        original_id={post?.original_id}
        handleLikeUsers={handleLikeUsers}
        like_users_images={post?.like_users_images}
        handleLike={handleLike}
        isLike={post?.isLike}
        handleGetComments={handleGetComments}
        setPostModal={setPostModal}
        comments_count={post?.comments_count}
        handleRepost={handleRepost}
        isShare={post?.isShare}
        isSave={post?.isSave}
        handleSave={handleSave}
      />

    </div>
    <Modal
        isOpen={likeUsersModal}
        toggle={() => setLikeUsersModal(!likeUsersModal)}
        centered
        zIndex={9}
      >
        <ModalHeader toggle={() => setLikeUsersModal(false)}>
          {lang?.liked_users}
        </ModalHeader>
        <ModalBody>
          {likeSpinner ? (
            <Col className="my-4 text-center">
              <Spinner />
            </Col>
          ) : (
            <div>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewLikeUsers}
                hasMore={likeUsers.current_page !== likeUsers.last_page}
                loader={
                  <Col className="text-center">
                    <Spinner />
                  </Col>
                }
                useWindow={false}
              >
                {likeUsers.data &&
                  likeUsers.data.map((value, index) => (
                    <>
                      <Link
                        onClick={() => {
                          navigate(PROFILE + "/" + value.id);
                        }}
                        className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                        key={index}
                      >
                        <div className="d-flex gap-2">
                          <div
                            className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                            style={{
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? _imagesURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="roboto-bold text-dark fs-17 search-post-name">
                              {value.name}
                            </div>
                            <p className="roboto-regular mb-0 fs-12 search-post-name">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </ModalBody>
      </Modal>
      {postModal && <CommentCanvas
        postModal={postModal}        // Controls the visibility of the canvas
        setPostModal={setPostModal}   // Function to toggle the modal’s visibility
        postId={postId}
      />}
    </>
  );
};
