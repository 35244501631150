import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { Language, LoginUserData, PostDetailContext } from "../../App";
import { _imagesURL } from "../../constants/API";
import ImageComponent from "./ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { ReadMore } from "../../pages/Feed";
import Like from "../../assets/images/icon/like.png";
import Liked from "../../assets/images/icon/liked.png";
import Reshare from "../../assets/images/icons/repost.svg";
import ReshareActive from "../../assets/images/icons/repost-active.svg";
import Save from "../../assets/images/icons/save.svg";
import Saved from "../../assets/images/icons/saved.svg";
import Comment from "../../assets/images/icon/comment.png";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { likePostApi, repostPostApi, reserveWebinarApi, savePostApi, usersLikedPostApi } from "../../services/feedApis";
import { useMutation, useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import { PROFILE } from "../../constants/RoutesUrl";
import CommentCanvas from "../../pages/post-details/comment-details/CommentCanvas";

export default function PostDetailModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const { postDetail, updatePostDetail, postModalClose } = useContext(PostDetailContext);
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const [commentModal, setCommentModal] = useState(false);

  const [isLikedUsersModal, setIsLikedUsersModal] = useState(false);
  const [width, setWidth] = useState("47%");
  const [hideText, setHideText] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setHideText(window.innerWidth < 990);
      setWidth(window.innerWidth < 1200 ? "60%" : "47%");
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLike = useMutation({
    mutationFn: () => likePostApi({ post_id: postDetail.original_id }),
    onError: (error) => toast.error(error.message),
    onMutate: () => {
      updatePostDetail({
        ...postDetail,
        isLike: !postDetail?.isLike,
        likes_count: postDetail.isLike
          ? postDetail?.likes_count - 1
          : postDetail?.likes_count + 1,
      });
    },
    onSuccess: (data) => {
      if (!data.status) toast.error(data.action);
    },
  });

  const handleSave = useMutation({
    mutationFn: () => savePostApi({ post_id: postDetail.original_id }),
    onError: (error) => toast.error(error.message),
    onMutate: () => {
      updatePostDetail({ ...postDetail, isSave: !postDetail?.isSave });
    },
    onSuccess: (data) => {
      if (!data.status) toast.error(data.action);
    },
  });
  const handleReserveWebinar = useCallback(async () => {
    try {
      updatePostDetail({
        ...postDetail,
        isReserve: !postDetail?.isReserve,
      });
      await reserveWebinarApi({post_id:postDetail.original_id});
    } catch (error) {
      console.error(error);
    }
  }, [postDetail]);
  const handleRepost = useMutation(
    () => repostPostApi({ post_id: postDetail.original_id }),
    {
      onError: (error) => toast.error(error.message),
      onMutate: () => {
        updatePostDetail({ ...postDetail, isShare: !postDetail?.isShare });
      },
      onSuccess: (data) => {
        if (!data.status) toast.error(data.action);
      },
    }
  );
  function convertTimestampToFormattedTime(timestamp) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    
    const timeString = date.toLocaleTimeString('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    });
  
    const dateString = date.toLocaleDateString('en-US', {
      weekday: 'long',
      day: '2-digit',
      year: 'numeric',
      month: 'long'
    });
  
    return `${timeString} on ${dateString}`;
  }
  return postDetail?.original_id ? (
    <>
      <Modal isOpen={isOpen} toggle={onClose} centered size="xl" className="rounded">
        <div className="d-flex flex-column flex-lg-row bg-white rounded w-100">
          <div
            style={{
              backgroundColor: "#f5f5f5",
              width: window.innerWidth < 992 ? "100%" : "65%",
              borderRadius: "5px 0 0 5px",
            }}
          >
     
            {postDetail?.type === "image" ||
            postDetail?.type === "webinar" ||
            postDetail?.type === "clinical-image" ? (
              <Carousel
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
              >
                {postDetail?.media.map((data, index) => (
                  <img
                    key={index}
                    src={_imagesURL + data}
                    alt="..."
                    style={{
                      borderRadius: "5px 0px 0px 5px",
                      height: "90vh",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                ))}
              </Carousel>
            ) : postDetail?.type === "video" ? (
              <video
                src={_imagesURL + postDetail?.media[0]}
                controls
                autoPlay
                alt="..."
                style={{
                  borderRadius: "5px 0px 0px 5px",
                  height: "90vh",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            ) : null}

          </div>
          <div style={{ width }}>
            <div className="d-flex gap-2 p-2 border-bottom border-inherit">
              <div
                className="bg-white rounded-circle align-self-center"
                style={{
                  padding: "2px",
                  width: "40px",
                  height: "40px",
                  border: "1px solid rgba(29, 193, 221, 1)",
                }}
              >
                <ImageComponent
                  src={postDetail?.user?.image ? _imagesURL + postDetail.user.image : Avatar}
                  img_height="100%"
                  img_width="100%"
                  compClassName="rounded-circle"
                />
              </div>
              <div className="align-self-center">
                <h1
                  className="fs-14 roboto-bold hover-blue mb-0 text-ellips-162px cursor"
                  onClick={() => {
                    postModalClose();
                    navigate(`/profile/${postDetail?.user?.id}`);
                  }}
                >
                  {postDetail?.user?.name}
                </h1>
              </div>
            </div>
            <div className="p-2 border-bottom border-inherit">
              {postDetail?.type === "webinar" ||
              postDetail?.type === "clinical-image" ? (
                <div className="p-2">
                  <h4>
                    {postDetail?.title && postDetail?.title.length > 26
                      ? postDetail?.title.slice(0, 26) + "..."
                      : postDetail?.title}
                  </h4>
                  <ReadMore text={postDetail?.caption} />
                  {postDetail?.type === "clinical-image" && (
                    <>
                      {postDetail?.pdf && (
                        <button
                          className="border-0 px-3 py-1 text-white btn-blue w-100 mt-3"
                          onClick={() => {
                            window.open(_imagesURL + postDetail?.pdf, "_blank");
                          }}
                        >
                          {lang?.view_Case_Study}
                        </button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <ReadMore text={postDetail?.caption} />
                </div>
              )}
            </div>
            <>
            {postDetail?.type !== "webinar" ? (
                <div>
                  <>
            {postDetail?.likes_count > 0 && (
              <div
                className="p-2 ps-4 border-bottom border-inherit d-flex cursor"
                onClick={() => setIsLikedUsersModal(true)}
              >
                {postDetail?.like_users_images &&
                  postDetail?.like_users_images.slice(0, 3).map((data, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-circle"
                      style={{
                        marginLeft: "-10px",
                        padding: "1px",
                        border: "1px solid rgba(29, 193, 221, 1)",
                      }}
                    >
                      <ImageComponent
                        src={data && data !== ".." ? _imagesURL + data : Avatar}
                        img_width={25}
                        img_height={25}
                        compClassName="rounded-circle"
                      />
                    </div>
                  ))}
                <p className="ps-2 fs-14 fs-md-12 roboto-medium mb-0 align-self-center text-ellips-162px hover-blue">
                  {postDetail?.likes_count > 3
                    ? `& ${postDetail?.likes_count - 3} ${lang?.other_have_liked}`
                    : `${lang?.have_liked}`}
                </p>
              </div>
            )}</></div>):
            (
                              <>
                                <Row className="px-3 my-2">
                                  <Col className="align-self-center">
                                    <p className="mb-0 text-warning">{lang?.scheduled_on}</p>
                                    <small className="mb-0">
                                      {postDetail?.scheduled}
                                      {", "}
                                      {convertTimestampToFormattedTime(postDetail?.start_timestamp)}
                                      {/* <ConvertToTime tstamp={postDetail?.start_timestamp} /> */}
                                    </small>
                                  </Col>
                                </Row>
                                <Row className="px-3 my-2">
                                  <Col className="align-self-center">
                                    {/* {postDetail?.status !== 0 && ( */}
                                      <button
                                        className="px-4 py-2 fw-bold text-white btn btn-warning w-100"
                                        onClick={
                                          postDetail?.user.id === splashData.user.id
                                            ? postDetail?.status === 0
                                              ? null
                                              : postDetail?.status === 2
                                              ? "Webinar Ended"
                                              : () => {
                                                  if (
                                                    postDetail?.user.id ===
                                                    splashData?.user?.id
                                                  ) {
                                                    window.open(
                                                      postDetail?.meeting_host_url,
                                                      "_blank"
                                                    );
                                                  } else {
                                                    if (postDetail?.isHost) {
                                                      window.open(
                                                        postDetail?.meeting_host_url,
                                                        "_blank"
                                                      );
                                                    } else {
                                                      window.open(
                                                        postDetail?.meeting_url,
                                                        "_blank"
                                                      );
                                                    }
                                                  }
                                                }
                                            : postDetail?.status === 0
                                            ? postDetail?.isReserve
                                              ? () => handleReserveWebinar()
                                              : () => handleReserveWebinar()
                                            : postDetail?.status === 1
                                            ? postDetail?.isReserve
                                              ? () => {
                                                  if (
                                                    postDetail?.user.id ===
                                                    splashData?.user?.id
                                                  ) {
                                                    window.open(
                                                      postDetail?.meeting_host_url,
                                                      "_blank"
                                                    );
                                                  } else {
                                                    if (postDetail?.meeting_url !== "") {
                                                      window.open(
                                                        postDetail?.meeting_url,
                                                        "_blank"
                                                      );
                                                    } else {
                                                      toast.info(lang.not_start_yet);
                                                    }
                                                  }
                                                }
                                              : () => handleReserveWebinar()
                                            : () => toast.info(lang.webinar_is_ended)
                                        }
                                      >
                                        {postDetail?.user.id === splashData?.user?.id
                                          ? postDetail?.status === 0
                                            ? lang.not_start_yet
                                            : postDetail?.status === 2
                                            ? lang.webinar_ended
                                            : lang.start_webinar
                                          : postDetail?.status === 0
                                          ? postDetail?.isReserve
                                            ? lang.unreserve
                                            : lang.reserve_seat
                                          : postDetail?.status === 1
                                          ? postDetail?.isReserve
                                            ? lang.join_webinar
                                            : lang.reserve_seat
                                          : lang.webinar_ended}
                                      </button>
                                    {/* )} */}
                                  </Col>
                                </Row>
                              </>
                            )}
            
            </>
            {postDetail?.type !== "webinar" ? (
                <div>
                  <>
            <div className="d-flex p-3 border-bottom border-inherit justify-content-between align-items-center">
              <div className="d-flex gap-lg-4 gap-md-3 gap-sm-2">
                {/* <div onClick={handleLike.mutate} className="d-flex gap-1 cursor">
                  <img width={18} height={18} src={postDetail?.isLike ? Liked : Like} alt="..." />
                  <p className="mb-0 fw-bold fs-14 fs-md-12">
                    {postDetail?.likes_count > 0 && postDetail?.likes_count}{" "}
                    {postDetail?.likes_count > 1 ? lang?.likes : lang?.like}
                  </p>
                </div> */}
   <div onClick={handleLike.mutate} className="d-flex align-items-center gap-1 cursor">
  <img width={18} height={18} src={postDetail?.isLike ? Liked : Like} alt="..." />
  <div className="d-flex align-items-center">
    
    {postDetail?.likes_count > 0 && (
      <span className="mb-0 fw-bold fs-14 fs-md-12">{postDetail?.likes_count}</span>
    )}
    <span className="mb-0 fw-bold fs-14 fs-md-12 ms-1">
      {postDetail?.likes_count > 1 ? lang?.likes : lang?.like}
    </span>
  </div>
</div>

                <div className="d-flex gap-1 cursor justify-content-center">
                  <img title="comment" width={18} height={18} src={Comment} alt="..." />
                  <p className="mb-0 fw-bold fs-14 fs-md-12">
                    {postDetail?.comments_count > 0 ? postDetail?.comments_count : ""}{" "}
                     <span onClick={()=>setCommentModal(true)} className=""> {lang?.comments}</span>
                  </p>
                </div>
                <div onClick={handleRepost.mutate} className="d-flex gap-1 cursor">
                  <img src={postDetail?.isShare ? ReshareActive : Reshare} width={18} height={18} alt="..." />
                  <p className={`mb-0 fw-bold fs-14 fs-md-12 ${postDetail?.isShare && "text-blue"}`}>
                    {postDetail?.isShare ? lang?.reposted : lang?.repost}
                  </p>
                </div>
                <div onClick={handleSave.mutate} className="d-flex gap-1 cursor">
                  <img
                    className="w-lg-23px"
                    title={postDetail?.isSave ? lang?.save_description : lang?.unsave_description}
                    src={postDetail?.isSave ? Saved : Save}
                    width={18}
                    height={18}
                    alt="..."
                  />
                  <p className={`mb-0 fw-bold fs-14 fs-md-12 ${postDetail?.isSave && "text-blue"}`}>
                    {postDetail?.isSave ? lang?.saved : lang?.save}
                  </p>
                </div>
              </div>
            </div>
            </></div>):""}
          </div>
          
        </div>
      </Modal>
      <UsersLikedPostModal
        isOpen={isLikedUsersModal}
        onClose={() => setIsLikedUsersModal(false)}
        postId={postDetail?.original_id}
      />
      {commentModal&& <CommentCanvas
        postModal={commentModal}        // Controls the visibility of the canvas
        setPostModal={setCommentModal}   // Function to toggle the modal’s visibility
        postId={postDetail?.id}
      />}
    </>
  ) : null;
}

export function UsersLikedPostModal({ isOpen, onClose, postId }) {
  const navigate = useNavigate();
  const { lang } = useContext(Language);

  const [page, setPage] = useState(1);

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["usersLikedPostApi", postId, page],
    () => usersLikedPostApi({ post_id: postId, page })
  );

  if (isError) {
    toast.error(error.message);
  }

  useEffect(() => {
    refetch();
  }, [postId, page]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>{lang?.liked_users}</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Col className="my-4 text-center">
            <Spinner />
          </Col>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => setPage(page + 1)}
            hasMore={data?.data?.current_page !== data?.data?.last_page}
            loader={
              <Col className="text-center">
                <Spinner />
              </Col>
            }
            useWindow={false}
          >
            {data?.data?.data.map((value, index) => (
              <div
                key={index}
                onClick={() => {
                  navigate(PROFILE + "/" + value.id);
                }}
                className="d-flex justify-content-between p-2 rounded text-dark menu-hover"
              >
                <div className="d-flex gap-2">
                  <div
                    className="bg-white dropdown-avatar-shadow rounded align-self-center"
                    style={{ padding: "1.8px" }}
                  >
                    <ImageComponent
                      src={value?.image ? _imagesURL + value.image : Avatar}
                      compClassName="img-fluid rounded"
                      img_width={60}
                      img_height={60}
                    />
                  </div>
                  <div className="align-self-center">
                    <div className="roboto-bold text-dark fs-17">
                      {value.name}
                    </div>
                    <p className="roboto-regular mb-0 fs-12">{value.user_name}</p>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </ModalBody>
    </Modal>
  );
}

// import { useCallback, useContext, useEffect, useState } from "react";
// import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
// import { Language, LoginUserData, PostDetailContext } from "../../App";
// import { _imagesURL, BaseURL } from "../../constants/API";
// import ImageComponent from "./ImageComponent";
// import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
// import { ConvertToTime, ReadMore } from "../../pages/Feed";
// import Like from "../../assets/images/icon/like.png";
// import Liked from "../../assets/images/icon/liked.png";
// import Reshare from "../../assets/images/icons/repost.svg";
// import ReshareActive from "../../assets/images/icons/repost-active.svg";
// import Save from "../../assets/images/icons/save.svg";
// import Saved from "../../assets/images/icons/saved.svg";
// import Comment from "../../assets/images/icon/comment.png";
// import { Carousel } from "react-responsive-carousel";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import {
//   likePostApi,
//   repostPostApi,
//   reserveWebinarApi,
//   savePostApi,
//   usersLikedPostApi,
// } from "../../services/feedApis";
// import { useMutation, useQuery } from "react-query";
// import InfiniteScroll from "react-infinite-scroller";
// import { PROFILE } from "../../constants/RoutesUrl";

// export default function PostDetailModal({ isOpen, onClose }) {
//   const navigate = useNavigate();
//   const { postDetail, updatePostDetail, postModalClose } =
//     useContext(PostDetailContext);
//   const { lang } = useContext(Language);
//   const { splashData } = useContext(LoginUserData);

//   const [isLikedUsersModal, setIsLikedUsersModal] = useState(false);
//   const [width, setWidth] = useState('35%');
//   const [hideText, setHideText] = useState(false);

//   useEffect(() => {
//     const handleResize = () => setHideText(window.innerWidth < 990);

//     // Initial check
//     handleResize();

//     // Add resize listener
//     window.addEventListener('resize', handleResize);

//     // Cleanup on component unmount
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth < 1200) {
//         setWidth('60%');
//       } else {
//         setWidth('35%');
//       }
//     };

//     // Initial check
//     handleResize();

//     // Add event listener
//     window.addEventListener('resize', handleResize);

//     // Clean up event listener on component unmount
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const handleReserveWebinar = useCallback(async () => {
//     try {
//       updatePostDetail({
//         ...postDetail,
//         isReserve: !postDetail?.isReserve,
//       });
//       await reserveWebinarApi({post_id:postDetail.original_id});
//     } catch (error) {
//       console.error(error);
//     }
//   }, [postDetail]);

//   const handleLike = useMutation({
//     mutationFn: () => likePostApi({post_id:postDetail.original_id}),
//     onError: (error) => {
//       toast.error(error.message);
//     },
//     onMutate: () => {
//       updatePostDetail({
//         ...postDetail,
//         isLike: !postDetail?.isLike,
//         likes_count: postDetail.isLike
//           ? postDetail?.likes_count - 1
//           : postDetail?.likes_count + 1,
//       });
//     },
//     onSuccess: (data, variables) => {
//       if (!data.status) {
//         toast.error(data.action);
//       }
//     },
//   });

//   const handleSave = useMutation( {
//     mutationFn: () => savePostApi({post_id:postDetail.original_id}),
//     onError: (error) => {
//       toast.error(error.message);
//     },
//     onMutate: () => {
//       updatePostDetail({
//         ...postDetail,
//         isSave: !postDetail?.isSave,
//       });
//     },
//     onSuccess: (data, variables) => {
//       if (!data.status) {
//         toast.error(data.action);
//       }
//     },
//   });

//   // const handleRepost = useMutation(repostPostApi, {
//   //   mutationFn: () => repostPostApi({post_id:postDetail.original_id}),
//   //   onError: (error) => {
//   //     toast.error(error.message);
//   //   },
//   //   onMutate: () => {
//   //     updatePostDetail({
//   //       ...postDetail,
//   //       isShare: !postDetail?.isShare,
//   //     });
//   //   },
//   //   onSuccess: (data, variables) => {
//   //     if (!data.status) {
//   //       toast.error(data.action);
//   //     }
//   //   },
//   // });
//   const handleRepost = useMutation(
//     () => repostPostApi({ post_id: postDetail.original_id }),
//     {
//       onError: (error) => {
//         toast.error(error.message);
//       },
//       onMutate: () => {
//         updatePostDetail({
//           ...postDetail,
//           isShare: !postDetail?.isShare,
//         });
//       },
//       onSuccess: (data) => {
//         if (!data.status) {
//           toast.error(data.action);
//         }
//       },
//     }
//   );
  
//   function convertTimestampToFormattedTime(timestamp) {
//     const date = new Date(timestamp * 1000); // Convert to milliseconds
    
//     const timeString = date.toLocaleTimeString('en-US', {
//       hour12: true,
//       hour: '2-digit',
//       minute: '2-digit'
//     });
  
//     const dateString = date.toLocaleDateString('en-US', {
//       weekday: 'long',
//       day: '2-digit',
//       year: 'numeric',
//       month: 'long'
//     });
  
//     return `${timeString} on ${dateString}`;
//   }
//   return postDetail?.original_id ? (
//     <>
//       <Modal
//         isOpen={isOpen}
//         toggle={onClose}
//         centered
//         size="xl"
//         className="rounded"
//       >
//         <div className="w-100 d-flex bg-white rounded">
//           <div
//             className="text-center"
//             style={{ backgroundColor: "#f5f5f5", width: "65%" }}
//           >
//             {postDetail?.type === "image" ||
//             postDetail?.type === "webinar" ||
//             postDetail?.type === "clinical-image" ? (
//               <Carousel
//                 showIndicators={false}
//                 showStatus={false}
//                 showThumbs={false}
//               >
//                 {postDetail?.media.map((data, index) => (
//                   <img
//                     key={index}
//                     src={_imagesURL + data}
//                     alt="..."
//                     style={{
//                       borderRadius: "5px 0px 0px 5px",
//                       height: "90vh",
//                       width: "100%",
//                       objectFit: "contain",
//                     }}
//                   />
//                 ))}
//               </Carousel>
//             ) : postDetail?.type === "video" ? (
//               <video
//                 src={_imagesURL + postDetail?.media[0]}
//                 controls
//                 autoPlay
//                 alt="..."
//                 style={{
//                   borderRadius: "5px 0px 0px 5px",
//                   height: "90vh",
//                   width: "100%",
//                   objectFit: "contain",
//                 }}
//               />
//             ) : null}
//           </div>
//           <div style={{ width }}>
//             <div className="d-flex gap-2 p-2 border-bottom border-inherit">
//               <div
//                 className="bg-white rounded-circle align-self-center"
//                 style={{
//                   padding: "2px",
//                   width: "40px",
//                   height: "40px",
//                   border: "1px solid rgba(29, 193, 221, 1)",
//                 }}
//               >
//                 <div>
//                   <ImageComponent
//                     src={
//                       postDetail?.user?.image
//                         ? _imagesURL + postDetail?.user?.image
//                         : Avatar
//                     }
//                     img_height="100%"
//                     img_width="100%"
//                     loader_height="100%"
//                     loader_width="100%"
//                     compClassName="rounded-circle"
//                     roundShimmerSize={40}
//                   />
//                 </div>
//               </div>
//               <div className="align-self-center">
//                 <div>
//                   <div>
//                     <h1
//                       className="fs-14 roboto-bold hover-blue mb-0 text-ellips-162px cursor"
//                       onClick={() => {
//                         postModalClose();
//                         navigate(`/profile/${postDetail?.user?.id}`);
//                       }}
//                     >
//                       {postDetail?.user?.name}
//                     </h1>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="p-2 border-bottom border-inherit">
//               {postDetail?.type === "webinar" ||
//               postDetail?.type === "clinical-image" ? (
//                 <div className="p-2">
//                   <h4>
//                     {postDetail?.title && postDetail?.title.length > 26
//                       ? postDetail?.title.slice(0, 26) + "..."
//                       : postDetail?.title}
//                   </h4>
//                   <ReadMore text={postDetail?.caption} />
//                   {postDetail?.type === "clinical-image" && (
//                     <>
//                       {postDetail?.pdf && (
//                         <button
//                           className="border-0 px-3 py-1 text-white btn-blue w-100 mt-3"
//                           onClick={() => {
//                             window.open(_imagesURL + postDetail?.pdf, "_blank");
//                           }}
//                         >
//                           {lang?.view_Case_Study}
//                         </button>
//                       )}
//                     </>
//                   )}
//                 </div>
//               ) : (
//                 <div>
//                   <ReadMore text={postDetail?.caption} />
//                 </div>
//               )}
//             </div>
//             <>
//               {postDetail?.type !== "webinar" ? (
//                 <div>
//                   <>
//                     {postDetail?.likes_count > 0 && (
//                       <div
//                         className="p-2 ps-4 border-bottom border-inherit d-flex cursor"
//                         onClick={() => setIsLikedUsersModal(true)}
//                       >
//                         {postDetail?.like_users_images &&
//                           postDetail?.like_users_images
//                             .slice(0, 3)
//                             .map((data) => (
//                               <div
//                                 className="bg-white rounded-circle"
//                                 style={{
//                                   marginLeft: "-10px",
//                                   padding: "1px",
//                                   border: "1px solid rgba(29, 193, 221, 1)",
//                                 }}
//                               >
//                                 <ImageComponent
//                                   src={
//                                     data && data !== ".."
//                                       ? _imagesURL + data
//                                       : Avatar
//                                   }
//                                   img_width={25}
//                                   img_height={25}
//                                   compClassName="rounded-circle"
//                                 />
//                               </div>
//                             ))}

//                         <p className="ps-2 fs-14 fs-md-12 roboto-medium mb-0 align-self-center text-ellips-162px hover-blue">
//                           {postDetail?.likes_count > 3
//                             ? `& ${postDetail?.likes_count - 3} ${
//                                 lang?.other_have_liked
//                               }`
//                             : `${lang?.have_liked}`}
//                         </p>
//                       </div>
//                     )}
//                   </>
//                   <div className="d-flex p-3 border-bottom border-inherit justify-content-between align-items-center">
//                     <div className="d-flex gap-lg-4 gap-md-3 gap-sm-2">
//                       <div
//                         onClick={handleLike.mutate}
//                         className="d-flex gap-1 cursor"
//                       >
//                         <div>
//                           <img
//                             width={18}
//                             height={18}
//                             src={postDetail?.isLike ? Liked : Like}
//                             alt="..."
//                           />
//                         </div>
//                         <div className="align-self-end hover-blue">
//                           <p className="mb-0 fw-bold fs-14 fs-md-12">
//                             {postDetail?.likes_count > 0 &&
//                               postDetail?.likes_count}{" "}
//                             {postDetail?.likes_count > 1
//                               ? lang?.likes
//                               : lang?.like}
//                           </p>
//                         </div>
//                       </div>
//                       <div className="d-flex gap-1 cursor justify-content-center">
//                         <div>
//                           <img title="comment" width={18} height={18} src={Comment} alt="..." />
//                         </div>
//                         <div className="align-self-end hover-blue">
//                           <p className="mb-0 fw-bold fs-14 fs-md-12">
//                             {postDetail?.comments_count > 0
//                               ? postDetail?.comments_count
//                               : ""}{" "}
//                            <span  className="comment-text"> {lang?.comments}</span>
//                           </p>
//                         </div>
//                       </div>
//                       <div
//                         className="d-flex gap-1 cursor"
//                         onClick={handleRepost.mutate}
//                       >
//                         <div>
//                           <img
//                             src={postDetail?.isShare ? ReshareActive : Reshare}
//                             width={18}
//                             height={18}
//                             alt="..."
//                           />
//                         </div>
//                         <div className="align-self-end hover-blue">
//                           <p
//                             className={`mb-0 fw-bold fs-14 fs-md-12 ${
//                               postDetail?.isShare && "text-blue"
//                             }`}
//                           >
//                             {postDetail?.isShare
//                               ? lang?.reposted
//                               : lang?.repost}
//                           </p>
//                         </div>
//                       </div>
//                       <div
//                         className="d-flex gap-1 cursor"
//                         onClick={handleSave.mutate}
//                       >
//                         <div>
//                           <img
//                             className="w-lg-23px"
//                             title={
//                               postDetail?.isSave
//                                 ? lang?.save_description
//                                 : lang?.unsave_description
//                             }
//                             src={postDetail?.isSave ? Saved : Save}
//                             width={18}
//                             height={18}
//                             alt="..."
//                           />
//                         </div>
//                         <div className="align-self-end hover-blue">
//                           <p
//                             className={`mb-0 fw-bold fs-14 fs-md-12 ${
//                               postDetail?.isSave && "text-blue"
//                             }`}
//                           >
//                             {postDetail?.isSave ? lang?.saved : lang?.save}
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ) : (
//                 <>
//                   <Row className="px-3 my-2">
//                     <Col className="align-self-center">
//                       <p className="mb-0 text-warning">{lang?.scheduled_on}</p>
//                       <small className="mb-0">
//                         {postDetail?.scheduled}
//                         {", "}
//                         {convertTimestampToFormattedTime(postDetail?.start_timestamp)}
//                         {/* <ConvertToTime tstamp={postDetail?.start_timestamp} /> */}
//                       </small>
//                     </Col>
//                   </Row>
//                   <Row className="px-3 my-2">
//                     <Col className="align-self-center">
//                       {/* {postDetail?.status !== 0 && ( */}
//                         <button
//                           className="px-4 py-2 fw-bold text-white btn btn-warning w-100"
//                           onClick={
//                             postDetail?.user.id === splashData.user.id
//                               ? postDetail?.status === 0
//                                 ? null
//                                 : postDetail?.status === 2
//                                 ? "Webinar Ended"
//                                 : () => {
//                                     if (
//                                       postDetail?.user.id ===
//                                       splashData?.user?.id
//                                     ) {
//                                       window.open(
//                                         postDetail?.meeting_host_url,
//                                         "_blank"
//                                       );
//                                     } else {
//                                       if (postDetail?.isHost) {
//                                         window.open(
//                                           postDetail?.meeting_host_url,
//                                           "_blank"
//                                         );
//                                       } else {
//                                         window.open(
//                                           postDetail?.meeting_url,
//                                           "_blank"
//                                         );
//                                       }
//                                     }
//                                   }
//                               : postDetail?.status === 0
//                               ? postDetail?.isReserve
//                                 ? () => handleReserveWebinar()
//                                 : () => handleReserveWebinar()
//                               : postDetail?.status === 1
//                               ? postDetail?.isReserve
//                                 ? () => {
//                                     if (
//                                       postDetail?.user.id ===
//                                       splashData?.user?.id
//                                     ) {
//                                       window.open(
//                                         postDetail?.meeting_host_url,
//                                         "_blank"
//                                       );
//                                     } else {
//                                       if (postDetail?.meeting_url !== "") {
//                                         window.open(
//                                           postDetail?.meeting_url,
//                                           "_blank"
//                                         );
//                                       } else {
//                                         toast.info("Not started yet.");
//                                       }
//                                     }
//                                   }
//                                 : () => handleReserveWebinar()
//                               : () => toast.info("Webinar is Ended.")
//                           }
//                         >
//                           {postDetail?.user.id === splashData?.user?.id
//                             ? postDetail?.status === 0
//                               ? "Not Started Yet"
//                               : postDetail?.status === 2
//                               ? "Webinar Ended"
//                               : "Start Webinar"
//                             : postDetail?.status === 0
//                             ? postDetail?.isReserve
//                               ? "Unreserve"
//                               : "Reserve Seat"
//                             : postDetail?.status === 1
//                             ? postDetail?.isReserve
//                               ? "Join Webinar"
//                               : "Reserve Seat"
//                             : "Webinar Ended"}
//                         </button>
//                       {/* )} */}
//                     </Col>
//                   </Row>
//                 </>
//               )}
//             </>
//           </div>
//         </div>
//       </Modal>
//       <UsersLikedPostModal
//         isOpen={isLikedUsersModal}
//         onClose={() => setIsLikedUsersModal(false)}
//         postId={postDetail?.original_id}
//       />
//     </>
//   ) : null;
// }

// export function UsersLikedPostModal({ isOpen, onClose, postId }) {
//   const navigate = useNavigate();
//   const { lang } = useContext(Language);

//   const [page, setPage] = useState(1);

//   const { data, isLoading, isError, error, refetch } = useQuery(
//     "usersLikedPostApi",
//     {
//       queryFn: () => usersLikedPostApi({post_id:postId, page}),
//     }
//   );

//   if (isError) {
//     toast.error(error.message);
//   }

//   useEffect(() => {
//     refetch();
//   }, [postId, page]);

//   return (
//     <Modal isOpen={isOpen} toggle={onClose} centered>
//       <ModalHeader toggle={onClose}>{lang?.liked_users}</ModalHeader>
//       <ModalBody>
//         {isLoading ? (
//           <Col className="my-4 text-center">
//             <Spinner />
//           </Col>
//         ) : (
//           <div>
//             <InfiniteScroll
//               pageStart={0}
//               loadMore={() => setPage(page + 1)}
//               hasMore={
//                 data?.data?.current_page !== data?.data?.last_page ||
//                 data?.data?.current_page < data?.data?.last_page
//               }
//               loader={
//                 <Col className="text-center">
//                   <Spinner />
//                 </Col>
//               }
//               useWindow={false}
//             >
//               {data?.data?.data &&
//                 data?.data?.data?.map((value, index) => (
//                   <>
//                     <div
//                       onClick={() => {
//                         navigate(PROFILE + "/" + value.id);
//                       }}
//                       className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
//                       key={index}
//                     >
//                       <div className="d-flex gap-2">
//                         <div
//                           className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
//                           style={{
//                             padding: "1.8px",
//                           }}
//                         >
//                           <ImageComponent
//                             src={value?.image ? _imagesURL + value.image : Avatar}
//                             compClassName="img-fluid rounded"
//                             loader_height="100%"
//                             loader_width="100%"
//                           />
//                         </div>
//                         <div className="align-self-center">
//                           <div className="roboto-bold text-dark fs-17 search-post-name">
//                             {value.name}
//                           </div>
//                           <p className="roboto-regular mb-0 fs-12 search-post-name">
//                             {value.user_name}
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                     {index !== 3 && (
//                       <hr className="m-0" style={{ opacity: "6%" }} />
//                     )}
//                   </>
//                 ))}
//             </InfiniteScroll>
//           </div>
//         )}
//       </ModalBody>
//     </Modal>
//   );
// }

