import React, { createContext, useContext, useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { Col, Container, Row } from "reactstrap";
import ChatsSideBar from "../../components/chat/ChatsSideBar";
import Chats from "../../components/chat/Chats";
import { ChatsContext, ChatsProvider } from "../../components/chat/ChatsProvider";
import { ShimmerBadge } from "react-shimmer-effects";
import ChatStartHeading from "./ChatStartHeading";

export const selectedChatContext = createContext();

function ChatModule() {
  const [selectedChat, setSelectedChat] = useState(null);


  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);
  return (
    <selectedChatContext.Provider value={{ selectedChat, setSelectedChat }}>
      <ChatsProvider>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row className="d-flex bg-white" style={{ marginTop: "3.3rem" }}>
          <Col className="m-0 p-0" style={{ maxWidth: "397px" }}>
            <ChatsSideBar />
          </Col>
          {/* <Col className="m-0 p-0">{selectedChat ? <Chats />:"Select a conversation"}</Col> */}
          <Col className="m-0 p-0">
    {selectedChat ? (
      <Chats />
    ) : (
    <ChatStartHeading/>
    )}
  </Col>
        </Row>
      </Container>
      </ChatsProvider>
    </selectedChatContext.Provider>
  );
}

export default ChatModule;
