import React from "react";
import { FaCheck } from "react-icons/fa6";
const AboutCourse = () => {
  return (
    <div className="container py-4">
      <div className="mb-3">
        <h6 className="text-primary fw-bold">About:</h6>
        <h5 className="fw-bold">What you will learn?</h5>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <ul className="list-unstyled">
            <li className="d-flex align-items-start mb-2">
                <FaCheck size={50} className=" me-2" />
              {/* <i className="bi bi-check-circle-fill "></i> */}
              <span>
                Scelerisque taciti integer vel quis mi ridiculus sollicitudin
                praesent bibendum. Torquent nisi pharetra penatibus ut lectus
                commodo amet porta enim.
              </span>
            </li>
            <li className="d-flex align-items-start mb-2">
            <FaCheck size={30} className=" me-2" />
              <span>Understand the importance of cybersecurity practices and their impact for organizations.</span>
            </li>
          </ul>
        </div>
        <div className="col-md-6 mb-3">
          <ul className="list-unstyled">
            <li className="d-flex align-items-start mb-2">
            <FaCheck size={30} className=" me-2" />
              <span>
                Scelerisque taciti integer vel quis mi ridiculus sollicitudin
                praesent bibendum.
              </span>
            </li>
            <li className="d-flex align-items-start mb-2">
            <FaCheck size={30} className=" me-2" />
              <span>Identify common risks, threats, and vulnerabilities, as well as techniques to mitigate them.</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-4">
        <h5 className="fw-bold">What skills you will gain?</h5>
        <p className="text-muted">
          Get on the fast track to a career in cybersecurity. In this
          certificate program, you’ll learn in-demand skills, and get AI
          training from Google experts. Learn at your own pace, no degree or
          experience required. Additionally, when you enroll today, you’ll get
          access to Google AI Essentials at no cost. Offer ends 11/30*!
        </p>
      </div>
    </div>
  );
};

export default AboutCourse;
