import { ShimmerText } from "react-shimmer-effects";
import { TimestampConverter } from "../../pages/Feed";
import SearchResultCard from "./SearchResultCard";

export default function JobsResultComp({ data, loading, keyword }) {
  return (
    <SearchResultCard
      title="Jobs"
      link={`/search/jobs?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      {loading ? (
        [1, 2, 3].map((item, index) => (
          <div className="p-2" key={index}>
            <ShimmerText line={5} gap={10} />
          </div>
        ))
      ) : data && data?.length > 0 ? (
        data?.slice(0, 3).flatMap((item, index) => (
          <div className="p-2" key={index}>
            <JobCard job={item} />
          </div>
        ))
      ) : data && data.length === 0 ? (
        <div className="text-center py-2">
          <p className="fs-14 mb-0 roboto-medium text-muted">
            No results found
          </p>
        </div>
      ) : null}
    </SearchResultCard>
  );
}

export const JobCard = ({ job }) => {
  return (
    <div className="p-3">
      <h5 className="roboto-bold fs-14">{job?.title}</h5>
      <div className="d-flex justify-content-between">
        <h6 className="roboto-bold fs-12" style={{ color: "#007AFF" }}>
          {job?.short_location}
        </h6>
        <p className="roboto-regular fs-12 text-muted mb-0">
          <TimestampConverter timestamp={job?.time} />
        </p>
      </div>
      <p className="roboto-medium fs-14 mb-0">
        {job?.description?.length > 150
          ? `${job?.description?.slice(0, 150)}...`
          : job?.description}
      </p>
    </div>
  );
};
