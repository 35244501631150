import { useContext, useState } from "react";
import { Language } from "../../App";



export default function SearchSidebarFilter() {
  const { lang } = useContext(Language);
  const list = [
    { id: 1, title: lang.doctors },
    { id: 2, title: lang.posts },
    { id: 3, title: "Webinars" },
    { id: 4, title: lang.clinical_studies },
    { id: 5, title: lang.courses },
    { id: 6, title: lang.events },
    // { id: 7, title: "Jobs" },
  ];
  const [active, setActive] = useState(1);
  return (
    <div
      className="bg-white border rounded"
      style={{
        marginTop: "1rem",
        width: "335px",
        height: "363px",
      }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">{lang.see_results_for}</h5>
      </div>
      <div className="py-3">
        {list.map((item) => (
          <p
            className="roboto-bold fs-16 ps-3 cursor"
            onClick={() => {
              setActive(item.id);
              window.location.hash = `#${item.title.toLowerCase()}`;
            }}
            style={{
              borderRight:
                active === item.id ? "3px solid rgba(29, 193, 221, 1)" : "none",
              color: active === item.id ? "rgba(29, 193, 221, 1)" : "black",
            }}
            key={item.id}
          >
            {item.title}
          </p>
        ))}
      </div>
    </div>
  );
}
