import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import { _imagesURL, BaseURL } from "../../constants/API";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { UnixToRelative } from "../notification/Notification";
import { Language } from "../../App";

const SearchedPostHeader = ({ postDetails }) => {
  const {lang} = useContext(Language)
  return (
    <div className="d-flex justify-content-between align-items-center position-relative p-2 border-bottom border-inherit">
      <div className="d-flex gap-2">
        <div
          className="bg-white rounded-circle align-self-center"
          style={{
            padding: "2px",
            width: "50px",
            height: "50px",
            border: "1px solid rgba(29, 193, 221, 1)",
          }}
        >
          <Link to={PROFILE + "/" + postDetails?.user?.id}>
            <ImageComponent
              src={
                postDetails?.user?.image
                  ? _imagesURL + postDetails?.user?.image
                  : Avatar
              }
              img_height="100%"
              img_width="100%"
              loader_height="100%"
              loader_width="100%"
              compClassName="rounded-circle"
              roundShimmerSize={45}
            />
          </Link>
        </div>
        <div className="align-self-center">
          <div>
            <Link to={PROFILE + "/" + postDetails?.user?.id}>
              <h1 className="fs-16 roboto-bold hover-blue mb-0 text-ellips-162px">
                {postDetails?.user?.name || "Unknown User"}
              </h1>
            </Link>
            <p
              className="mb-0 fs-12 roboto-regular"
              style={{
                color: "#434343",
                opacity: "80%",
              }}
            >
              {lang.posted} <UnixToRelative unixTimestamp={postDetails?.time} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchedPostHeader;
