import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { GOOGLE_KEY } from "../../../constants/Keys";
import { useCallback, useContext, useEffect } from "react";
import { Language,  } from "../../../App";

const mapContainerStyle = {
    width: "100%",
    height: "50vh",
  };
  
  
  
  const LocationModal = ({
    isOpen,
    toggle,
    onSave, // Callback function to pass back the selected lat/lng
    formData, // Optional existing form data to prefill lat/lng
    mapCenter,
    setMapCenter,
    markerPosition,
    setMarkerPosition,
    setCity,
    setCountry
  }) => {
    // Load the Google Maps script
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: GOOGLE_KEY,
    });
    const { lang } = useContext(Language);
  
  
  
  
    // Initialize marker position if formData has existing lat_lng values
    useEffect(() => {
      if (formData?.lat_lng) {
        setMarkerPosition(formData.lat_lng);
        setMapCenter(formData.lat_lng); // Set the map center based on the existing position
      }
    }, [formData]);
  
    // Handle the map click to place a marker
    const handleMapClick = useCallback((event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setMarkerPosition({
        lat: lat,
        lng: lng,
      });
      reverseGeocode(lat, lng);
    }, []);
  
    // Handle marker dragging to update position
    const handleMarkerDragEnd = useCallback((event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setMarkerPosition({ lat, lng });
      reverseGeocode(lat, lng);
    }, []);
  
    // Reverse Geocoding (optional) to fetch address based on lat/lng
    const reverseGeocode = (lat, lng) => {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(lat, lng);
  
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          const addressComponents = results[0].address_components;
          let cityName = "";
          let countryName = "";
  
          for (const component of addressComponents) {
            if (component.types.includes("locality")) {
              cityName = component.long_name;
            } else if (component.types.includes("country")) {
              countryName = component.long_name;
            }
          }
  
          setCity(cityName);
          setCountry(countryName);
        } else {
          setCity("");
          setCountry("");
        }
      });
    };
  
    // Save the location and perform reverse geocoding on save
    const handleSave = () => {
      if (markerPosition) {
        onSave(markerPosition); // Pass marker position back to parent via callback
        const { lat, lng } = markerPosition;
        reverseGeocode(lat, lng);
      }
      toggle(); // Close the modal
    };
  
    if (loadError) return <div>{lang?.error_loading_maps || "Error loading maps"}</div>;
    if (!isLoaded) return <div>{lang?.loading_maps || "Loading Maps..."}</div>;
  
    return (
      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader toggle={toggle}></ModalHeader>
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={toggle}
          >
            {lang?.cancel || "Cancel"}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={handleSave}
          >
            {lang?.save || "Save"}
          </button>
        </ModalFooter>
      </Modal>
    );
  };
export default LocationModal