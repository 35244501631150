import { useContext, useState, useLayoutEffect } from "react";
import { MdFilterListAlt } from "react-icons/md";
import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Language } from "../../App";
import { useNavigate, useParams } from "react-router-dom";

export default function EventTabs({ activeTab, setActiveTab,joinedEventId }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [filterModal, setFilterModal] = useState(false);
const {lang} = useContext(Language)
const tabsArray = [
  {
    id: 1,
    title: lang.see_all,
    value: "all",
  },
  {
    id: 2,
    title: lang.my_events,
    value: "my-events",
  },
  {
    id: 3,
    title: lang.joined_events,
    value: "joined-events",
  },
  {
    id: 4,
    title: lang.saved_events,
    value: "saved-events",
  },
];
  useLayoutEffect(() => {
    if (id === "1") {
      setActiveTab("all");
    } else if (id === "2") {
      setActiveTab("my-events");
    } else if (id === "3") {
      setActiveTab("joined-events");
    } else if (id === "4") {
      setActiveTab("saved-events");
    }
  }, [id]);
// useLayoutEffect(()=>{
// if(joinedEventId==="2"){
//   setActiveTab("joined-events");
// }
// },[joinedEventId])
  return (
    <>
      <div className="w-100 d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-center gap-3">
          {tabsArray.map((tab) => (
            <button
              className={`btn btn-sm bg-white fw-bold roboto-medium shadow-sm rounded text-gray ${
                activeTab === tab.value && "text-dark"
              }`}
              onClick={() => {
                setActiveTab(tab.value);
                navigate(`/events/${tab.id}`);
              }}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div>
          <button
            className="btn btn-sm bg-white shadow-sm rounded"
            onClick={() => setFilterModal(true)}
          >
            <MdFilterListAlt size={18} />
          </button>
        </div>
      </div>
      <FilterModal filterModal={filterModal} setFilterModal={setFilterModal} />
    </>
  );
}



export const FilterModal = ({ filterModal, setFilterModal }) => {
  const { lang } = useContext(Language);
  const [filterData, setFilterData] = useState({
    location: "",
    distance: 25.0,
  });
  return (
    <Modal isOpen={filterModal} centered>
      <ModalHeader>{lang?.filters}</ModalHeader>
      <ModalBody>
        <div>
          <Row className="mb-3">
            <Col>
              <div className="d-flex justify-content-between">
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.location}
                </label>
                <small
                  className="fw-bold text-blue cursor"
                  onClick={() => {
                    setFilterData({ location: "", distance: 25.0 });
                    // setLatLng("");
                    // setCity("");
                    // setCountry("");
                  }}
                >
                  {lang?.reset}
                </small>
              </div>
              <div className="position-relative">
                <input
                  //   onClick={handleLocation}
                  type="text"
                  className="py-2 form-control"
                  placeholder="City, Country"
                  //   value={latLng && city && country ? `${city}, ${country}` : ""}
                />
                {/* {city !== "" && country !== "" && (
                  <div
                    className="position-absolute end-0 top-0 me-2"
                    style={{ marginTop: "13px" }}
                  >
                    <h6
                      className="text-primary cursor"
                      onClick={() => {
                        setLatLng("");
                        setCity("");
                        setCountry("");
                      }}
                    >
                      <TiCancel size={18} />
                      {lang?.remove}
                    </h6>
                  </div>
                )} */}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div className="d-flex justify-content-between">
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.distance}
                </label>
                <label className="pb-1 fs-15 roboto-medium">
                  {filterData.distance} {lang?.km}
                </label>
              </div>
              <Input
                type="range"
                value={filterData.distance}
                onChange={(e) => {
                  setFilterData({ ...filterData, distance: e.target.value });
                }}
              />
              <div className="d-flex justify-content-between">
                <label className="pb-1 fs-15 roboto-medium">0 {lang?.km}</label>
                <label className="pb-1 fs-15 roboto-medium">
                  100 {lang?.km}
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="justify-content-end gap-3 d-flex mt-3 w-100">
          <button
            className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
            onClick={() => setFilterModal(false)}
          >
            {lang?.cancel}
          </button>
          <button
            className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
            onClick={() => {
              setFilterModal(false);
              //   handleEvents();
            }}
          >
            {lang?.apply_filter}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
