import React from 'react';
import { Container, Row } from 'reactstrap';
import './DebitCreditAmounts.css';

const DebitCreditAmounts = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center mt-2">
        <div className="content-wrapper">
          {/* <h5 className="section-heading">Debit & Credit Amounts:</h5> */}
          <div className="card-container" style={{padding: "20px"}}>
            <div className="header-section">
              <h6 className="debit-title" style={{ color: "#ff3b30" }}>Amount Debit</h6>
              <p className="debit-time">10:30 pm - 12 Dec 2024</p>
            </div>
            <div className="details-section mt-3">
              <p className="label">Withdraw amount</p>
              <h4 className="amount text-end">€10,000.00</h4>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default DebitCreditAmounts;

