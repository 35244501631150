import { useContext } from "react";
import { Language } from "../../App";

export default function SearchHashtags({ data }) {
  const {lang} = useContext(Language)
  return (
    <div>
      <div className="bg-white rounded mt-3 border" style={{ width: "335px" }}>
        <div
          className="p-3"
          style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
        >
          <h5 className="mb-0 fs-16 roboto-bold">{lang.hashtags}</h5>
        </div>
        <div className="d-flex flex-wrap p-3 gap-2">
          {data?.flatMap((item, index) => (
            <div
              key={index}
              className="rounded-pill px-3 d-flex align-items-center gap-2 cursor"
              style={{
                backgroundColor: "#E8F9FC",
                border: "1px solid rgba(29, 193, 221, 0.2)",
              }}
            >
              <p className="mb-0 fs-16 roboto-regular">#{item?.tag}</p>
              {item?.count > 0 && (
                <p className="rounded-circle bg-white fs-12 roboto-bold px-1 mb-0">
                  {item?.count}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
