
import React, { useState } from 'react'
import NavBar from '../NavBar'
import { Container, Row } from 'reactstrap'
import CoursePurchaseCard from './CoursePurchaseCard'
import AboutCourse from './AboutCourse'
import CourseChapters from './CourseChapters'
import ic_certificate from "../../assets/images/icon/ic_certificate.svg"
import ic_chart from "../../assets/images/icon/ic_chart.svg"
import ic_video from "../../assets/images/icon/ic_video.svg"
import ic_course from "../../assets/images/icon/ic_course.svg"
import EnrollPopup from './EnrollPopup'
const NewCourseDetail = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen); // Toggle the popup visibility
  };
  return (
    <>
    <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}> {/* White background for the whole page */}
      <Container fluid className="p-0">
        <Row style={{ backgroundColor: "#f8f9fa" }}> {/* Light background for the header */}
          <NavBar />
        </Row>
        <Row>
          <div className="container mb-5" style={{ backgroundColor: "#f0f7fa" }}>
            {/* Main Course Card Section */}
            <div className="card border-0  p-3 mb-4" style={{ backgroundColor: "#f0f7fa" }} >
              <div className="row g-0">
                {/* Course Information Section */}
                <div className="col-md-8">
                  <div className="card-body" style={{ marginTop: "65px", paddingLeft: "73px", paddingRight: "73px", paddingTop: "73px" }}>
                    <h1 className="card-title fw-bold">
                      The Theory Behind Electronics<br /> – A Beginners Guide
                    </h1>
                    <p className="text-primary mt-3 mb-1">Ophthalmology - Uveitis</p>
                    <p className="card-text mt-3 text-muted">
                      Created By:
                    </p>
                    <div className="d-flex mt-3 align-items-center">
                      <img
                        src="https://via.placeholder.com/40"
                        alt="University Logo"
                        className="me-2 rounded-circle"
                        style={{ width: "40px", height: "40px", objectFit: "cover" }}
                      />
                      <span>University of California</span>
                    </div>

                    <div className='d-flex mt-5 gap-2'>
                      <button onClick={togglePopup} style={{backgroundColor:"#031316",color:"white"}} className="btn fw-bold  px-5">Enroll Now - Free</button>
                      <button className="btn fw-bold p-3" style={{ backgroundColor: "white", color: "black" }}>Save Course</button>
                      <button className="btn fw-bold p-3" style={{ backgroundColor: "#ff3b30", color: "white" }}>Report Course</button>
                    </div>
                  </div>
                </div>
                {/* Image Section */}
                <div className="col-md-4  d-flex align-items-center" style={{
                  paddingTop: "75px",
                  paddingLeft: "75px",
                  paddingRight: "75px",
                  marginTop: "56px"
                }}>
                  <img src="https://medicalradar.es//uploads/user/11/courses/11-cover-6710f32ea2105.jpeg" className="img-fluid rounded" alt="Course Cover" />
                </div>
              </div>
            </div>
            {/* Features Section */}
            <div
              className="row text-center bg-white shadow p-3 rounded"
              style={{
                borderRadius: '8px', marginBottom: "-50px",
                marginLeft: "90px",
                marginRight: "90px",
                marginTop: "30px"
              }} // Custom inline style for border radius
            >

              <div className="col-md-3 d-flex justify-content-center align-items-center">
              <span><img src={ic_course} alt="" /></span>
                <div className="p-3">
                  <h6 className="fw-bold"> 17 Course Chapters</h6>
                  <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center align-items-center">
              <span><img src={ic_video} alt="" /></span>
                <div className="p-3">
                  <h6 className="fw-bold">37 Hours & 38 Minutes</h6>
                  <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center align-items-center">
              <span><img src={ic_chart} alt="" /></span>
                <div className="p-3">
                  <h6 className="fw-bold">Beginner Level</h6>
                  <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center align-items-center">
              <span><img src={ic_certificate} alt="" /></span>
                <div className="p-3">
                  <h6 className="fw-bold">Earn Certificates</h6>
                  <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
                </div>
              </div>
            </div>

          </div>
        </Row>
      </Container>
      {/* about section */}
      <div className="mt-5 d-flex" style={{
        paddingRight: "74px",
        paddingLeft: "74px",
        paddingBottom: "74px"
      }}>
        <div className="row w-100">
          <div className="col-md-9"> {/* Approximately 60% */}
            <AboutCourse />
          </div>
          <div className="col-md-3"> {/* Approximately 40% */}
            <CoursePurchaseCard />
          </div>
        </div>
      </div>
      <CourseChapters />
      <div className="mt-5" style={{
        paddingRight: "74px",
        paddingLeft: "74px",
        paddingBottom: "74px",
        marginLeft: "16px"
      }}>
      <div class="d-flex justify-content-between align-items-center " style={{
    marginBottom: "20px"}}>
        <h4 class="mb-0 fs-20 roboto-bold text-primary">Other Recommended Courses</h4>
        </div>
        <div className='d-flex'>
        <div class="d-flex flex-wrap gap-3 justify-content-between" style={{
    marginBottom: "20px"}}>
        <div class="bg-white p-1 br-8 cursor" style={{width: "280px"}}>
          <img src=" https://djoejj0gd5srt.cloudfront.net/uploads/user/11/courses/11-cover-672c7a22c9a1e.jpeg" class="brt-7 object-cover d-block" alt="..." width="100%" height="303px"/>
          <div class="my-2"><h5 class="fs-12 roboto-bold mb-1">4ª SESIÓN COMENTA TU CASO COMITÉ RETINA ...</h5>
          <div class="d-flex align-items-center justify-content-between"><p class="fs-12 roboto-medium mb-0" style={{color: "rgb(255, 149, 0)"}}>Free</p>
        <p class="fs-12 roboto-medium text-gray mb-0 line-through"><s>€165.00</s></p></div></div></div>
        </div>
        <div class="d-flex flex-wrap gap-3 justify-content-between" style={{marginLeft: "116px",
    marginBottom: "20px"}}>
        <div class="bg-white p-1 br-8 cursor" style={{width: "280px"}}>
          <img src=" https://djoejj0gd5srt.cloudfront.net/uploads/user/11/courses/11-cover-672c7a22c9a1e.jpeg" class="brt-7 object-cover d-block" alt="..." width="100%" height="303px"/>
          <div class="my-2"><h5 class="fs-12 roboto-bold mb-1">4ª SESIÓN COMENTA TU CASO COMITÉ RETINA ...</h5>
          <div class="d-flex align-items-center justify-content-between"><p class="fs-12 roboto-medium mb-0" style={{color: "rgb(255, 149, 0)"}}>Free</p>
        <p class="fs-12 roboto-medium text-gray mb-0 line-through"><s>€165.00</s></p></div></div></div>
        </div>
        <div class="d-flex flex-wrap gap-3 justify-content-between" style={{marginLeft: "116px",
    marginBottom: "20px"}}>
        <div class="bg-white p-1 br-8 cursor" style={{width: "280px"}}>
          <img src=" https://djoejj0gd5srt.cloudfront.net/uploads/user/11/courses/11-cover-672c7a22c9a1e.jpeg" class="brt-7 object-cover d-block" alt="..." width="100%" height="303px"/>
          <div class="my-2"><h5 class="fs-12 roboto-bold mb-1">4ª SESIÓN COMENTA TU CASO COMITÉ RETINA ...</h5>
          <div class="d-flex align-items-center justify-content-between"><p class="fs-12 roboto-medium mb-0" style={{color: "rgb(255, 149, 0)"}}>Free</p>
        <p class="fs-12 roboto-medium text-gray mb-0 line-through"><s>€165.00</s></p></div></div></div>
        </div>
        </div>
    </div>
    </div>
    {isPopupOpen && <EnrollPopup isOpen={isPopupOpen} toggle={togglePopup} />}
    </>
  )
}

export default NewCourseDetail
