import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import SearchResultCard from "./SearchResultCard";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { _imagesURL, BaseURL } from "../../constants/API";
import { followUserApi } from "../../services/feedApis";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { ShimmerCircularImage, ShimmerTitle } from "react-shimmer-effects";
import { Language } from "../../App";

export default function DoctorsResultsComp({ data, keyword, loading }) {
  const [searchData, setSearchData] = useState(data);
  const { lang } = useContext(Language);

  const handleFollow = async (id) => {
    const isFollowed = searchData?.find((item) => item?.id === id)?.follow;
    try {
      const response = await followUserApi(id);
      if (response.status) {
        toast.success(response.message);
        const updatedData = searchData?.map((item) => {
          if (item?.id === id) {
            return { ...item, follow: !isFollowed };
          }
          return item;
        });
        setSearchData(updatedData);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    setSearchData(data);
  }, [data]);

  console.log(searchData)

  return (
    <SearchResultCard
      seeAll={!loading && searchData?.length > 2}
      title={lang.doctors}
      link={`/search/doctors?keyword=${keyword}`}
    >
      <div className="p-2">
        {loading ? (
          <>
            {[1, 2, 3].map((_, index) => (
              <div
                className="d-flex px-2 pb-4 justify-content-between manue-hover rounded"
                key={index}
              >
                <div className="d-flex gap-4 text-dark w-100">
                  <div
                    className="align-self-center"
                    style={{
                      width: "3rem",
                      height: "3rem",
                    }}
                  >
                    <ShimmerCircularImage size={60} />
                  </div>
                  <div className="align-self-center">
                    <p className="fs-14 roboto-bold suggest_name m-0">
                      <ShimmerTitle line={1} variant="secondary" />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : searchData && searchData?.length > 0 ? (
          <>
            {searchData?.slice(0, 3).flatMap((item, index) => (
              <div
                className="d-flex mb-3 justify-content-between manue-hover rounded"
                key={index}
              >
                <Link
                  to={`${PROFILE}/${item?.id}`}
                  className="d-flex gap-3 text-dark w-100"
                >
                  <div
                    className="bg-white rounded-circle align-self-center"
                    style={{
                      padding: "2px",
                      width: "66px",
                      height: "66px",
                      border: "1px solid rgba(29, 193, 221, 1)",
                    }}
                  >
                    <ImageComponent
                      src={
                        item?.image !== "" ? _imagesURL + item?.image : Avatar
                      }
                      img_height="100%"
                      img_width="100%"
                      loader_height="100%"
                      loader_width="100%"
                      compClassName="rounded-circle"
                      roundShimmerSize={60}
                    />
                  </div>
                  <div className="align-self-center">
                    <p className="fs-16 roboto-medium m-0">{item?.name}</p>
                    <p className="fs-12 text-muted roboto-medium m-0">
                      {item?.user_name}
                    </p>
                  </div>
                </Link>
                <div className="align-self-center">
                  <button
                    className="text-white rounded border-0 px-3 py-1 follow-btn"
                    onClick={() => handleFollow(item?.id)}
                  >
                    <span className="roboto-medium fs-14">
                      {item?.follow ? lang.following : lang.follow}
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : searchData && searchData?.length === 0 ? (
          <div className="text-center py-2">
            <p className="fs-14 mb-0 roboto-medium text-muted">
              {lang.no_results_found}
            </p>
          </div>
        ) : null}
      </div>
    </SearchResultCard>
  );
}
