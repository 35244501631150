import React, { useLayoutEffect, useState } from "react";
import ImageComponent from "./ImageComponent.jsx";

const ImageWithAspectRatio = ({ height, src }) => {
  return (
    <div
      style={{
        height: height > 630 ? "630px" : height,
        backgroundColor: "#f5f5f5",
      }}
    >
      <ImageComponent
        src={src}
        img_height={height > 630 ? "630px" : height}
        img_width="100%"
        loader_height={height > 630 ? "630px" : height}
        loader_width=""
        compClassName={height > 630 ? "object-contain" : "object-cover"}
      />
    </div>
  );
};

export default ImageWithAspectRatio;
