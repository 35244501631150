import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';

export const fetchEvents = async () => {
const {data} = JSON.parse(localStorage.getItem("SignupData"));

    // const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(
            `user/web/event/home`,
            // { user_id,type:"all" },
            {
                headers: {
                  Authorization: `Bearer ${data.token}`, // Set the Authorization header
                },
              }
        );
        return response.data;
    } catch (error) {
        toast.error('Error fetching events:', error);
        throw error;
    }
};

export const fetchMyEvents = async ({ status }) => {
    const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    try {
        const response = await apiAdmin.get(`user/event/my/${status}`,{
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header
            },
          });
        return response.data;
    } catch (error) {
        toast.error('Error fetching my events:', error);
        throw error;
    }
};

export const fetchJoinedEvents = async ({ status }) => {
    const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    try {
        const response = await apiAdmin.get(`user/event/joined/${status}`,{
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header
            },
          });
        return response.data;
    } catch (error) {
        toast.error('Error fetching joined events:', error);
        throw error;
    }
};

export const fetchSavedEvents = async () => {
    const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    try {
        const response = await apiAdmin.get(`user/event/favorite`,{
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header
            },
          });
        return response.data;
    } catch (error) {
        toast.error('Error fetching saved events:', error);
        throw error;
    }
};
export const fetchSuggestedEvents = async ({ page }) => {
  const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
  try {
      const response = await apiAdmin.get(
          `/user/web/event/suggested?page=${page}`,{
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
      );
      return response.data;
  } catch (error) {
      toast.error('Error fetching suggested events');
      throw error;
  }
};