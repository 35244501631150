import React from "react";
import { Col, Container, Row } from "reactstrap";
import SpecializationComponent from "../../components/auth/SpecializationComponent";

const Specialization = () => {
  return (
    // <Container fluid>
    //   <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
    //     <SpecializationComponent />
    //   </Row>
    // </Container>
    <Container fluid className="px-0">
  <Row
    className="d-flex justify-content-center align-items-center"
    style={{ minHeight: "100vh",minWidth:"100%" }}
  >
    {/* <Col xs={12} sm={10} md={8} lg={6} xl={4} className="p-4"> */}
      <SpecializationComponent />
    {/* </Col> */}
  </Row>
</Container>

  );
};

export default Specialization;
