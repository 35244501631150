import React from 'react'

const ShimmerClinicalSessionCard = () => {
    return (
        <>
            <div
                className="card p-3 shimmer-card"
                style={{ maxWidth: '400px',border: 'none' }}
            >
                {/* Title Placeholder */}
                <div
                    className=" shimmer-placeholder"
                    style={{
                        height: "25px",
                        width: "60%",
                        marginBottom: "10px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>

                {/* Caption Placeholder */}
                <div
                    className="shimmer-placeholder"
                    style={{
                        height: "15px",
                        width: "80%",
                        marginBottom: "20px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>

                {/* Category, Time, and Other Info Placeholder */}
                <div
                    className="shimmer-placeholder"
                    style={{
                        height: "40px",
                        width: "90%",
                        marginBottom: "10px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>
                <div
                    className="shimmer-placeholder"
                    style={{
                        height: "40px",
                        width: "90%",
                        marginBottom: "10px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>
                <div
                    className="shimmer-placeholder"
                    style={{
                        height: "40px",
                        width: "90%",
                        marginBottom: "10px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>

                {/* Created By Section Placeholder */}
                <div
                    className=" shimmer-placeholder"
                    style={{
                        height: "25px",
                        width: "30%",
                        marginBottom: "10px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>
                <div className="d-flex align-items-center gap-2 mt-2 mb-3">
                    <div
                        className="bg-white rounded-circle"
                        style={{
                            width: "50px",
                            height: "50px",
                            border: "1px solid #EBEDF0",
                            backgroundColor: "#EBEDF0",
                        }}
                    ></div>
                    <div>
                        <div
                            className="shimmer-placeholder"
                            style={{
                                height: "15px",
                                width: "120px",
                                marginBottom: "5px",
                                backgroundColor: "#EBEDF0",
                            }}
                        ></div>
                        <div
                            className="shimmer-placeholder"
                            style={{
                                height: "10px",
                                width: "80px",
                                backgroundColor: "#EBEDF0",
                            }}
                        ></div>
                    </div>
                </div>

                {/* Button Placeholder */}
                <div
                    className="btn mt-4 w-100 shimmer-placeholder"
                    style={{
                        height: "35px",
                        backgroundColor: "#EBEDF0",
                    }}
                ></div>
            </div>
        </>
    )
}

export default ShimmerClinicalSessionCard