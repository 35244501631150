import React, { useState, useEffect, useContext } from "react";
import { MdOutlineMail, MdOutlineLock } from "react-icons/md";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Authlogo from "../../../assets/images/auth/newAuthLogo.svg";
import { adminBaseURL, BaseURL, LoginApi } from "../../../constants/API";
import email_icon from "../../../assets/images/auth/login_email.svg";
import pass_icon from "../../../assets/images/auth/login_password.svg";
import pending from "../../../assets/images/auth/pending.svg";
import { Link } from "react-router-dom";
import "./NewLogin.css";
import axios from "axios";
import NewAuthFooter from "../../../components/auth/NewAuthFooter";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { FEED, SPECIALIZATION } from "../../../constants/RoutesUrl";
import { Language } from "../../../App";

const NewLogin = () => {
  const { lang } = useContext(Language);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<FaRegEye />);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorApi, setErrorApi] = useState("");
  const [showToaster, setShowToaster] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalPending, setModalPending] = useState(false);
  const navigate = useNavigate();

  // Show toaster when password reset is successful
  useEffect(() => {
    const resetPasswordStatus = sessionStorage.getItem("resetPassword");
    if (resetPasswordStatus === "success") {
      setShowToaster(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToaster(false);
      sessionStorage.removeItem("resetPassword");
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(type === "password" ? <FaRegEyeSlash /> : <FaRegEye />);
  };

  const validateForm = () => {
    const tempErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9_.-]{3,}$/;

    if (!email.trim()) {
      tempErrors.email = lang?.new_emailaddress_valid;
    } else if (
      !emailRegex.test(email.trim()) &&
      !usernameRegex.test(email.trim())
    ) {
      tempErrors.email = lang?.please_enter_valid_email_or_username;
    }

    if (!password.trim()) {
      tempErrors.password = lang?.new_password_valid;
    }

    setErrors(tempErrors);

    // Return true if there are no errors
    return Object.keys(tempErrors).length === 0;
  };

  // const validateForm = () => {
  //   const tempErrors = {};
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!email.trim()) {
  //     tempErrors.email = lang?.new_emailaddress_valid;
  //   } else if (!emailRegex.test(email.trim())) {
  //     tempErrors.email = lang?.please_enter_valid_email;
  //   }

  //   if (!password.trim()) {
  //     tempErrors.password = lang?.new_password_valid;
  //   }

  //   setErrors(tempErrors);

  //   // Return true if there are no errors
  //   return Object.keys(tempErrors).length === 0;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorApi("");

    if (validateForm()) {
      setLoading(true);

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      try {
        const response = await axios.post(LoginApi, {
          login: email.trim(),
          password: password.trim(),
          timezone: timeZone,
        });

        if (response.data.status) {
          console.log(response.data);
          const userData = response.data.data;

          if (userData.verified === 1) {
            // Save user data in localStorage
            localStorage.setItem("SignupData", JSON.stringify(response.data));

            // Determine redirect URL
            const url = sessionStorage.getItem("postUrl");
            const redirectUrl = url || FEED;

            // Navigate based on subcategory
            navigate(userData.subcategory ? redirectUrl : SPECIALIZATION);
          } else {
            setModalPending(true);
          }
        } else {
          // Handle API error
          setErrorApi(
            response.data.action || "An error occurred. Please try again."
          );
        }
      } catch (error) {
        // Handle network or server errors
        setErrorApi(
          error.response?.data?.action ||
            "An unexpected error occurred. Please try again."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  // set countries in local storage
  useEffect(() => {
    const url = adminBaseURL + "app/country";

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("countriesData", JSON.stringify(data.data));
      })
      .catch((err) => {
        console.error("Fetch error:", err);
      });
  }, []);

  return (
    <>
      <div className="new_login_bg position-relative signup_height_log responsive_signup_height">
        <img
          src={Authlogo}
          className="position-absolute w_12"
          alt="LOGO"
          style={{ top: "40px", left: "40px" }}
        />
        <div className="container custom-height pb-lg-0 pb-md-0 pb-sm-0 pb-xsm-4 pb-4">
          <div className="row">
            <div className="setup-size pt-30">
              <form className="log-box py-3 px-4" onSubmit={handleSubmit}>
                <div className="d-flex flex-column mt-4 gap-2">
                  <h5 className="mb-0">{lang?.welcome_to} Medical Radar!</h5>
                  <p>
                    {lang?.new_sign_in_desc} <br />
                    {lang?.new_sign_in_desc_compl}
                  </p>

                  <div className="d-flex flex-column mt-3 mb-2 gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <label>{lang?.email_or_Username}</label>
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>
                    <div className="position-relative input-div">
                      <input
                        type="text"
                        placeholder={lang?.enter_email_username}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {/* <MdOutlineMail className="position-absolute log-icon" /> */}
                      <div
                        className="position-absolute"
                        style={{ top: "11px", left: "10px" }}
                      >
                        <img
                          src={email_icon}
                          alt="email_icon"
                          style={{
                            width: "15px",
                            height: "15px",
                            opacity: "0.7",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-2 mb-2 gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <label>{lang?.password}</label>
                      {errors.password && (
                        <small className="text-danger">{errors.password}</small>
                      )}
                    </div>
                    <div className="position-relative input-div">
                      <input
                        type={type}
                        placeholder={lang?.enter_password}
                        style={{ padding: "15px 27px 15px 35px" }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <MdOutlineLock
                        className="position-absolute log-icon"
                        style={{ top: "18px", left: "10px" }}
                      />
                      <span
                        className="position-absolute"
                        style={{
                          right: "11px",
                          top: "12px",
                          color: "#7991A4",
                        }}
                        onClick={handleToggle}
                      >
                        {icon}
                      </span>
                    </div>
                  </div>

                  <p className="text-danger text-center mb-0">{errorApi}</p>
                  <p className="text-center mt-2 my-2 res-pass">
                    {lang?.new_forgot_desc}
                    <Link to="/forgot-password" className="ms-1 new_link">
                      {lang?.new_reset}
                    </Link>
                  </p>

                  <button
                    className="new_main_btn p-12 my-lg-3 my-md-2 my-sm-2 my-1"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-border text-white"
                          style={{ width: "1.35rem", height: "1.35rem" }}
                        ></span>
                      </div>
                    ) : (
                      lang?.sign_in
                    )}
                  </button>

                  <p className="text-center my-2 mb-3 res-pass">
                    {lang.new_dont_account}
                    <Link to="/signup" className="ms-1 new_link">
                      {lang?.new_join}
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showToaster && (
          <div className={`success_msg ${showToaster ? "show" : ""}`}>
            {lang.new_reset_success}
          </div>
        )}
        <NewAuthFooter position={"custom-absolute"} />
      </div>

      <Modal
        className="inst-box"
        isOpen={modalPending}
        centered
        toggle={() => setModalPending(true)}
      >
        <ModalBody className="px-4">
          <div className="d-flex flex-column justify-content-center">
            <img
              style={{ objectFit: "cover", width: "65px" }}
              src={pending}
              alt="PENDING"
            />
            <p className="mb-4 mt-4" style={{ fontFamily: "NotoSans-bold" }}>
              Approval Pending!
            </p>
            <p style={{ fontFamily: "NotoSans-medium", fontSize: "17px" }}>
              Your request to join is under review. Admin approval may take up
              to 24 hours. Please wait before attempting to sign in again.
            </p>
          </div>
        </ModalBody>
        <Link
          to="/"
          onClick={() => setModalPending(false)}
          style={{ cursor: "pointer" }}
          className=" text-decoration-none text-black"
        >
          <ModalFooter className="d-flex flex-column align-items-center justify-content-center">
            <button
              className="new_main_btn w-100 py-3"
              style={{ fontFamily: "NotoSans-bold" }}
            >
              Okay
            </button>
          </ModalFooter>
        </Link>
      </Modal>
    </>
  );
};

export default NewLogin;
