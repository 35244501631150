import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Spinner from "../Spinner";
import PlaceholderImg from "../../assets/images/icon/placeholder_Awatar.png";
import ArrowRight from "../../assets/images/icon/arrow-right.png";
import axios from "axios";
import { _imagesURL, BaseURL, JoinedMembersApi } from "../../constants/API";
import { useNavigate, useParams } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import { Language } from "../../App";
import { getToken } from "../../shared/token";
const JoinedMembers = () => {
  const event = useParams();
  const event_id = event.id;

  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;
  const { lang } = useContext(Language);
  const [spin, setSpin] = useState(false);
  const [data, setdata] = useState({});
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const authorize ={
        
    headers: {
        Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },

}
  
  const handleNewPageData = () => {
    setIsLoading(true);
    axios
      .get(
        JoinedMembersApi +
          event_id +
  
          "?page=" +
          JSON.stringify(page + 1)
      ,authorize)
      .then((res) => {
        const updatedValue = res.data.data.data;
        setUserData(userData.concat(updatedValue));
        setdata(res.data.data);
        setPage(page + 1);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handlePurchasedUsers = () => {
    setSpin(true);
    axios
      .get(
        JoinedMembersApi +
          event_id +
          "?page=" +
          JSON.stringify(page),authorize
      )
      .then((res) => {
        setUserData(res.data.data.data);
        setdata(res.data.data);

        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  };

  useEffect(() => {
    handlePurchasedUsers();
  }, []);
  return (
    <Row>
      {spin ? (
        <div className="vh-100 w-100">
          <Spinner />
        </div>
      ) : (
        <Col
          lg={7}
          className="bg-white p-4 ms-5"
          style={{ borderRadius: "12px" }}
        >
          <Row>
            <Col className="text-center">
              <h3 className="fs-20 fs-md-16 roboto-bold">{lang?.joined_members}</h3>
            </Col>
          </Row>
          {userData &&
            userData.map((value, index) => (
              <Fragment key={index}>
                <Profile
                  id={value?.id}
                  user_image={value?.image}
                  user_name={value?.name}
                  category={value?.category}
                  sub_category={value?.subcategory}
                />
              </Fragment>
            ))}

          {data?.current_page === data?.last_page ? (
            <></>
          ) : (
            <Row>
              <Col className="text-center">
                <button
                  disabled={isLoading}
                  className="btn-blue border-0 px-md-3 py-3"
                  onClick={() => {
                    handleNewPageData();
                  }}
                >
                  {isLoading ? <div className="loader"></div> : lang?.load_more}
                </button>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};

export default JoinedMembers;

const Profile = (props) => {
  const navigate = useNavigate();
  return (
    <Row
      onClick={() => {
        navigate(PROFILE + "/" + props.id);
      }}
      className="pb-2 my-3 cursor"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
        borderRadius: "12px",
      }}
    >
      <Col lg={11} className="d-flex gap-3">
        <div className="align-self-center">
          <div
            className="bg-white p-1 br-24 dropdown-avatar-shadow"
            style={{ minHeight: "5rem", minWidth: "5rem" }}
          >
            <img
              src={
                props?.user_image === ""
                  ? PlaceholderImg
                  : _imagesURL + props?.user_image
              }
              style={{
                height: "5rem",
                width: "5rem",
                objectFit: "fill",
              }}
              className="br-24"
              alt="picture"
            />
          </div>
        </div>
        <div className="align-self-center">
          <h4 className="fs-18 fs-md-16 roboto-bold">{props?.user_name}</h4>
          <span className="fs-18 fs-md-16 roboto-regular" style={{ color: "#555555" }}>
            {props?.category}{" "}
            {`(${
              props?.sub_category && props.sub_category.length > 50
                ? `${props.sub_category.slice(0, 25)}...`
                : props.sub_category
            })`}
          </span>
        </div>
      </Col>
      <Col
        lg={1}
        className="align-self-center text-center"
        style={{ opacity: "50%" }}
      >
        <img src={ArrowRight} alt="picture" />
      </Col>
    </Row>
  );
};
