import React from "react";
import { ShimmerBadge, ShimmerCircularImage } from "react-shimmer-effects";

const DebitCreditShimmer = () => {
  return (
    <>
      <div className="debitShimmer">
        <h3>
          <ShimmerBadge width={170} />
        </h3>
        <h4>
          <ShimmerBadge width={200} />
        </h4>

        <div className="border_top_bottom">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between">
              <h2>
                <ShimmerBadge width={200} />
              </h2>
              <h2>
                <ShimmerBadge width={100} />
              </h2>
            </div>
            <div className="d-flex justify-content-between">
              <h2>
                <ShimmerBadge width={200} />
              </h2>
              <h2>
                <ShimmerBadge width={100} />
              </h2>
            </div>
            <div className="d-flex justify-content-between">
              <h2>
                <ShimmerBadge width={200} />
              </h2>
              <h2>
                <ShimmerBadge width={100} />
              </h2>
            </div>
          </div>
        </div>

        <div className="border-bottom_shimmer">
          <h2>Course Detail:</h2>
          <div className="d-flex align-items-center gap-3 mt-3">
            {/* <img src={watch} alt="..." /> */}
            <ShimmerCircularImage size={70} />
            <div>
              <h2 className="mb-2">
                <ShimmerBadge width={200} />
              </h2>
              <h4 className="m-0">
                <ShimmerBadge width={90} />
              </h4>
            </div>
          </div>
        </div>

        <div>
          <h2>Customer Detail:</h2>
          <div className="d-flex align-items-center gap-3 mt-3">
            {/* <img src={watch} alt="..." /> */}
            <ShimmerCircularImage size={70} />
            <div>
              <h2 className="mb-2">
                <ShimmerBadge width={200} />
              </h2>
              <h4 className="m-0">
                <ShimmerBadge width={90} />
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DebitCreditShimmer;
