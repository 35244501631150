import React, { useEffect, useContext, useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Spinner from "../Spinner";
import PlusIcon from "../../assets/images/icon/plus.png";
import axios from "axios";
import {
  AddCourseSectionApi,
  adminBaseURL,
  DeteleCourseSectionApi,
  EditCourseSectionApi,
  GetCourseSectionApi,
} from "../../constants/API";
import { Language } from "../../App";
import { toast } from "react-toastify";
import { getToken } from "../../shared/token";

const CourseContent = (props) => {
  const course_id = sessionStorage.getItem("CourseId");
  const { lang } = useContext(Language);
  const [spin, setSpin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(false);
  const [courseSection, setCourseSection] = useState([]);
  const [addCourseSection, setAddCourseSection] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [editCourseSection, setEditCourseSection] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const authorize ={
        
    headers: {
        Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },

}
  const handleEditSection = () => {
    setIsLoading(true);

    if (editData.name.length > 150) {
      setIsLoading(false);
      toast.error(lang?.title_is_long);
      return;
    }

    axios
      .post(`${adminBaseURL}user/course/section/update`, {
        section_id: editData.id,
        name: editData.name,
        description: editData.description,
      },authorize)
      .then((res) => {
        setIsLoading(false);
        setEditCourseSection(false);
        setDataRefetch(!dataRefetch);
        setEditData({});
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleAddSection = () => {
    setIsLoading(true);

    if (sectionTitle.length > 150) {
      setIsLoading(false);
      toast.error(lang?.title_is_long);
      return;
    }

    axios
      .post(`${adminBaseURL}user/course/section/create`,{
        course_id,
        name: sectionTitle,
        description: sectionDescription,
      },authorize)
      .then((res) => {
        setIsLoading(false);
        setAddCourseSection(false);
        setDataRefetch(!dataRefetch);
        setSectionTitle("");
        setSectionDescription("");
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/section/delete/${id}`,authorize)
      .then((res) => {
        setIsLoading(false);
        setDeleteModal(false);
        if (res.data.status) {
          setDataRefetch(!dataRefetch);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleData = () => {
    setSpin(true);
    axios
      .get(`${adminBaseURL}user/course/sections/${course_id}`,authorize)
      .then((res) => {
        setCourseSection(res.data.data.sections);
        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  };

  useEffect(() => {
    handleData();
  }, [dataRefetch]);

  return (
    <>
      <Row>
        {spin ? (
          <div className="ticketchatspinner">
            <Spinner />
          </div>
        ) : (
          <Col
            xl={9}
            className="bg-white p-4 ms-xl-3 ms-lg-0 ms-md-0 ms-sm-0 ms-0"
            style={{ borderRadius: "12px" }}
          >
            <Row className="justify-content-end">
              <Col lg={8} className="d-flex justify-content-between">
                <h3 className="fs-20 fs-md-16 roboto-bold">
                  {lang?.course_section}
                </h3>
                <img
                  src={PlusIcon}
                  width={25}
                  height={25}
                  alt="picture"
                  className="cursor"
                  onClick={() => setAddCourseSection(true)}
                />
              </Col>
            </Row>
            {courseSection.map((value, index) => (
              <>
                <Row
                  key={index}
                  className="py-2 my-3 "
                  style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                  }}
                >
                  <Col
                    xl={8}
                    lg={7}
                    className="d-flex gap-3 cursor"
                    onClick={() => {
                      props.clickContent(6);
                      sessionStorage.setItem("section_id", value.id);
                    }}
                  >
                    <div className="my-3 ps-2">
                      <h4 className="fs-18 fs-md-16 roboto-bold">
                        {value.name}
                      </h4>
                      <span
                        className="fs-18 fs-md-16 roboto-regular py-2"
                        style={{ color: "#2C2C2Cs" }}
                      >
                        {value.description}
                      </span>
                    </div>
                  </Col>
                  <Col xxl={4} className="d-flex justify-content-end">
                    <Row className="justify-content-between align-self-center">
                      <Col>
                        <button
                          className="btn px-4 py-2 text-white fs-20 fs-md-16 roboto-medium"
                          style={{ background: "#69C34D" }}
                          onClick={() => {
                            setEditData(value);
                            setEditCourseSection(true);
                          }}
                        >
                          {lang?.edit}
                        </button>
                      </Col>
                      <Col>
                        <button
                          className="btn-red px-4 rounded border-0 py-2"
                          onClick={() => {
                            setDeleteId(value.id);
                            setDeleteModal(true);
                          }}
                        >
                          {lang?.delete}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
          </Col>
        )}
      </Row>
      {/* Add Modal */}
      <Modal
        isOpen={addCourseSection}
        toggle={() => setAddCourseSection(!addCourseSection)}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9}
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 fs-md-16 roboto-bold">
                {lang?.add_section}
              </h4>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.section_title}
              </label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2"
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
                placeholder={lang?.enter_sec_title}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.short_description}
              </label>
              <textarea
                onChange={(e) => setSectionDescription(e.target.value)}
                value={sectionDescription}
                className="form-control mt-2"
                placeholder={lang?.write_sec_desc_her}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                }}
                cols="30"
                rows="6"
              ></textarea>
            </Col>
          </Row>
          <Row className="my-3">
            <div className="justify-content-end gap-3 d-flex">
              <button
                disabled={isLoading || !sectionDescription || !sectionTitle}
                className="btn-blue text-white border-1 border rounded w-100 py-2"
                onClick={() => {
                  handleAddSection();
                }}
              >
                {isLoading ? <div className="loader"></div> : lang?.save}
              </button>

              <button
                className="manue-hover text-dark border-1 border rounded w-100 py-2"
                onClick={() => {
                  setSectionDescription("");
                  setSectionTitle("");
                  setAddCourseSection(false);
                }}
              >
                {lang?.cancel}
              </button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      {/* Edit Modal */}
      <Modal
        isOpen={editCourseSection}
        toggle={() => setEditCourseSection(!editCourseSection)}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9}
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 fs-md-16 roboto-bold">{lang?.edit_sect}</h4>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.section_title}
              </label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2"
                value={editData.name}
                onChange={(e) => {
                  const update = {
                    ...editData,
                    name: e.target.value,
                  };
                  setEditData(update);
                }}
                placeholder={lang?.enter_sec_title}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.short_description}
              </label>
              <textarea
                value={editData.description}
                onChange={(e) => {
                  const update = {
                    ...editData,
                    description: e.target.value,
                  };
                  setEditData(update);
                }}
                className="form-control mt-2"
                placeholder={lang?.write_sec_desc_her}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                }}
                cols="30"
                rows="6"
              ></textarea>
            </Col>
          </Row>
          <Row className="my-3">
            <div className="justify-content-end gap-3 d-flex">
              <button
                disabled={isLoading || !editData.description || !editData.name}
                className="btn-blue text-white border-1 border rounded w-100 py-2"
                onClick={() => {
                  handleEditSection();
                }}
              >
                {isLoading ? <div className="loader"></div> : lang?.save}
              </button>

              <button
                className="manue-hover text-dark border-1 border rounded w-100 py-2"
                onClick={() => {
                  setEditData({});
                  setEditCourseSection(false);
                }}
              >
                {lang?.cancel}
              </button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div>
            <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
              {lang?.want_del_sec}
            </h2>
            <p className="fs-18 fs-md-16 roboto-regular">
              {lang?.want_del_sec_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded w-100 py-2"
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CourseContent;
