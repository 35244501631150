import React from 'react';
import { Container, Row } from 'reactstrap';
import './WithdrawRequests.css';
import timer_processing from "../../../assets/images/icons/timer_processing.png"
const WithdrawRequests = ({ withdrawPayments,clearanceDays=0 }) => {
  // Format the date and time from the withdrawPayments object
  const formatDate = (timestamp,) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };
  const _formatDate = (dateString) => {
    
    const date = new Date(dateString);
    date.setDate(date.getDate() + clearanceDays); 
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <Container fluid>
      <Row className="justify-content-center mt-3">
        <div className="content-wrapper">
          <h5 className="section-heading">Withdraw Requests:</h5>
          {withdrawPayments.map((payment, index) => (
            <div className="card-container" key={index} style={{ padding: "20px", marginBottom: "15px" }}>
              <div className="header-section d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="request-title" style={{ color: "#ff9500" }}>{payment.feature}</h6>
                  <p className="request-time">
                    {formatTime(payment.time)} - {formatDate(payment.time)}
                  </p>
                </div>
                <img src={timer_processing} alt="Processing Timer" className="icon-image" />{/* Replace with an icon if needed */}
              </div>
              <div className="details-section mt-3">
                <p>
                  <strong>Request Clearance Date:</strong> 
                  <span className="float-end">{_formatDate(payment.date)}</span> {/* Adjust clearance date as needed */}
                </p>
                <p className="text-gray">
                  Your payment withdrawal request is being processed. Please wait until the remaining time has expired.
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-between total-amount mt-3">
                <h6 className="amount">Total amount requested:</h6>
                <h4 className="amount text-end">€{parseFloat(payment.amount).toFixed(2)}</h4>
              </div>
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default WithdrawRequests;



