import { useContext, useState } from "react";
import AddMultipleOptionModal from "./AddMultipleOptionModal";
import { Language } from "../../../App";
import {
    Col,  
    Modal,
    ModalFooter,
    ModalHeader, 
    Row,
  } from "reactstrap";
const AddQuestionModal = ({ isOpen, toggle, getQuestions }) => {
    const { lang } = useContext(Language);
  
    const [questionsData, setQuestionsData] = useState([
      {
        question: "",
        answers: [""],
        
      },
    ]);
    const [optionsModal, setOptionsModal] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null); // To track which question is being edited
  
    const addQuestion = () => {
      const newQuestion = { question: "", answers: [""] };
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData.push(newQuestion);
      setQuestionsData(updatedQuestionsData);
    };
  
    const removeQuestion = (index) => {
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData.splice(index, 1);
      setQuestionsData(updatedQuestionsData);
    };
  
    const saveAllQuestions = () => {
      const numberOfQuestions = questionsData.length;
      console.log("Number of questions added:", numberOfQuestions);
      getQuestions(questionsData,numberOfQuestions);
  
      toggle();
      // return numberOfQuestions;
    };
  
    // Function to update the options count for the current question
    const updateOptionsCount = (index, count) => {
      setQuestionsData((prev) =>
        prev.map((question, i) =>
          i === index ? { ...question, optionsCount: count } : question
        )
      );
    };
  
    return (
      <>
        <Modal isOpen={isOpen} centered size="lg">
          <ModalHeader toggle={toggle}>Add Questions</ModalHeader>
          <div className="p-3">
            {questionsData &&
              questionsData.map((value, index) => (
                <Row key={index}>
                  <Col>
                    <Row>
                      <Col>
                        <label className="pb-1 fs-15 roboto-medium">
                          {lang?.question + " " + (index + 1)}
                        </label>
                      </Col>
                      <Col className="text-end">
                        <label
                          className="pb-1 fs-15 roboto-medium text-blue cursor"
                          onClick={() => {
                            setCurrentQuestionIndex(index);
                            setOptionsModal(true);
                          }}
                        >
                          {lang?.add_multiple_option}{" "}
                          <span style={{ opacity: "0.3" }} className="text-dark">
                            {"(Optional)"}
                          </span>
                        </label>
                        <div>
                          {value.optionsCount > 0 &&
                            `(${value.optionsCount} options added)`}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type="text"
                          className="py-2 form-control"
                          placeholder="Question"
                          value={value.question}
                          onChange={(e) =>
                            setQuestionsData((prev) =>
                              prev.map((question, i) =>
                                i === index
                                  ? { ...question, question: e.target.value }
                                  : question
                              )
                            )
                          }
                        />
                      </Col>
  
                      <Col md={1} className="align-self-center">
                        <button
                          className="rounded border-0 fw-bold px-2"
                          onClick={() => removeQuestion(index)}
                        >
                          x
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            <Row className="mt-3">
              <Col className="text-end">
                <button
                  className="bg-white rounded text-black fw-bold px-4 py-1"
                  style={{ border: "1px solid #1DC1DD" }}
                  onClick={addQuestion}
                >
                  {lang?.add_question}
                </button>
              </Col>
            </Row>
          </div>
          <ModalFooter className="gap-3">
            <button className="btn btn-secondary px-5" onClick={toggle}>
              {lang?.cancel}
            </button>
            <button className="btn-blue border-0 py-2 px-5" onClick={saveAllQuestions}>
              {lang?.save}
            </button>
          </ModalFooter>
        </Modal>
  
        <AddMultipleOptionModal
          isOpen={optionsModal}
          toggle={() => setOptionsModal(!optionsModal)}
          saveOptions={(count) => updateOptionsCount(currentQuestionIndex, count)} // Pass the count to parent
        />
      </>
    );
  };

  export default AddQuestionModal