import { authorize } from "../shared/authorize";
import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';

export const getRecentSearchApi = async () => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/web/recent-search`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching recent search:', error);
        throw error;
    }
}
export const getSearchAgainstKeywordApi = async (keyword) => {
    // Check if keyword is valid (not falsy)
    console.log("keyword",keyword)
    if (!keyword|| keyword === "undefined") {
      console.warn("Keyword is required to fetch search results.");
      return null; // Or handle it however you like
    }
  
    try {
      const response = await apiAdmin.post(
        `user/web/recent-search/keyword`,
        { keyword },
        authorize()
      );
      return response.data;
    } catch (error) {
      toast.error("Error fetching search against keyword");
      console.error("Error fetching search against keyword:", error);
      throw error;
    }
  };


export const getAllSearchAgainstKeywordApi = async ({keyword, type, pageParam, country, 
    city, 
    specialization,month,date,cost,sort,year}) => {
        console.log("cost",cost)
    const payload = { keyword, type };
    // Include country in the payload if type is 'doctors'
    if (type === "doctors") {
        if (country&&country.length>0) payload.country = country;
        if (city) payload.city = city;
        if (specialization && specialization.length > 0) payload.specialization = specialization;
    }
    if(type==="posts"||type==="courses"||type==="webinars"||type==="studies"
    ){
        if (specialization && specialization.length > 0) payload.specialization = specialization;
        if(date) payload.date=date
        if (sort) {
            if (sort === "Newest to Oldest") {
                payload.sort = "desc";
            } else if (sort === "Oldest to Newest") {
                payload.sort = "asc";
            } else {
                payload.sort = sort; // For other sort values, use the existing sort directly
            }
        }
        
    }
    if(type==="events"){
      if(month)  payload.month=month
      if(year)  payload.year=year
      if (specialization && specialization.length > 0) payload.specialization = specialization;
    }
    if(type==="courses"){
        if(cost) payload.cost=cost
    }
  
    try {
        const response = await apiAdmin.post(`user/web/recent-search?page=${pageParam || 1}`, payload,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching search against keyword:', error);
        throw error;
    }
}
export const clearRecentSearches = async () => {
    try {
      const response = await apiAdmin.get(
        'user/web/recent-search/clear',
        authorize()
      );
      console.log("Recent searches cleared successfully:", response.data);
      return response.data; // Return the response if needed
    } catch (error) {
      console.error("Error clearing recent searches:", error.response || error);
      throw new Error(
        error.response?.data?.message || "Failed to clear recent searches"
      );
    }
  };