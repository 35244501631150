import React from "react";
import { FaPlus } from "react-icons/fa6";
import NavBar from "../NavBar";
import { Container, Row } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./ManageContent.css";
import { IoIosArrowForward } from "react-icons/io";
import Delete from "../../assets/images/content_delete.svg";
import Edit from "../../assets/images/content_edit.svg";
import { GoDotFill } from "react-icons/go";
import { useState } from "react";
import NoChapter from "../../assets/images/no_chapter.svg";

function NewManageContentPage() {
  const [data, setData] = useState([
    { title: "Foundations of CyberSecurity", lecture: "5 Lectures", time: "19 Mins" },
    { title: "Advanced Security Concepts", lecture: "6 Lectures", time: "25 Mins" },
    { title: "Network Security", lecture: "4 Lectures", time: "15 Mins" },
    { title: "Graphical Security", lecture: "8 Lectures", time: "21 Mins" },
    { title: "Network Security", lecture: "7 Lectures", time: "12 Mins" },
  ]);

  const Product = [
    {
      section: "23",
      video: "23",
      length: "22h 2m",
    },
  ];

  const [modal, setModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleEdit = (chapter, index) => {
    setSelectedChapter({ ...chapter });
    setSelectedIndex(index);
    setModalEdit(true);
  };

  const saveEditedChapter = () => {
    const updatedData = [...data];
    updatedData[selectedIndex] = selectedChapter;
    setData(updatedData);
    setModalEdit(false);
  };

  return (
    <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <Container fluid className="p-0">
        <Row style={{ backgroundColor: "#f8f9fa" }}>
          <NavBar />
        </Row>
        <div className="container" style={{ marginTop: "80px" }}>
          <div className="row manag_content">
            <div className="d-flex align-items-center justify-content-between manag_content">
              <h4>Manage Content</h4>
              <div className="d-flex align-items-center gap-3">
                <button className="btn_new d-flex align-items-center gap-3 rounded">
                  <FaPlus />
                  <p className="mb-0" onClick={() => setModalAdd(!modalAdd)}>
                    Add New Chapter
                  </p>
                </button>
                <div className="btn_new_main text-white rounded">
                  Save Changes
                </div>
              </div>
            </div>
            <h5 className="mb-4 pt-4">Course Chapters</h5>

            {Product.length === 0 ||
            (Product[0].section === "" &&
              Product[0].video === "" &&
              Product[0].length === "") ? (
              <>
                <div className="d-flex align-items-center gap-3 content_subtitle">
                  <p className="mb-0">0 Sections</p>
                  <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">0 Videos</p>
                  <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">0 Total Length</p>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={NoChapter} alt="No Content Available" />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center gap-3 content_subtitle">
                  <p className="mb-0">{Product[0].section} Sections</p>
                  <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">{Product[0].video} Videos</p>
                  <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">{Product[0].length} Total Length</p>
                </div>
                <div className="pt-4">
                  {data.map((val, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center gap-4"
                    >
                      <div
                        className="d-flex align-items-center justify-content-between mb-3 w-100 rounded"
                        style={{
                          backgroundColor: "#E8F9FC",
                          border: '1px solid #BFEEF6',
                          padding: "13px 16px",
                        }}
                      >
                        <p className="mb-0">{val.title}</p>
                        <div className="d-flex align-items-center gap-3">
                          <small>{val.lecture}</small>
                          <small>{val.time}</small>
                          <IoIosArrowForward style={{ fontSize: "23px" }} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={Edit}
                          alt="Edit"
                          onClick={() => handleEdit(val, index)}
                        />
                        <img
                          src={Delete}
                          alt="Delete"
                          onClick={() => setModal(!modal)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Container>

      {/*  Delete Section Modal */}
      <Modal isOpen={modal} toggle={() => setModal(!modal)} centered size="md">
        <ModalBody className="px-3 pt-3">
          <h5 style={{ fontFamily: "NotoSans-bold" }}>
            Do you want to delete this section?
          </h5>
          <p style={{ fontFamily: "NotoSans-medium" }}>
            After enrolling to delete this section. Once <br /> deleted you
            won’t be able to recover it.
          </p>
        </ModalBody>
        <ModalFooter className="w-100 p-3">
          <Button
            className="cancel_btn rounded"
            onClick={() => setModal(!modal)}
          >
            Cancel
          </Button>
          <Button
            className="delete_btn rounded"
            onClick={() => setModal(!modal)}
          >
            Yes, Delete
          </Button>
        </ModalFooter>
      </Modal>

      {/* Add New Chapter Modal */}
      <Modal
        isOpen={modalAdd}
        toggle={() => setModalAdd(!modalAdd)}
        centered
        size="md"
      >
        <h5 className="modal-title text-center p-3">Add New Chapter</h5>
        <ModalBody className="px-5 py-4">
          <div className="d-flex mb-4 flex-column add_chapter gap-2">
            <label htmlFor="">Chapter Title</label>
            <input type="text" placeholder="Write Title" />
          </div>
          <div className="d-flex gap-3">
            <Button
              className="cancel_btn rounded"
              onClick={() => setModalAdd(!modalAdd)}
            >
              Cancel
            </Button>
            <Button
              className="delete_btn rounded"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={() => setModalAdd(!modalAdd)}
            >
              Add, Chapter
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Edit Chapter Modal */}
      <Modal
        isOpen={modalEdit}
        toggle={() => setModalEdit(!modalEdit)}
        centered
        size="md"
      >
        <h5 className="modal-title text-center p-3">Edit Chapter</h5>
        <ModalBody className="px-5 py-4">
          <div className="d-flex mb-4 flex-column add_chapter gap-2">
            <label htmlFor="chapterTitle">Chapter Title</label>
            <input
              id="chapterTitle"
              type="text"
              placeholder="Write Title"
              value={selectedChapter?.title || ""}
              onChange={(e) =>
                setSelectedChapter({
                  ...selectedChapter,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex gap-3">
            <Button
              className="cancel_btn rounded"
              onClick={() => setModalEdit(false)}
            >
              Cancel
            </Button>
            <Button
              className="delete_btn rounded"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={saveEditedChapter}
            >
              Save Changes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default NewManageContentPage;
