import React, { useContext, useState } from "react";
import {
    Col,
    Form,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    Modal,
} from "reactstrap";
import { Language, LoginUserData } from "../../App";
import { RiImageAddFill } from "react-icons/ri";
import ImageCropModal from "../common/ImageCropModal";
import CertificateModal from "./CertificateModal";
import axios from "axios"; // Make sure to import axios for API calls
import { toast } from "react-toastify"; // Import toast for notifications
import { AddCourseApi, adminBaseURL } from "../../constants/API";// Import your API endpoint constants

const CreateCourseModal = ({ isOpen, toggle }) => {
    const { lang } = useContext(Language);
    const { splashData } = useContext(LoginUserData);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
    const [isCertificateModelOpen, setIsCertificateModelOpen] = useState(false);
    const [certificates, setCertificates] = useState([]);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    // State for course data
    const [data, setData] = useState({
        title: "",
        subcategory: "",
        short_description: "",
        price: "",
    });
    const [errors, setErrors] = useState({
        title: "",
        subcategory: "",
        short_description: "",
        price: "",
        cover: "",
    });

    const [isLoading, setIsLoading] = useState(false); // Loading state for API call

    const user_id = splashData?.user?.id
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setIsCropModalOpen(true);
            };
        }
    };

    const handleCropComplete = (croppedImageURL) => {
        setCroppedImage(croppedImageURL);
        setIsCropModalOpen(false);
    };

    const handleRemoveImage = () => {
        setCroppedImage(null);
        setImageSrc(null);
    };

    const toggleImagePreview = () => setIsImagePreviewOpen(!isImagePreviewOpen);

    const handleAddCertificate = (index, certificate) => {
        if (index !== null) {
            setCertificates((prev) => {
                const updatedCertificates = [...prev];
                updatedCertificates[index] = certificate;
                return updatedCertificates;
            });
        } else {
            setCertificates((prev) => [...prev, certificate]);
        }
    };

    const handleOpenModalCertificateModal = (certificate, index) => {
        setSelectedCertificate({ ...certificate, index });
        setIsCertificateModelOpen(true);
    };

    const handleCloseModalCertificateModal = () => {
        setSelectedCertificate(null);
        setIsCertificateModelOpen(false);
    };

    const handleRemoveCertificate = (index) => {

        const newCertificates = certificates.filter((_, i) => i !== index);
        setCertificates(newCertificates);
    };
    const validateForm = () => {
        let formValid = true;
        const newErrors = {
            title: "",
            subcategory: "",
            short_description: "",
            price: "",
            cover: "",
        };

        // Title validation
        if (!data.title) {
            newErrors.title = lang.course_title_is_required;
            formValid = false;
        }

        // Subcategory validation
        if (!data.subcategory) {
            newErrors.subcategory = lang.course_category_is_required;
            formValid = false;
        }

        // Description validation
        if (!data.short_description) {
            newErrors.short_description = lang.course_description_is_required;
            formValid = false;
        }

        // Price validation
        if (!data.price) {
            newErrors.price = lang.course_price_is_required;
            formValid = false;
        } else if (isNaN(data.price)) {
            newErrors.price = lang.course_price_must_be_a_number;
            formValid = false;
        }

        // Cover image validation
        if (!croppedImage) {
            newErrors.cover = lang.course_cover_image_is_required;
            formValid = false;
        }

        // Set the errors state
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        return formValid;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        if (!data.subcategory || data.subcategory === "") {
            toast.error(lang?.select_course_subcategory_please);
            return;
        }

        const certificateTitles = certificates.map((certificate) => certificate.title);
        const commaSeparatedTitles = certificateTitles.join(", ");

        const certificateImages = certificates.map((certificate) => certificate.file);

        setIsLoading(true);


        try {
            const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
            const res = await axios.post(`${adminBaseURL}user/course/create`, {

                title: data.title,
                short_description: data.short_description,
                price: data.price,
                category: "3",
                subcategory: data.subcategory,
                // cover: croppedImage,
                cover_base64:croppedImage,
                titles: commaSeparatedTitles,
                images: certificateImages,
                // certifiedImages: certificateImages,
            }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.data.status) {
                setIsLoading(false);
                toggle(false); // Close modal on success
                toast.success(lang.course_saved_successfully);
                // window.location.reload(); // Refresh or redirect to another page
            } else {
                setIsLoading(false);
                toast.error(res.data.action);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Offcanvas isOpen={isOpen} toggle={toggle}>
                <OffcanvasHeader toggle={toggle}>{lang?.create_course}</OffcanvasHeader>
                <OffcanvasBody>
                    <p className="fs-14 roboto-regular text-gray">
                        {lang?.fill_course_para}
                    </p>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    {croppedImage ? (
                                        <div
                                            className="position-relative"
                                            style={{
                                                cursor: "pointer",
                                                border: "1px dashed #A2A2A2",
                                                minHeight: "128px",
                                                borderRadius: "6px",
                                            }}
                                            onClick={toggleImagePreview}
                                        >
                                            <img
                                                src={croppedImage}
                                                alt="Cropped Preview"
                                                className="w-100 br-16"
                                                style={{ objectFit: "cover", height: "15rem" }}
                                            />
                                            <button
                                                className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                                                style={{
                                                    top: "-10px",
                                                    right: "-10px",
                                                    background: "transparent",
                                                    border: "none",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveImage();
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                border: "1px dashed #A2A2A2",
                                                minHeight: "128px",
                                                borderRadius: "6px",
                                            }}
                                            className="d-flex flex-column align-items-center justify-content-center"
                                            onClick={() => {
                                                document.getElementById("fileInput").click();
                                            }}
                                        >
                                            <RiImageAddFill size={20} color="#848798" />
                                            <h4 className="fs-14 roboto-medium pt-3" style={{ color: "#848798" }}>
                                                {lang.add_course_cover}
                                            </h4>

                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="d-none"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                    {errors.cover && <p className="text-danger">{errors.cover}</p>}
                                </Col>
                            </Row>
                            <Form className="mt-3" onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Col>
                                        <input
                                            type="text"
                                            className="py-2 form-control"

                                            placeholder={lang.course_title}
                                            value={data.title}
                                            onChange={(e) => setData({ ...data, title: e.target.value })}
                                        />
                                        {errors.title && <p className="text-danger">{errors.title}</p>}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        <select
                                            className="form-select py-2"

                                            value={data.subcategory}
                                            onChange={(e) => setData({ ...data, subcategory: e.target.value })}
                                        >
                                            <option value="" disabled hidden >{lang.select_course_category}</option>
                                            {splashData?.home?.subcategories?.map((value, index) => (
                                                <option value={value.id} key={index}>
                                                    {value.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.subcategory && <p className="text-danger">{errors.subcategory}</p>}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        <input
                                            type="number"
                                            className="py-2 form-control"
                                            placeholder={lang.course_price}
                                            value={data.price}
                                            onChange={(e) => setData({ ...data, price: e.target.value })}
                                        />
                                        {errors.price && <p className="text-danger">{errors.price}</p>}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <textarea
                                            className="form-control resize"

                                            rows="5"
                                            placeholder={lang.course_desc}
                                            value={data.short_description}
                                            onChange={(e) => setData({ ...data, short_description: e.target.value })}
                                        ></textarea>
                                        {errors.short_description && <p className="text-danger">{errors.short_description}</p>}
                                    </Col>
                                </Row>
                                <hr style={{ opacity: 0.2 }} />
                                <Row className="mb-3">
                                    <Col>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => setIsCertificateModelOpen(!isCertificateModelOpen)}
                                                className="btn bg-gray text-black px-4"
                                            >
                                                <b>{lang.add_certificates}</b>
                                                <span className="text-secondary"> {lang.opti}</span>
                                            </button>
                                            <div className="certificates-list my-3 d-flex flex-row flex-wrap">
                                                {certificates.map((cert, index) => (
                                                    <div key={index} className="certificate-item position-relative mx-1 my-1">
                                                        <button
                                                            type="button"
                                                            className="btn bg-gray text-black roboto-bold py-2 px-3 border border-2 position-relative"
                                                            onClick={() => handleOpenModalCertificateModal(cert, index)}
                                                        >
                                                            {lang.certificate} {index + 1}
                                                            <span
                                                                className="fw-bold text-black px-1 rounded position-absolute end-0 bg-white border cursor"
                                                                aria-label="Close"
                                                                style={{ marginTop: "-20%", marginRight: "-11%" }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRemoveCertificate(index);
                                                                }}
                                                            >
                                                                &times;
                                                            </span>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr style={{ opacity: 0.2 }} />
                                <div className="row">
                                    <div className="col">
                                        <button onClick={() => toggle(false)} type="button" className="bg-secondary text-white border-0 w-100 my-4 py-3 rounded fw-bold">
                                            {lang.cancel}
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button type="submit" className="btn-blue border-0 w-100 my-4 py-3 rounded fw-bold" disabled={isLoading}>
                                            {isLoading ? "Adding Course..." : lang.add_course}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </OffcanvasBody>
            </Offcanvas>
            <ImageCropModal
                isOpen={isCropModalOpen}
                toggle={() => setIsCropModalOpen(!isCropModalOpen)}
                imageSrc={imageSrc}
                aspect={0.86}
                onCropComplete={handleCropComplete}
            />
            <Modal isOpen={isImagePreviewOpen} toggle={toggleImagePreview} centered className="image-preview-modal">
                <img src={croppedImage} alt="Full Preview" className="img-fluid" />
            </Modal>
            {isCertificateModelOpen && <CertificateModal
                isOpen={isCertificateModelOpen}
                toggle={handleCloseModalCertificateModal}
                onSave={handleAddCertificate}
                initialCertificate={selectedCertificate}
            />}
        </>
    );
};

export default CreateCourseModal;
