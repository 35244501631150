import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import email_icon from "../../../assets/images/auth/email_A.svg";
import Authlogo from "../../../assets/images/auth/newAuthLogo.svg"; // Update the path as per your project
import NewAuthFooter from "../../../components/auth/NewAuthFooter";
import { ForgotPasswordApi } from "../../../constants/API";
import { OtpVerificationApi } from "../../../constants/API";
import { Language } from "../../../App";

function NewForgotPassword() {
  const { lang } = useContext(Language);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [modal, setModal] = useState(false);
  const [values, setValues] = useState(Array(6).fill("")); // OTP input fields
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [product, setProduct] = useState("");
  const [errorApi, setErrorApi] = useState("");
  const [errorOtp, setErrorOtp] = useState("");
  const [errorOtpApi, setErrorOtpApi] = useState("");
  const [pauseCancel, setPauseCancel] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("Password");
  }, []);

  const handleChange = (index, event) => {
    const { value } = event.target;

    if (/^\d*$/.test(value)) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);

      if (value !== "" && index < values.length - 1) {
        const nextInput = event.target.nextElementSibling;
        if (nextInput) nextInput.focus();
      }

      if (newValues.every((val) => val !== "")) {
        setError("");
      }
    }
  };

  const handleKeyPress = (index, event) => {
    if (event.key === "Backspace" && index > 0 && values[index] === "") {
      const prevInput = event.target.previousElementSibling;
      if (prevInput) prevInput.focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text").trim();

    if (/^\d+$/.test(pasteData)) {
      const newValues = Array(6).fill("");
      const pasteValues = pasteData.split("").slice(0, newValues.length);

      pasteValues.forEach((digit, index) => {
        if (index < newValues.length) {
          newValues[index] = digit;
        }
      });

      setValues(newValues);

      const lastInputIndex = Math.min(pasteValues.length, newValues.length) - 1;
      const lastInput = document.querySelector(
        `.otp-inputs input:nth-child(${lastInputIndex + 1})`
      );
      if (lastInput) lastInput.focus();
    } else {
      setErrorOtp("Invalid input. Only numbers are allowed.");
      // setErrorOtp(lang?.new_invalid_otp);
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setErrors({ email: lang?.new_emailaddress_valid });
      return false;
    } else if (!emailRegex.test(email)) {
      setErrors({ email: lang?.please_enter_valid_email });
      return false;
    }
    setErrors({ email: "" });
    return true;
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setErrorApi("");

    if (validateEmail()) {
      setLoading(true);

      axios
        .post(ForgotPasswordApi, { email })
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            sessionStorage.setItem("email", email);
            console.log(response.data.data);
            setProduct(response.data.data);
            setModal(true);
          } else {
            console.log(response.data.action);
            setErrorApi(response.data.action);
          }
        })
        .catch((error) => {
          console.error("Error initiating password recovery", error);
          setErrorApi(error.response.data.action);
          // toast.error("An error occurred. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const SubmitOTP = async (e) => {
    e.preventDefault();

    if (values.some((val) => val === "")) {
      setErrorOtp(lang?.please_enter_otp);
      return;
    }
    setErrorOtp("");

    setLoader(true);
    setPauseCancel(false);
    console.log(email);
    const otpString = values.join("");
    // const params = { email, code: otpString };

    try {
      const response = await axios.post(OtpVerificationApi, {
        email,
        code: otpString,
      });
      if (response.data.status) {
        console.log(response.data);
        setErrorOtpApi("");
        if (sessionStorage.getItem("verify")) {
          console.log("Removing existing verify value");
          sessionStorage.removeItem("verify");
        }
        navigate("/change-password");
      } else {
        //   toast.error(response.data.action);
        setErrorOtpApi(response.data.action);
      }
    } catch (error) {
      console.error("Error during OTP verification", error);
      setErrorOtpApi(error.response.data.action);
    } finally {
      setLoader(false);
      setPauseCancel(true);
    }
  };

  return (
    <>
      {/* <div
        className="w-100"
        style={{ backgroundColor: "#00c5de", padding: "40px 0 15px 40px" }}
      >
      </div> */}
      <div className="new_login_bg newForgot position-relative">
        <img
          src={Authlogo}
          className="position-absolute w_12"
          alt="LOGO"
          style={{ top: "40px", left: "40px" }}
        />
        {/* <div
          style={{
            height: "calc(100vh - 149px)",
            overflow: "auto",
          }}
        > */}
        <div className="container d-flex flex-column justify-content-center vh-0 overflow-hidden">
          <div className="row">
            <div
              className="setup-size pt-lg-0 pt-md-0 pt-sm-5 pt-xsm-5 pt-5"
              style={{ zIndex: "1" }}
            >
              <div className="log-box py-3 px-4">
                <div className="d-flex flex-column my-4 gap-2 res-pass">
                  <h5 className="mb-0">{lang?.forgot_Password}!</h5>
                  <p>{lang?.new_forgetpassword_desc}</p>

                  <div className="d-flex flex-column mt-3 mb-2 gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <label>{lang?.email_address}</label>
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>
                    <div className="position-relative input-div">
                      <input
                        type="text"
                        placeholder={lang?.email_address}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <MdEmail
                        className="position-absolute"
                        style={{
                          top: "17px",
                          left: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                  </div>

                  <p className="text-danger text-center">{errorApi}</p>
                  <button
                    className="new_main_btn p-12 mb-lg-3"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-border text-white"
                          style={{ width: "1.35rem", height: "1.35rem" }}
                        ></span>
                      </div>
                    ) : (
                      lang?.new_send_otp
                    )}
                  </button>

                  <p className="text-center mt-2 mb-0">
                    {lang?.new_remember_password}
                  </p>
                  <Link to="/" className="text-center new_link">
                    {lang?.new_try_signing_again}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        <Modal
          isOpen={modal}
          toggle={() => setModal(true)}
          backdrop="static"
          size="md"
          centered
        >
          <ModalBody className="text-center mb-4 px-4 log-box">
            <h5 className="mt-4">
              {/* {lang?.new_account_verify}  */}
              Account Verification
            </h5>
            <p className="px-4 mb-1">
              {/* {lang?.new_account_verify_desc} */}
              A 6-digit OTP code has been issued and sent to the provided email address.
              </p>
            <p
              className="text-center"
              style={{ fontFamily: "NotoSans-medium", color: "#323F49" }}
            >
              {email}
            </p>

            <p className="text-start mt-5" style={{ color: "#323f49" }}>
              {/* {lang?.new_otp} */}
              OTP
            </p>
            <div className="otp-inputs d-flex align-items-center gap-2 mb-3">
              {values.map((value, index) => (
                <input
                  className="w-100 text-center"
                  key={index}
                  type="text"
                  maxLength={1}
                  value={value}
                  onChange={(event) => handleChange(index, event)}
                  onKeyDown={(event) => handleKeyPress(index, event)}
                  onPaste={handlePaste}
                />
              ))}
            </div>
            <p className="text-danger text-center">{errorOtp}</p>
            {errorOtpApi && (
              <p className="text-danger text-center">{errorOtpApi}</p>
            )}
            <div className="d-flex align-items-center gap-4 w-100 mt-5 mb-5">
              <button
                className="new_btn-2 text-white p-12 w-100"
                disabled={pauseCancel === false}
                onClick={() => {
                  setModal(false);
                  setValues(Array(6).fill(""));
                  setErrorOtp("");
                  setErrorOtpApi("");
                }}
              >
                {lang?.cancel}
              </button>
              <button
                className="new-btn-origin text-white p-12 w-100"
                onClick={SubmitOTP}
              >
                {loader ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className="spinner-border text-white"
                      style={{ width: "1.60rem", height: "1.60rem" }}
                    ></span>
                  </div>
                ) : (
                  // lang?.new_confirm
                  "Confirm"
                )}
              </button>
            </div>
            <p className="text-center mb-1">
              {/* {lang?.new_not_receive_otp} */}
              If you have not received OTP check “Spams”
              </p>
            <div
              onClick={() => {
                handleSubmit();
                setValues(Array(6).fill(""));
                setErrorOtp("");
              }}
              className="link-txt text-center text-decoration-underline"
              style={{ cursor: "pointer", fontFamily: "NotoSans-bold" }}
            >
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    className="spinner-border text-black"
                    style={{ width: "1.35rem", height: "1.35rem" }}
                  ></span>
                </div>
              ) : (
                // lang?.new_send_again
                "Send Again"
              )}
            </div>
          </ModalBody>
        </Modal>
        <NewAuthFooter position={"position-absolute"} />
      </div>
    </>
  );
}

export default NewForgotPassword;
