import InfiniteScroll from "react-infinite-scroller";
import CourseCard from "./CourseCard";
import { ShimmerPostItem } from "react-shimmer-effects";
import { fetchRecommendedCourses } from "../../services/coursesApi";
import { toast } from "react-toastify";
import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { Language } from "../../App";

export default function RecommendedCourses() {
  const {lang} = useContext(Language)
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["recommendedCourses"],
      queryFn: ({ pageParam = 1 }) =>
        fetchRecommendedCourses({ page: pageParam }),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage?.data?.current_page + 1;
        return nextPage <= lastPage?.data?.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const recommendedCourses = data?.pages?.flatMap((page) => page.data.data);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        <h4 className="mb-0 fs-20 roboto-bold">{lang.recomended_for_you}</h4>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (!isFetchingNextPage) {
            fetchNextPage();
          }
        }}
        hasMore={hasNextPage}
        loader={
          <div className="d-flex flex-wrap mt-4 gap-3 justify-content-between">
          {[1, 2, 3].flatMap((_, index) => (
                <div style={{ width: "280px" }} key={index}>
                  <ShimmerPostItem imageHeight={200} card title />
                </div>
              ))
            }
        </div>
          // <div style={{ width: "280px" }}>
          //   <ShimmerPostItem imageHeight={200} card title />
          // </div>
        }
      >
        <div className="d-flex flex-wrap gap-3 justify-content-between">
          {isLoading
            ? [1, 2, 3, 4, 5, 6].flatMap((_, index) => (
                <div style={{ width: "280px" }} key={index}>
                  <ShimmerPostItem imageHeight={200} card title />
                </div>
              ))
            : recommendedCourses?.flatMap((course, index) => (
                <CourseCard key={index} course={course} />
              ))}
        </div>
      </InfiniteScroll>
    </>
  );
}
