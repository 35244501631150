import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CloseTicketApi,
  GetTicketApi,
  TicketDetailChatApi,
  TicketSendMessageApi,
} from "../../constants/API";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { BsSendFill } from "react-icons/bs";
import SpecSpinner from "./changeInterest/SpecSpinner";
import { Language } from "../../App";
import { authorize } from "../../shared/authorize";

function Create_Complaint() {
  const { lang } = useContext(Language);
  const [activeTickets, setActiveTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [ticketChat, setTicketChat] = useState([]);
  const [showActive, setShowActive] = useState(true); // Initially show active tickets
  const localData = JSON.parse(localStorage.getItem("SplashData"));

  const login_id = localData?.user?.id;

  const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTicketLoading, setActiveTicketLoading] = useState(true);
  const [closeTicketLoading, setCloseTicketLoading] = useState(true);
  const [userMessage, setUserMessage] = useState([]);
  const [
    closeDetailsOffcanvasIsOpen,
    setCloseDetailsOffcanvasIsOpen,
  ] = useState(false);
  const [selectedCloseItem, setSelectedCloseItem] = useState(null);
  const lastMessageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingclose, seIsLoadingClose] = useState(false);

  useEffect(() => {
    if (offcanvasIsOpen && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth", // You can change this to "auto" for instant scrolling
      });
    }
  }, [offcanvasIsOpen, ticketChat]);

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault();

      // Call the function to send the message
      HandlemessageSend();
    }
  }

  const openOffcanvas = (item) => {
    setSelectedItem(item);
    setOffcanvasIsOpen(true);
    setLoading(true);
    axios
      .get(TicketDetailChatApi + item.id,authorize())
      .then((res) => {
        setTicketChat(res.data.data);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const openCloseDetailsOffcanvas = (item) => {
    setSelectedCloseItem(item);
    setCloseDetailsOffcanvasIsOpen(true);
    setLoading(true);

    axios
      .get(TicketDetailChatApi + item.id,authorize())
      .then((res) => {
        setTicketChat(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const closeOffcanvas = () => {
    setSelectedItem(null);
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOffcanvasIsOpen(false);
  };

  const HandlemessageSend = () => {
    // Check if the messageInput is not empty or only contains whitespace
    if (messageInput.trim() !== "") {
      setIsLoading(true);

      axios
        .post(TicketSendMessageApi, {
          to: 0,
          ticket_id: ticketId,
          message: messageInput,
        },authorize())
        .then((response) => {
          // Create a new message object
          const newMessage = {
            from: login_id,
            to: null,
            ticket_id: ticketId,
            message: messageInput,
          };

          // Append the new message to the existing ticketChat array
          setTicketChat((prevChat) => [...prevChat, newMessage]);
          setMessageInput("");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCloseTicket = () => {
    seIsLoadingClose(true);
    axios
      .get(CloseTicketApi + ticketId,authorize())
      .then((res) => {

        if (res.status) {
          seIsLoadingClose(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        seIsLoadingClose(false);
      });
    setIsModalOpen(false);
    setOffcanvasIsOpen(false);
  };

  useEffect(() => {
    // Fetch both active and closed tickets initially
    setActiveTicketLoading(true);
    axios
      .get(GetTicketApi + 0,authorize())
      .then((res) => {
        setActiveTickets(res.data.data);
        setActiveTicketLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setActiveTicketLoading(false);
      });

    setCloseTicketLoading(true);
    axios
      .get(GetTicketApi + 1,authorize())
      .then((res) => {
        setClosedTickets(res.data.data);
        setCloseTicketLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCloseTicketLoading(false);
      });
  }, [login_id]);

  const handleActiveButtonClick = () => {
    setShowActive(true);
  };

  const handleClosedButtonClick = () => {
    setShowActive(false);
  };

  return (
    <div>
      <div style={{ padding: "0" }}>
        <div className="create-complaint br-8 pxy-20 pb-15-rem bg-white w-xl-75 w-lg-100 m-xl-0 m-lg-auto m-md-auto m-sm-auto m-auto position-relative">
          <h3 className="d-flex justify-content-center mt-12 fs-20 fs-md-16 fw-bold">
            {lang?.complaints}
          </h3>
          <div className="d-flex justify-content-center box-shadow2 text-center br-10 mb-3 mt-4">
            <button
              className={`w-100 p-3 fs-15 fw-bold border-0 ${
                showActive ? "bg-blue text-white" : "bg-white"
              }`}
              style={{ borderRadius: "10px 0 0 10px" }}
              onClick={handleActiveButtonClick}
            >
              {lang?.active}
            </button>
            <button
              className={`w-100 p-3 fs-15 fw-bold border-0 ${
                !showActive ? "bg-blue text-white" : "bg-white"
              }`}
              style={{ borderRadius: "0 10px 10px 0" }}
              onClick={handleClosedButtonClick}
            >
              {lang?.closed}
            </button>
          </div>
          {showActive ? (
            activeTicketLoading ? (
              <div className="activeticketspinner">
                {" "}
                <SpecSpinner />{" "}
              </div>
            ) : activeTickets.length > 0 ? (
              activeTickets.map((item) => (
                <div
                  onClick={() => {
                    setTicketId(item.id);
                    openOffcanvas(item);
                  }}
                >
                  <div>
                    <div
                      key={item.id}
                      className="box-shadow1 p-2 mt-3"
                      style={{ borderRadius: "10px 10px 0 0" }}
                    >
                      <div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <h5 className="fs-16 fs-md-14">
                            {lang?.complaint_status}:
                          </h5>
                          <h6 className="fs-16 fs-md-14 text-success">
                            {lang?.active}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <h5 className="fs-16 fs-md-14">
                            {lang?.complaint_no}:
                          </h5>
                          <h6 className="fs-16 fs-md-14">#{item.id}</h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <h5 className="fs-16 fs-md-14">{lang?.date_time}:</h5>
                          <h6 className="fs-16 fs-md-14">{item.date_time}</h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <h5 className="fs-16 fs-md-14">
                            {lang?.complaint_title}:
                          </h5>
                          <h6 className="fs-16 fs-md-14">{item.type}</h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <h5 className="fs-16 fs-md-14 fw-bold activedescription">
                            {item.description}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="w-100  border-0 p-3  fs-18 fs-md-16"
                    style={{
                      borderRadius: "0 0 10px 10px",
                      backgroundColor: "#00C5DE",
                      color: "white",
                    }}
                  >
                    {lang?.click_view_details}
                  </button>
                </div>
              ))
            ) : (
              <p>{lang?.no_active_complaints_available}</p>
            )
          ) : closeTicketLoading ? (
            <div className="activeticketspinner">
              <SpecSpinner />
            </div>
          ) : closedTickets.length > 0 ? (
            closedTickets.map((item) => (
              <div onClick={() => openCloseDetailsOffcanvas(item)}>
                <div>
                  <div
                    key={item.id}
                    className="box-shadow1 p-2 mt-3"
                    style={{ borderRadius: "10px 10px 0 0" }}
                  >
                    <div>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        <h5 className="fs-16 fs-md-14">
                          {lang?.complaint_status}:
                        </h5>
                        <h6 className="fs-16 fs-md-14 text-red">
                          {lang?.close}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        <h5 className="fs-16 fs-md-14">
                          {lang?.complaint_no}:
                        </h5>
                        <h6 className="fs-16 fs-md-14">#{item.id}</h6>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        <h5 className="fs-16 fs-md-14">{lang?.date_time}:</h5>
                        <h6 className="fs-16 fs-md-14">{item.date_time}</h6>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        <h5 className="fs-16 fs-md-14">
                          {lang?.complaint_title}:
                        </h5>
                        <h6 className="fs-16 fs-md-14">{item.type}</h6>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        <h5 className="fs-16 fs-md-14 fw-bold">
                          {item.description}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="w-100 border-0 p-3 fs-18 fs-md-16"
                  style={{
                    borderRadius: "0 0 10px 10px",
                    backgroundColor: "#00C5DE",
                    color: "white",
                  }}
                >
                  {lang?.click_view_close_details}
                </button>
              </div>
            ))
          ) : (
            <p>{lang?.no_close_comp_available}</p>
          )}
        </div>
        <Offcanvas
          zIndex={9}
          style={{ width: "480px" }}
          direction="end"
          isOpen={offcanvasIsOpen}
        >
          <OffcanvasHeader toggle={closeOffcanvas}>
            {lang?.a_specialist_joined_chat}
          </OffcanvasHeader>
          <hr />
          <OffcanvasBody className="ticketchat scroll-hide">
            {loading ? (
              <div className="ticketchatspinner">
                <SpecSpinner />
              </div>
            ) : (
              ticketChat?.map((message, index) => (
                <div
                  key={message}
                  ref={index === ticketChat.length - 1 ? lastMessageRef : null}
                >
                  <div>
                    <div
                      className={
                        message.from === null ? "left-message" : "right-message"
                      }
                    >
                      <p className="m-0">{message.message}</p>
                    </div>
                    <p
                      className={
                        message.from === null ? "left-name" : "right-name"
                      }
                    >
                      {message.name}
                    </p>
                  </div>
                </div>
              ))
            )}

            <Row>
              <div className="d-flex position-absolute bottom-0 bg-white">
                <input
                  type="text"
                  placeholder={lang?.write_your_message}
                  className="form-control m-2 py-3"
                  value={messageInput}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <div className="align-self-center">
                  <button
                    type="button"
                    className="btn-blue border-0 ms-1 me-2 py-3 px-4"
                    onClick={HandlemessageSend}
                    disabled={!messageInput.trim() || isLoading} // Disable if messageInput is empty or isLoading is true
                  >
                    {isLoading ? (
                      <div>
                        <Spinner size={"sm"} />
                      </div>
                    ) : (
                      <BsSendFill size={20} />
                    )}
                  </button>
                </div>
              </div>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <Modal centered isOpen={isModalOpen} toggle={closeModal}>
        <ModalBody>
          <div>
            <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
              {lang?.do_want_close_chat}
            </h2>
            <p className="fs-18 fs-md-16 roboto-regular">
              {lang?.do_want_close_chat_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="bg-white text-dark border-1 border rounded w-100 py-2"
              onClick={closeModal}
            >
              {lang?.back}
            </button>

            <button
              className="btn-red border-0 rounded w-100 py-2"
              onClick={handleCloseTicket}
            >
              {isLoadingclose ? (
                <div>
                  <Spinner />
                </div>
              ) : (
                lang?.yes_close
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* close ticket */}
      <Offcanvas
        zIndex={9}
        style={{ width: "480px" }}
        direction="end"
        isOpen={closeDetailsOffcanvasIsOpen}
      >
        <OffcanvasHeader toggle={() => setCloseDetailsOffcanvasIsOpen(false)}>
          {lang?.a_specialist_joined_chat}
        </OffcanvasHeader>
        <hr />
        <OffcanvasBody className="ticketchat scroll-hide">
          {loading ? (
            <div className="ticketchatspinner">
              <SpecSpinner />
            </div>
          ) : (
            ticketChat.map((message) => (
              <div key={message}>
                <div>
                  <div
                    className={
                      message.from === null ? "left-message" : "right-message"
                    }
                  >
                    <p className="m-0">{message.message}</p>
                  </div>
                  <p
                    className={
                      message.from === null ? "left-name   " : "right-name"
                    }
                  >
                    {message.name}
                  </p>
                </div>
              </div>
            ))
          )}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
}

export default Create_Complaint;
