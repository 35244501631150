const BaseURL = "https://medicalradar.es/"; // Endpoint
const adminBaseURL = "https://admin.medicalradar.es/endpoint/" // new Endpoint
const imagesURL = " https://djoejj0gd5srt.cloudfront.net/" // new Endpoint
const _imagesURL = " https://djoejj0gd5srt.cloudfront.net" // new Endpoint

const EmailVErificationApi = `${adminBaseURL}user/auth/verify`;
// const EmailVErificationApi = `${BaseURL}api/user/verify`;
const SignupApi = `${adminBaseURL}user/auth/register`;
// const SignupApi = `${BaseURL}api/auth/register`;
const LoginApi = `${adminBaseURL}user/auth/login`;
// const LoginApi = `${BaseURL}api/user/auth`;
const GetSpecializationApi = `${adminBaseURL}app/subcategories/3`;
const PostSpecializationApi = `${BaseURL}api/user/profile/`;
const ForgotPasswordApi = `${adminBaseURL}user/auth/password/forgot`;
// const ForgotPasswordApi = `${BaseURL}api/auth/password/forgot`;
const OtpVerificationApi = `${adminBaseURL}user/auth/otp/verify`;
// const OtpVerificationApi = `${BaseURL}api/auth/otp/verify`;
const ChangePasswordApi = `${adminBaseURL}user/auth/password/reset`;
// const ChangePasswordApi = `${BaseURL}api/user/password/reset`;
const LogoutApi = `${adminBaseURL}user/auth/logout`;
// const LogoutApi = `${BaseURL}api/user/auth/logout`;
const ProfileApi = `${adminBaseURL}user/profile?page=`; // page param included

const JoinedMembersApi = `${adminBaseURL}user/event/joined/users/`; // {event_id}/{user_id}

const AllJobsApi = `${BaseURL}api/jobs/`; // User_id param included
const MyJobsApi = `${BaseURL}api/myjobs/`; // User_id param included
const SavedJobsApi = `${BaseURL}api/bookmarked/`; // User_id param included
const CreateJobApi = `${BaseURL}api/job/create/`; // User_id param included
const DeleteJobApi = `${BaseURL}api/job/delete/`; // job_id param included
const bookmarkJobApi = `${BaseURL}api/bookmark/job/`; // {job_Id}/{user_id} included
const ReportJobApi = `${BaseURL}api/report`; // {report_id},{user_id},{report},{type} included
const EditJobApi = `${BaseURL}api/job/update`; // {userid},{job_id},{title},{location},{hospital_name},{lat_lng},{description} included

const CalendarEventsApi = `${BaseURL}api/events/calendar`;
const SavedEventsApi = `${BaseURL}api/events/favorite/`; // user_id param included
const MyEventsApi = `${BaseURL}api/events/my/`; // user_id,staus param included

const AllCoursesApi = `${BaseURL}api/course/v1/home/`; //user_id param included
const MyCoursesApi = `${BaseURL}api/course/v1/my/`; // {userId}/{mId} included
const SavedCoursesApi = `${BaseURL}api/course/v1/saved/`; // {userId}/{mId} included
const PurchasedCoursesApi = `${BaseURL}api/course/v1/purchased/`; // {userId}/{mId} included
const DetailCourseApi = `${BaseURL}api/course/v1/detail/`; // course_id/user_id included
const GetPublishCourse = `${BaseURL}api/course/v1/publish/`; // course_id included
const PostPurchaseCourse = `${BaseURL}api/course/v1/purchase`; // course_id & user_id included
const SpecificCourseContentDetails = `${BaseURL}api/course/v1/media/content/`; // section_id & user_id
const SaveCourseApi = `${BaseURL}api/course/v1/save/`; // course_id & user_id
const DeleteCourseApi = `${BaseURL}api/course/v1/delete/`; // course_id
const ReportCourseApi = `${adminBaseURL}app/report`; // POST -> report, user_id, report_id & type:"course"
const UsersPurchasedCoursesApi = `${BaseURL}api/course/v1/users/purchased/`; // course_id & user_id

const GetCourseCertificatesListApi = `${BaseURL}api/course/v1/certificates/`; // course_id
const DeteleCourseCertificateApi = `${BaseURL}api/course/v1/certificate/delete/`; // certification_id

const AddCertificateApi = `${BaseURL}api/course/v1/certificate/create`; // course_id, title & media
const EditCertificateApi = `${BaseURL}api/course/v1/certificate/update`; // certification_id, title & media

const GetCourseSectionApi = `${BaseURL}api/course/v1/sections/`; // course_id included
const AddCourseSectionApi = `${BaseURL}api/course/v1/section/create`; // course_id, name & description
const EditCourseSectionApi = `${BaseURL}api/course/v1/section/update`; // section_id, name & description
const DeteleCourseSectionApi = `${BaseURL}api/course/v1/section/delete/`; // section_id

const AddCourseApi = `${BaseURL}api/course/v1/create`; // user_id, title, short_description, price, category, sub_category & cover
const EditCourseApi = `${BaseURL}api/course/v1/edit`; // course_id, cover, title, short_description, category & subcategory

const GetCourseSectionContentApi = `${BaseURL}api/course/v1/section/`; // section_id & user_id
const AddCourseSectionContentApi = `${BaseURL}api/course/v1/save/video`; // section_id, title & description
const EditCourseSectionContentApi = `${BaseURL}api/course/v1/update/video`; // video_id, title & description
const DeleteCourseSectionContentApi = `${BaseURL}api/course/v1/video/delete/`; // video_id

const SplashApi = `${adminBaseURL}user/web/splash`
// `${BaseURL}api/settings/`; // user_id param included + {type:all} in form

const RecomendationApi = `${adminBaseURL}user/suggested/list`; //  user_id

const FollowApi = `${adminBaseURL}user/follow/`; // other_user_id & user_id

const Followingapidata = `${adminBaseURL}user/following/`; //other_user id & user_id  followingapi
const Followersapidata = `${adminBaseURL}user/followers/`; //other_user id & user_id followersapi

const FeedPostApi = `${adminBaseURL}user/web/posts/`; // user_id & page

const LikeApi = `${BaseURL}api/like/`; // {postId}/{userId}
const SaveApi = `${BaseURL}api/post/save/`; // {postId}/{userId}
const ReserveApi = `${adminBaseURL}user/post/reserve/`; // {postId}/{userId}
const JoinWebinarApi = `${BaseURL}api/webinar/join/`; // {postId}/{userId}

const GetAllMessages = `${adminBaseURL}user/chat/all/messages`
// `${BaseURL}api/all/messages`; //1 to 1 all messages
const SendGroupchats = `${adminBaseURL}user/group-chat/messaging`
// `${BaseURL}api/group/messaging`; // group media and text chats send
const Sendindiviualchats = `${adminBaseURL}user/chat/messaging`
// `${BaseURL}api/messaging`; // 1 to 1 media and text chats send
const GroupAllMessages =`${adminBaseURL}user/group-chat/all/messages` 
// `${BaseURL}api/group/all/messages`; // group all messages
const AllInboxConversation = `${BaseURL}api/conversation`; // all inbox concersation
const UserUnreadCounts = `${BaseURL}api/message/read`; // 1 to 1 unread count
const GroupUnreadCounts = `${BaseURL}api/group/message/read`; // group unread count

const AddEventApi = `${adminBaseURL}user/event/create`;
const EditEventApi = `${adminBaseURL}user/event/update`;

const EnrolEventApi = `${adminBaseURL}user/event/join`;

const AddEventQuestionApi = `${adminBaseURL}user/event/questions/add`;

const joinLeaveWebinarApi = `${BaseURL}api/webinar/live/join/`; // {id}/{user}/{status}

const endWebinarApi = `${BaseURL}api/webinar/exit/`; // {postId}

const RepostApi = `${adminBaseURL}user/post/repost/`; // {postid}/{userid}

const GetCommentsApi = `${adminBaseURL}user/post/comments/`; // {postid}/{userid}
const GetRepliesApi = `${adminBaseURL}user/post/comment/replies/`; // {postid}/{userid}
const AddCommentApi = `${adminBaseURL}user/post/comment/create`; // {postid}/{userid}
const DeleteCommentApi = `${adminBaseURL}user/post/comment/delete/`; // {comment_id}/delete
const AddCommentRepliesApi = `${adminBaseURL}user/post/comment/create`; // {post_id}/{user_id}

const EditProfileInfoApi = `${adminBaseURL}user/profile/update`; // {user_id}

const DeleteAccountApi = `${adminBaseURL}user/auth/delete`; // {user_id}
// const DeleteAccountApi = `${BaseURL}api/user/delete/`; // {user_id}
const PasswordChangeApi = `${adminBaseURL}user/auth/password/change`;
// const PasswordChangeApi = `${BaseURL}api/user/password/change`;

const getNotificationsApi = `${adminBaseURL}user/notifications`; // {user_id}

const GetSubcategories = `${adminBaseURL}app/subcategories/3`; // category_id = 3

const GlobalSearchApi = `${adminBaseURL}user/global/search`;

const GetBlockList = `${adminBaseURL}user/blocked`; //{user_id}
const BlockUnblockUserApi = `${adminBaseURL}user/block/`; //{user_id}/{login_id}
const saveEventApi = `${adminBaseURL}user/event/favorite/`; // {user_id}
// const EventDetailApi = `${BaseURL}api/events/detail/`; // {event_id}/{user_id}
const EventDetailApi = `${adminBaseURL}user/event/detail/`; // {event_id}/{user_id}
const deleteEventApi = `${adminBaseURL}user/event/delete/`; // {event_id}

const GenerateHelpTicketApi = `${adminBaseURL}app/ticket/create`; // {user_id}, {type}, {description}
const GetTicketApi = `${adminBaseURL}app/ticket/list/`; // /{user_id}/{type}
const CloseTicketApi = `${adminBaseURL}app/ticket/close/`; // /{ticket_id}
const TicketDetailChatApi = `${adminBaseURL}app/ticket/detail/`; // /{ticket_id}
const TicketSendMessageApi = `${adminBaseURL}user/chat/messaging`; // /{from},{to},{ticket_id},{message}

const DeleteQuestionApi = `${adminBaseURL}user/event/questions/delete/`;

const RemoveProfilePicApi = `${adminBaseURL}user/profile-picture/delete`; // {user_id}

const EditEventQuestionsApi = `${adminBaseURL}user/event/questions/update`;

// const AddClinicalStudies = `${BaseURL}api/post/create/`; // {user_id}
const AddClinicalStudies = `${adminBaseURL}user/post/create`; // {user_id}
// const AddSurvay = `${BaseURL}api/post/add/survey`;
const AddSurvay = `${adminBaseURL}user/post/clinical-study/add/survey`;
const CountApi = `${BaseURL}api/counts/`; // {user_id}

const DeletePostApi = `${adminBaseURL}user/post/delete/`; // {post_id}/delete

const EditPostApi = `${adminBaseURL}user/post/update`;

const LiveWebinarApi = `${adminBaseURL}user/post/webinar/started`; // user_id
const UsersWebinars = `${BaseURL}api/my/webinars/`; // user_id
const ClinicalStudiesUsers = `${BaseURL}api/my/clinical/`; // user_id   /  login_id
const UsersPostsPhoto = `${BaseURL}api/user/profile`; // user_id   /  login_id /  page
const UserPolls = `${BaseURL}api/my/polls/`; // user_id   /  login_id

const JoinedEventsApi = `${BaseURL}api/events/joinedEvent/`;

const ReadNotificationsApi = `${adminBaseURL}user/notifications/read`; // {user_id}
const GetGroupParticipants = `${adminBaseURL}user/users`; // {user_id}
const CreateGroupApi = `${BaseURL}api/group/create`; // {group},{user},{users[]}
const GroupImageApi = `${BaseURL}api/group/update/`; // {group_id}
const GroupNotificationStatusApi = `${BaseURL}api/group/mute/`; // {group_id}/ {login_id}
const GroupMediaApi = `${BaseURL}api/group/conversation/media/`; // {group_id}
const GroupParticipantsApi = `${BaseURL}api/group/conversation/users/`; // {group_id}/ {user_id}
const AddGroupParticipantsApi = `${BaseURL}api/user/group/users/`; // {group_id}/ {user_id}
const InviteGroupMemebr = `${BaseURL}api/user/group/member/add/`; // {group_id}/ {target_user_id}
const GroupSearchParticipantsApi = `${BaseURL}api/user/group/search`; // {keyword} {group_id}/ {user_id}
const GroupGlobalSearchApi = `${adminBaseURL}user/global/search`; // {keyword}/ {type="user"}/ {user_id}

const ClinicalSessionsInvitedUsersApi = `${BaseURL}api/clinical-session/invited/users/`; // {post_id}/user_id
const ClinicalSessionsInviteUsersListApi = `${BaseURL}api/clinical-session/users/`; // {post_id}/user_id
const ClinicalSessionsInviteUserApi = `${BaseURL}api/clinical-session/invite/`; // {post_id}/target_user_id

const PostDetailsApi = `${adminBaseURL}user/post/detail/`; // {post_id}/{user_id}

const ClinicalSessionUserSearchApi = `${BaseURL}api/clinical-session/user/search`;

export {
  PostDetailsApi,
  ClinicalSessionUserSearchApi,
  ClinicalSessionsInviteUserApi,
  ClinicalSessionsInviteUsersListApi,
  ClinicalSessionsInvitedUsersApi,
  ReadNotificationsApi,
  InviteGroupMemebr,
  JoinedEventsApi,
  GroupSearchParticipantsApi,
  GroupParticipantsApi,
  GroupGlobalSearchApi,
  AddGroupParticipantsApi,
  GroupImageApi,
  GroupMediaApi,
  GroupNotificationStatusApi,
  GetGroupParticipants,
  LiveWebinarApi,
  CreateGroupApi,
  EditPostApi,
  ClinicalStudiesUsers,
  UsersPostsPhoto,
  UserPolls,
  DeletePostApi,
  UsersWebinars,
  CountApi,
  AddSurvay,
  AddClinicalStudies,
  EditEventQuestionsApi,
  DeleteQuestionApi,
  EditJobApi,
  EnrolEventApi,
  DeleteJobApi,
  RemoveProfilePicApi,
  deleteEventApi,
  EventDetailApi,
  bookmarkJobApi,
  TicketDetailChatApi,
  TicketSendMessageApi,
  CreateJobApi,
  saveEventApi,
  ReportJobApi,
  CloseTicketApi,
  GetTicketApi,
  GlobalSearchApi,
  getNotificationsApi,
  GenerateHelpTicketApi,
  BlockUnblockUserApi,
  AddCommentRepliesApi,
  GetBlockList,
  GetSubcategories,
  JoinedMembersApi,
  DeleteAccountApi,
  PasswordChangeApi,
  GetRepliesApi,
  EditProfileInfoApi,
  endWebinarApi,
  DeleteCommentApi,
  GetCommentsApi,
  AddCommentApi,
  RepostApi,
  joinLeaveWebinarApi,
  BaseURL,
  adminBaseURL,
  SplashApi,
  Followersapidata,
  GroupUnreadCounts,
  AllInboxConversation,
  RecomendationApi,
  AllCoursesApi,
  UserUnreadCounts,
  SendGroupchats,
  GroupAllMessages,
  Sendindiviualchats,
  MyEventsApi,
  CalendarEventsApi,
  SavedEventsApi,
  MyJobsApi,
  SavedJobsApi,
  AllJobsApi,
  Followingapidata,
  ProfileApi,
  LogoutApi,
  ChangePasswordApi,
  OtpVerificationApi,
  ForgotPasswordApi,
  EmailVErificationApi,
  SignupApi,
  LoginApi,
  GetSpecializationApi,
  PostSpecializationApi,
  MyCoursesApi,
  SavedCoursesApi,
  PurchasedCoursesApi,
  DetailCourseApi,
  GetPublishCourse,
  PostPurchaseCourse,
  SpecificCourseContentDetails,
  SaveCourseApi,
  DeleteCourseApi,
  ReportCourseApi,
  UsersPurchasedCoursesApi,
  GetCourseCertificatesListApi,
  DeteleCourseCertificateApi,
  AddCertificateApi,
  EditCertificateApi,
  GetCourseSectionApi,
  AddCourseSectionApi,
  EditCourseSectionApi,
  DeteleCourseSectionApi,
  EditCourseApi,
  AddCourseApi,
  GetCourseSectionContentApi,
  AddCourseSectionContentApi,
  EditCourseSectionContentApi,
  DeleteCourseSectionContentApi,
  FeedPostApi,
  FollowApi,
  LikeApi,
  SaveApi,
  GetAllMessages,
  ReserveApi,
  JoinWebinarApi,
  AddEventApi,
  AddEventQuestionApi,
  EditEventApi,
  imagesURL,
  _imagesURL
};
