import React, { useContext } from 'react'
import { ChatsContext } from '../../components/chat/ChatsProvider';
import { ShimmerBadge } from 'react-shimmer-effects';
import { Language } from '../../App';

const ChatStartHeading = () => {
  const { loading} = useContext(ChatsContext);
  const{lang} = useContext(Language) 

  return (
    <div style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center",display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%" }}>
         {loading?  <ShimmerBadge width={500} />
        :lang.select_conversation_display_messages}
      </div>
  )
}

export default ChatStartHeading