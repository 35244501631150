import React from "react";
import { fetchClinicalSessionData } from "../../services/coursesApi";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import ClinicalSessionCard from "./ClinicalSessionCard";
import ShimmerClinicalSessionCard from "./ShimmerClinicalSessionCard";

const MySessions = ({ count }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["myclinicalSessionData"],
    queryFn: () => fetchClinicalSessionData({ type: "my" }),
    refetchOnWindowFocus: false, 
  });

  if (isError) {
    toast.error("Error fetching Clinical Session data");
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-wrap justify-content-start gap-3">
          {new Array(count).fill(0).map((_, index) => (
            <div className="clinical_card" key={index}>
             <ShimmerClinicalSessionCard/>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-2">
          <div className="d-flex flex-wrap justify-content-start gap-3">
            { data?.data.length>0
            &&data?.data?.map((session, index) => (
              <div key={index}  className="clinical_card">
                <ClinicalSessionCard session={session} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MySessions;



