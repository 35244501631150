import { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoginUserData } from "../../App";
import Pusher from "pusher-js";
import { adminBaseURL } from "../../constants/API";
import { getToken } from "../../shared/token";


export const ChatsContext = createContext();

export function ChatsProvider({ children }) {
  const { splashData } = useContext(LoginUserData);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pusherCheck, setPusherCheck] = useState(false);
//   const {   error, fetchChatss } = useFetchChats(splashData?.user?.id);
  const fetchChats = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${adminBaseURL}user/chat/conversation`
      ,{
        headers:{
          Authorization: `Bearer ${getToken()}`
        }
      });
      if (response.data.status === true) {
        setChats(response.data.data);
       
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching chats. Please try again.");
    } finally {
      setLoading(false);
    }
  };

const updateChatMessage = (newMessage, chatId) => {
    console.log("newMessage", newMessage); // Debugging log
    setChats((prevChats) => {
      const updatedChats = prevChats.map((chat) => {
        if (chat.id === chatId) {
          return {
            ...chat,
            message: newMessage.text,  // Ensure you are updating the text correctly
            time: newMessage.time,      // Update the timestamp if necessary
          };
        }
        return chat;
      });
  
      return [...updatedChats];
    });
  };
  
  const fetchChatss = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${adminBaseURL}user/chat/conversation`
      ,{
        headers:{
          Authorization: `Bearer ${getToken()}`
        }
      });
      if (response.data.status === true) {
        setChats(response.data.data);
       
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching chats. Please try again.");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
    });
    let channel
    // if(splashData===null){
        channel =
       pusher.subscribe(String(`inbox-${splashData?.user.id}`))
 
        channel.bind("inbox-update", function (data) {
                 
            fetchChatss()
        
        });
    return () => {
      pusher.unsubscribe(String(`inbox-${splashData?.user.id}`));
      setPusherCheck(false)
    };
  }, [splashData.user.id]);

  return (
    <ChatsContext.Provider value={{ chats,setChats, loading, fetchChatss, updateChatMessage }}>
      {children}
    </ChatsContext.Provider>
  );
}
