import React from "react";
import { ShimmerButton, ShimmerBadge } from "react-shimmer-effects";

const AvailableShimmer = () => {
  return (
    <>
      <div className="availableShimmer">
        <div
          style={{ minHeight: "120px" }}
          className="j-c-c flex-column postion-relative gap-2 p-4"
        >
          <p className="m-0">
            <ShimmerBadge width={300} />
          </p>
          <h4 className="m-0">
            <ShimmerBadge width={200} />
          </h4>
        </div>
        <div className="available-btn py-2">

          <div className="w-50 j-c-c ">
            
            <ShimmerButton size="md" />
          </div>
          <div className="w-50 j-c-c">
            <ShimmerButton size="md" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableShimmer;
