import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Modal, ModalHeader, Row } from "reactstrap";
import GroupAvatar from "../../assets/images/icon/group_avatar.png";
import { Language } from "../../App";
import { HiPlus } from "react-icons/hi";
import { toast } from "react-toastify";
import { adminBaseURL } from "../../constants/API";
import { getToken } from "../../shared/token";
import axios from "axios";
import { selectedChatContext } from "../../pages/chat/ChatModule";
import { ChatsContext } from "./ChatsProvider";

const EditGroupInfo = ({ isOpen, toggle, currentName, currentImage, currentId }) => {
  const { selectedChat,setSelectedChat } = useContext(selectedChatContext);
  const { fetchChatss } = useContext(ChatsContext);
  
  const { lang } = useContext(Language);
  const [groupImage, setGroupImage] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [isImageModified, setIsImageModified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Set initial values for groupName and groupImage when component mounts or currentName changes
  useEffect(() => {
    setGroupName(currentName || "");
    setGroupImage(currentImage || null);
    setIsImageModified(false); // Reset when modal opens
  }, [currentName, currentImage]);

  const handleGroupImage = async () => {
    try {
      const formData = new FormData();
      formData.append("group_id", currentId);
      formData.append("group_name", groupName);
      formData.append("image", groupImage);

     const res= await axios.post(`${adminBaseURL}user/group-chat/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.data.status) {
        setSelectedChat({...selectedChat,group_name:groupName,image:res.data?.data?.image})
        fetchChatss()
        toggle();
        toast.success("Group updated successfully");
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error("Failed to upload group image");
    }
  };

  const handleEditGroup = async () => {
    setIsLoading(true); // Disable button during API call
    try {
      if (isImageModified && groupImage) {
        // Upload the image only if it has been modified
        await handleGroupImage();
      }else{
      
      // Update group info
      const res = await axios.post(
        `${adminBaseURL}user/group-chat/update`,
        {
          group_name: groupName,
          group_id: currentId,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (res.data.status) {
        setSelectedChat({...selectedChat,group_name:groupName,})
        fetchChatss()
        toggle();
        toast.success("Group updated successfully");
      } else {
        toast.error(res?.data?.message);
      }
    }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsLoading(false); // Re-enable button after API call
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3 className="mb-0 roboto-bold fs-20 fs-md-16">{lang.update_group_info}</h3>
      </ModalHeader>
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={12}>
            <div className="edit-profile br-8 bg-white p-3">
              <div className="d-flex justify-content-center mt-4">
                <div className="profile_img position-relative">
                  <img
                    src={
                      groupImage instanceof Blob
                        ? URL.createObjectURL(groupImage)
                        : groupImage || GroupAvatar
                    }
                    alt="Group"
                    id="profileimage"
                  />
                  <input
                    type="file"
                    id="media"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => {
                      setGroupImage(e.target.files[0]);
                      setIsImageModified(true); // Mark image as modified
                    }}
                  />
                  <label className="plus manue-hover br-8" htmlFor="media">
                    <HiPlus color="black" size={25} />
                  </label>
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-4">
                <div>
                  <h6 className="fs-16 fw-bold opacity-75">
                    {lang?.group_name}
                  </h6>
                  <input
                    type="text"
                    placeholder={lang?.type_group_name_here}
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-3">
                <button
                  className="btn-blue border-0 px-4 py-2"
                  disabled={!groupName || isLoading} // Disable when loading or if no name
                  onClick={handleEditGroup}
                >
                  {isLoading ? lang.updating : lang.update}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default EditGroupInfo;

