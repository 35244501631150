import axios from 'axios';
import { SplashApi } from '../constants/API';
import { authorize } from '../shared/authorize';


export const api = axios.create({
    baseURL: 
    // 'https://admin.medicalradar.es/endpoint/'
    'https://medicalradar.es/',
});
export const apiAdmin = axios.create({
    baseURL: 
    'https://admin.medicalradar.es/endpoint/'
    // 'https://medicalradar.es/',
});

export const getSplashData = () => {
    const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    return apiAdmin.get(SplashApi,{
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is passed correctly
        },
      });
};
