
import { useContext, useState } from "react";
import CreateEventModal from "./CreateEventModal";
import { Language } from "../../../App";

export default function CreateEventCard() {
  const [isOpen, setIsOpen] = useState(false);
  const {lang} = useContext(Language) 
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <div
        className="d-flex flex-row align-items-start justify-content-start mb-3"
        style={{ width: "335px" }}
      >
        <section className="flex-grow-1 w-100 text-white fs-16 roboto-bold shadow-sm rounded bg-white border border-inherit p-0 pb-3 d-flex flex-column align-items-start justify-content-start gap-3 text-center">
          <div className="w-100 rounded-top bg-dark text-white d-flex flex-row align-items-start justify-content-center p-4">
            <h5 className="roboto-medium fs-16 text-white mb-0">
             {lang.create_new_event}
            </h5>
          </div>
          <div className="w-100 d-flex flex-row align-items-start justify-content-start px-5 fs-14 text-black">
            <div
              className="flex-grow-1 roboto-medium"
              style={{ lineHeight: "20px" }}
            >
              {lang.create_new_event_para}
            </div>
          </div>
          <div className="w-100 d-flex flex-row align-items-start justify-content-start px-3 fs-12">
            <button
              onClick={toggle}
              style={{ width: "96px" }}
              className="flex-grow-1 rounded border-0 text-white align-items-center bg-info d-flex flex-row align-items-start justify-content-center p-2"
            >
              {lang.create_event}
            </button>
          </div>
        </section>
      </div>
     {isOpen&& <CreateEventModal isOpen={isOpen} toggle={toggle} />}
    </>
  );
}












