import { createContext, useContext, useEffect, useState } from "react";
import ChatUserCard from "./ChatUserCard";
import { Language, LoginUserData } from "../../App";
import { BiMessageAdd } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import CreateGroupModal from "./CreateGroupModal";
import { ChatsContext } from "./ChatsProvider";
import SearchChatUsersModal from "./SearchChatUsersModal";
import { selectedChatContext } from "../../pages/chat/ChatModule";


export default function ChatsSideBar() {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const { chats, fetchChatss, loading ,updateChatMessage} = useContext(ChatsContext); 
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { selectedChat,setSelectedChat } = useContext(selectedChatContext);


  return (
    <ChatsContext.Provider value={{ fetchChatss}}>
    <>
     {isCreateGroupModalOpen&& <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        toggle={() => setIsCreateGroupModalOpen(!isCreateGroupModalOpen)
          
        }
        fetchChatss={fetchChatss}
      />}
      <div className="border border-inherit overflow-y-auto scroll-hide" style={{ maxHeight: "calc(100vh - 53px)" }}>
        <div
          className="p-3 border-bottom border-inherit d-flex align-items-center justify-content-between bg-white"
          style={{ zIndex: "2" }}
        >
          <h4 className="fs-20 roboto-bold mb-0"> {lang?.all_messages}</h4>
          <div className="d-flex align-items-center gap-3">
            <CiSearch size={25} className="cursor" title="New chat" onClick={toggle} />
            <BiMessageAdd
              size={25}
              onClick={() => setIsCreateGroupModalOpen(true)}
              color="#1Dc1dd"
              className="cursor"
              title="Add new group chat"
            />
          </div>
        </div>
        <div style={{ height: "100vh" }}>
          {loading ? (
         Array(chats?.length || 10).fill().map((_, index) => (
  <ShimmerCategoryItem
    key={index}
    hasImage
    imageType="circular"
    imageWidth={56}
    imageHeight={56}
    title
  />
))
          ) : (
            chats.map((chat, index) => <ChatUserCard key={index} chat={chat} fetchChats={fetchChatss} />)
          )}
        </div>
      </div>
      {isOpen&& <SearchChatUsersModal isOpen={isOpen} toggle={toggle} setSelectedChat={setSelectedChat} />}
      </>
     </ChatsContext.Provider>
  );
}
