import React, { useEffect, useState, useRef, useContext } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN } from "../../../constants/RoutesUrl";
import Pick from "../../../assets/images/icon/image.png";
import axios from "axios";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
  adminBaseURL,
  BaseURL,
  EmailVErificationApi,
} from "../../../constants/API";
import user from "../../../assets/images/auth/login_email.svg";
import lock from "../../../assets/images/auth/lock.svg";
import email_icon from "../../../assets/images/auth/login_email.svg";
import { CiLocationOn } from "react-icons/ci";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaHospitalAlt } from "react-icons/fa";
import Authlogo from "../../../assets/images/auth/newAuthLogo.svg";
import pending from "../../../assets/images/auth/pending.svg";
import { Language } from "../../../App";

function Hospital() {
  const { lang } = useContext(Language);
  const [formData, setFormData] = useState({
    fullName: "",
    userName: "",
    email: "",
    password: "",
    country: "",
    city: "",
  });
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [fetchingCities, setFetchingCities] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<FaRegEye />);
  const [modalPending, setModalPending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    // Scroll to top when the page is loaded
    window.scrollTo(0, 0);
  }, []);

  const options = ["Hospital", "Private Clinic", "Societies"];
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const countriesData = localStorage.getItem("countriesData");
    if (countriesData) {
      setCountries(JSON.parse(countriesData));
    }
  }, []);

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(type === "password" ? <FaRegEyeSlash /> : <FaRegEye />);
  };

  const fetchCities = (country) => {
    setFetchingCities(true);
    const url = adminBaseURL + "app/country/" + country;

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log("Response data:", data);
        setCities(data.data);
      })
      .catch((err) => console.error("Fetch error:", err))
      .finally(() => setFetchingCities(false));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = lang?.new_hospital_full_name;
    }

    // FullName Validation
    // const fullNameRegex = /^[A-Z][a-zA-Z\s]{0,29}$/;

    // if (formData.fullName === "") {
    //   newErrors.fullName = "Enter hospital full name.";
    // }

    // if (!fullNameRegex.test(formData.fullName)) {
    //   newErrors.fullName(lang?.fullname_formate);
    // }

    // UserName Validation Code
    const usernameRegex = /^[a-zA-Z0-9_]{0,30}$/;
    if (formData.userName === "") {
      newErrors.userName = lang?.new_hospital_user_name;
    }
    if (!usernameRegex.test(formData.userName)) {
      newErrors.userName = lang?.username_formate;
    }

    // if (!formData.userName.trim()) {
    //   newErrors.userName = "Enter hospital username.";
    // }
    if (!formData.email.trim()) {
      newErrors.email = lang?.new_emailaddress_valid;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = lang?.please_enter_valid_email;
    }

    if (formData.password === "") {
      newErrors.password = lang?.new_password_valid;
    } else if (formData.password.length < 6) {
      newErrors.password = lang?.password_should_least_6char_long;
    } else if (formData.password.length > 30) {
      newErrors.password = lang?.password_should_not_exced_30_char;
    } else if (/\s/.test(formData.password)) {
      newErrors.password = lang?.space_not_allowed_pass;
    }
    if (!formData.country) {
      newErrors.country = lang?.new_selectcountry_valid;
    }
    if (!formData.city) {
      newErrors.city = lang?.new_selectcity_valid;
    }
    // Selected Option Validation
    if (!selectedOption) {
      newErrors.type = lang?.select_account_type;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setApiError("")

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const params = {
        name: formData.fullName,
        user_name: formData.userName,
        sub_type: formData.selectedOption,
        email: formData.email,
        password: formData.password,
        country: formData.country,
        city: formData.city,
        user_type: "hospital",
        device: "website",
        device_name: "web_abc",
        token: "web_abc",
        timezone: timeZone,
      };

      try {
        const response = await axios.post(
          adminBaseURL + "user/auth/register",
          params,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Form submitted successfully:", response.data);

        if (response.data.status) {
          setModalPending(true); // Open success modal if the submission is successful
        } else {
          setApiError(
            response.data.action ||
              "An error occurred while submitting the form."
          );
        }
      } catch (error) {
        console.error(
          "Error submitting form:",
          error.response?.data || error.message
        );
        setApiError(error.response?.data.errors[0]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        className="inst-box"
        isOpen={modalPending}
        centered
        toggle={() => setModalPending(true)}
      >
        <ModalBody className="px-4">
          <div className="d-flex flex-column justify-content-center">
            <img
              style={{ objectFit: "cover", width: "65px" }}
              src={pending}
              alt="PENDING"
            />
            <p className="mb-4 mt-4" style={{ fontFamily: "NotoSans-bold" }}>
              Approval Pending!
            </p>
            <p style={{ fontFamily: "NotoSans-medium", fontSize: "17px" }}>
              Your request to join is under review. Admin approval may take up
              to 24 hours. Please wait before attempting to sign in again.
            </p>
          </div>
        </ModalBody>
        <Link
          to="/"
          onClick={() => setModalPending(false)}
          style={{ cursor: "pointer" }}
          className=" text-decoration-none text-black"
        >
          <ModalFooter className="d-flex flex-column align-items-center justify-content-center">
            <button
              className="new_main_btn w-100 py-3"
              style={{ fontFamily: "NotoSans-bold" }}
            >
              Okay
            </button>
          </ModalFooter>
        </Link>
      </Modal>

      <div className="container ">
        <div className="row">
          <div className="d-flex flex-column justify-content-center">
            <div
              className="setup-size pb-lg-0 pb-md-0 pb-sm-5 pb-xsm-5 pb-5"
              style={{ paddingTop: "75px" }}
            >
              <div className="log-box py-3 px-4">
                <div className="d-flex flex-column my-4 gap-2">
                  <h5 className="mb-0">{lang?.welcome_to} Medical Radar!</h5>
                  <p>{lang?.please_sign_up_account}</p>

                  <form onSubmit={handleSubmit} className="res-pass">
                    <div className="d-flex flex-column mb-2 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label htmlFor="category">{lang?.new_account}</label>
                        {errors.type && (
                          <small className="text-danger">{errors.type}</small>
                        )}
                      </div>
                      <div
                        className="dropdown position-relative"
                        ref={dropdownRef}
                      >
                        <img
                          src={email_icon}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "11px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="SECTOR"
                        />
                        <button
                          className="d-flex align-items-center justify-content-between w-100 btn-dropdown"
                          type="button"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          {selectedOption || `${lang?.select_account_type}`}
                          {isOpen ? (
                            <FaChevronUp className="me-2" />
                          ) : (
                            <FaChevronDown className="me-2" />
                          )}
                        </button>

                        {isOpen && (
                          <ul className="dropdown-menu show w-100">
                            {options.map((option, index) => (
                              <li key={index}>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    color: "#000",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = "#00c5de";
                                    e.target.style.color = "#fff";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = "#ffffff";
                                    e.target.style.color = "#000";
                                  }}
                                  onClick={() => handleSelect(option)}
                                >
                                  {option}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_fullname}</label>
                        {errors.fullName && (
                          <small className="text-danger">
                            {errors.fullName}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.new_hospital_full_name}
                          value={formData.fullName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              fullName: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={user}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="USER"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_username}</label>
                        {errors.userName && (
                          <small className="text-danger">
                            {errors.userName}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.new_hospital_user_name}
                          value={formData.userName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              userName: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={user}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="USER"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.email_address}</label>
                        {errors.email && (
                          <small className="text-danger">{errors.email}</small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.support_medicalradar_com}
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                          }}
                        />
                        <MdEmail
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "16px",
                            height: "16px",
                            opacity: "0.7",
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_pass}</label>
                        {errors.password && (
                          <small className="text-danger">
                            {errors.password}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type={type}
                          placeholder={lang?.enter_password}
                          value={formData.password}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={lock}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="LOCK"
                        />
                        <span
                          className="position-absolute"
                          style={{
                            right: "11px",
                            top: "15px",
                            color: "#7991A4",
                          }}
                          onClick={handleToggle}
                        >
                          {icon}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_country}</label>
                        {errors.country && (
                          <small className="text-danger">
                            {errors.country}
                          </small>
                        )}
                      </div>
                      <div className="position-relative">
                        <CiLocationOn
                          className="position-absolute input-icon"
                          size={20}
                        />
                        <select
                          style={{
                            padding: "13px 8px 13px 35px",
                            borderRadius: "10px",
                          }}
                          className="form-control"
                          onChange={(e) => {
                            const selectedCountry = e.target.value;
                            setFormData({
                              ...formData,
                              country: e.target.value,
                              city: "", 
                            });
                            fetchCities(selectedCountry);
                          }}
                        >
                          <option value="" disabled selected>
                            {lang?.select_country}
                          </option>
                          {countries.length === 0 && (
                            <option value="" disabled>
                              <h6>Loading ...</h6>
                            </option>
                          )}
                          {countries.map((value) => (
                            <option value={value.id}>{value.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {formData.country && (
                      <div className="d-flex flex-column mb-3 gap-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <label>{lang?.new_city}</label>
                          {errors.city && (
                            <small className="text-danger">{errors.city}</small>
                          )}
                        </div>
                        <div className="position-relative">
                          <CiLocationOn
                            className="position-absolute input-icon"
                            size={20}
                          />
                          <select
                            // onClick={() => fetchCities}
                            style={{
                              padding: "13px 8px 13px 35px",
                              borderRadius: "10px",
                            }}
                            className="form-control"
                            value={formData.city}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                city: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              {lang?.select_city}
                            </option>
                            {fetchingCities && (
                              <option value="" disabled>
                                <h6>Loading ...</h6>
                              </option>
                            )}
                            {!fetchingCities && cities.length === 0 && (
                              <option value="" disabled>
                                <h6>No city Found</h6>
                              </option>
                            )}
                            {cities.map((value) => (
                              <option value={value.id}>{value.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    <p className="text-center text-danger">{apiError}</p>
                    <button
                      className="new_main_btn w-100 p-12 mb-lg-3 mb-md-2 mb-sm-2 mb-1"
                      type="submit"
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <span
                            className="spinner-border text-white"
                            style={{ width: "1.35rem", height: "1.35rem" }}
                          ></span>
                        </div>
                      ) : (
                        lang?.new_save_continue
                      )}
                    </button>

                    <p className="text-center mt-2 mb-0">
                      {lang?.already_account}
                      <Link
                        to="/"
                        className=" ms-1 text-center new_link"
                      >
                        {lang?.login}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hospital;
