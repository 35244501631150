

import React, { useContext, useEffect, useState } from 'react';
import ImageComponent from "../common/ImageComponent";
import { IoIosMore } from "react-icons/io";
import { _imagesURL } from '../../constants/API';
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { useNavigate } from 'react-router-dom';
import { Language, PostDetailContext } from '../../App';
import { ShimmerBadge, ShimmerCircularImage, ShimmerTitle } from "react-shimmer-effects";


const containerStyles = {
  width: "335px",
  height: "649px",
  borderRadius: "8px",
  boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.05)",
  border: "0.5px solid rgba(0,0,0,0.25)",
  backgroundColor: "white"
};

export default function LiveFeeds({ leftSideFeeds ,isLoading}) {
  const [loader, setLoader] = useState(false)
  const [sectionsData, setSectionsData] = useState("")
  const {lang} = useContext(Language)
  const categoriesConfig = {
    [lang.live_webinars]: {
        id: 1,
        name: lang.live_webinars,
        color: "rgba(255, 149, 0, 1)",
        type: 'webinar',
        isLive: true,
    },
    [lang.live_clinical_sessions]: {
        id: 2,
        name: lang.live_clinical_sessions,
        color: "rgba(255, 59, 48, 1)",
        type: 'clinical-session',
        isLive: true,
    },
    [lang.upcoming_webinars]: {
        id: 3,
        name: lang?.upcoming_webinars,
        color: "rgba(255, 149, 0, 1)",
        type: 'webinar',
        isLive: false,
    },
    [lang.upcoming_slinical_sessions]: {
        id: 4,
        name: lang.upcoming_clinical_sessions,
        color: "rgba(255, 59, 48, 1)",
        type: 'clinical-session',
        isLive: false,
    },
};
    const { live, upcoming } = leftSideFeeds;
    // Initial counts
    const initialLiveWebinars = live?.webinar?.length ?? 0;
    const initialLiveClinical = live?.['clinical-session']?.length ?? 0;
    const initialUpcomingWebinars = upcoming?.webinar?.length ?? 0;
    const initialUpcomingClinical = upcoming?.['clinical-session']?.length ?? 0;

    // Start calculations based on available data
    let liveWebinars = Math.min(initialLiveWebinars, 3);
    let liveClinical = Math.min(initialLiveClinical, 3);
    let totalLive = liveWebinars + liveClinical;
    let spaceRemaining = 6 - totalLive;

    // Adjust the counts for upcoming based on remaining space
    const _upcomingWebinars = 
  initialUpcomingClinical >= 3
    ? 3
    : initialUpcomingClinical <= 1
    ? 4
    : initialUpcomingClinical < 2
    ? 3
    : 4;

    // const _upcomingWebinars =initialUpcomingClinical>=3?3:4 ||initialUpcomingClinical<=1?4:3||initialUpcomingClinical<2?3:4
    let upcomingWebinars = Math.min(initialUpcomingWebinars, Math.min(_upcomingWebinars, spaceRemaining));
    let upcomingClinical = Math.min(initialUpcomingClinical, Math.max(0, spaceRemaining - upcomingWebinars));

    // Create sections with adjusted counts
    let sections = [
        {data: live?.webinar?.slice(0, liveWebinars), type: lang.live_webinars},
        {data: live?.['clinical-session']?.slice(0, liveClinical), type: lang.live_clinical_sessions},
        {data: upcoming?.webinar?.slice(0, upcomingWebinars), type: lang.upcoming_webinars},
        {data: upcoming?.['clinical-session']?.slice(0, upcomingClinical), type: lang.upcoming_slinical_sessions}
    ].filter(section => section?.data?.length > 0);

    // if (sections?.length === 0) return null;

    // useEffect(() => {
    //   // setLoader(true);
    
    //   if (sections.length >= 0) {
    //     setSectionsData(sections);
    //     setLoader(false);
    //   }
    // }, [sections]);

    useEffect(() => {
      if (isLoading && sections >= 0) {
        setLoader(true);
      } else {
        // setSectionsData(sections);
        setLoader(false);
      }
    }, [isLoading, sections]);
    

  const navigate = useNavigate()
    return (
        <>
        {(loader || sections.length > 0) && (
          <div
            className={`bg-white ${sections.length !== 0 ? "d-xl-block d-none" : ""} mt-3 mb-0`}
            style={{
              width: "335px",
            //   height: "649px",
              borderRadius: "8px",
              boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.05)",
              border: "0.5px solid rgba(0,0,0,0.25)",
              overflowY: "auto"
            }}
          >
            <div className="p-3 border-bottom d-flex justify-content-between">
              <h4 className="fs-16 roboto-bold mb-0">{lang.live_feeds}</h4>
              <p
                onClick={() => navigate("/live-webinars")}
                className="fs-12 cursor roboto-medium mb-0"
                style={{ color: "#8A93A1" }}
              >
                {lang.view_all}
              </p>
            </div>
      
            {loader
              ? // Show shimmers if loading and no data in sections
                [1, 2, 3, 4, 5, 6].map((_, index) => (
                  <React.Fragment key={index}>
                    <div className="d-flex p-2 justify-content-between rounded shimmer-placeholder">
                      <div className="d-flex gap-2 text-dark w-100">
                        <div style={{ width: "3rem", height: "3rem" }}>
                          <ShimmerCircularImage size={48} />
                        </div>
                        <div style={{ width: "100%" }}>
                          <p className="fs-14 roboto-bold m-0">
                            <ShimmerTitle width={200} />
                          </p>
                        </div>
                      </div>
                    </div>
                    {index !== 4 && <hr className="my-0" style={{ opacity: "6%" }} />}
                  </React.Fragment>
                ))
              : // Show sections data if available
                sections.map((section, index) => (
                  <React.Fragment key={index}>
                    <CategorySection
                      title={section.type}
                      items={section.data}
                      categoryId={categoriesConfig[section.type].id}
                      color={categoriesConfig[section.type].color}
                    />
                    {index !== sections.length - 1 && <hr className="m-0" />}
                  </React.Fragment>
                ))}
          </div>
        )}
      </>
      
    );
}

const CategorySection = ({ title, items, categoryId, color }) => {
    return (
        <div className="category-section cursor-pointer">
            <h6 className="px-3 m-2" style={{ color }}>{title}</h6>
            {items.map((item, index) => (
                <React.Fragment key={item.id}>
                    <LiveSectionCard item={item} borderColor={color} />
                    {index !== items.length - 1 && <hr className="m-0" />}
                </React.Fragment>
            ))}
        </div>
    );
};

const LiveSectionCard = ({ item, borderColor }) => {
  const { updatePostDetail } = useContext(PostDetailContext);
const{lang} = useContext(Language)
 const navigate=useNavigate()
  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  const startTimestamp = parseInt(item.start_timestamp, 10); // Convert API timestamp to an integer
  const timeDifference = startTimestamp - currentTime;
  
  // Convert the time difference to a human-readable format
  const getRelativeTime = (timeDifference) => {
      const minutes = Math.abs(Math.floor(timeDifference / 60));
      const hours = Math.abs(Math.floor(minutes / 60));
      const days = Math.abs(Math.floor(hours / 24));
      const months = Math.abs(Math.floor(days / 30)); // Approximate month as 30 days
      const years = Math.abs(Math.floor(days / 365)); // Approximate year as 365 days
  
      // if (timeDifference > 0) {
      //     // Event is in the future
      //     if (minutes < 60) return `${lang.starts_in} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes}`;
      //     else if (hours < 24) return `${lang.starts_in} ${hours} ${hours === 1 ? lang.hour  : lang.hours}`;
      //     else if (days < 30) return `${lang.starts_in} ${days} ${days === 1 ? lang.day : lang.days}`;
      //     else if (months < 12) return `${lang.starts_in} ${months} ${months === 1 ? lang.month : lang.months}`;
      //     else return `${lang.starts_in} ${years} ${years === 1 ? lang.year : lang.years}`;
      // } else {
      //   // started ago 2 day
      //     // Event is in the past
      //     if (minutes < 60) return navigator.language.startsWith("en")?`${lang.started} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes} ${lang.ago}`: `${lang.started} ${lang.ago} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes}`;
      //     else if (hours < 24) return `${lang.started} ${lang.ago} ${hours} ${hours === 1 ? lang.hour : lang.hours}`;
      //     else if (days < 30) return `${lang.started} ${lang.ago} ${days} ${days === 1 ? lang.day : lang.days}`;
      //     else if (months < 12) return `${lang.started} ${lang.ago} ${months} ${months === 1 ? lang.month : lang.months}`;
      //     else return `${lang.started} ${lang.ago} ${years} ${years === 1 ? lang.year : lang.years}`;
      // }
      if (timeDifference > 0) {
        // Event is in the future
        if (minutes < 60) {
          return navigator.language.startsWith("en") 
            ? `${lang.starts_in} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes}` 
            : `${lang.starts_in} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes}`;
        }
        else if (hours < 24) {
          return navigator.language.startsWith("en") 
            ? `${lang.starts_in} ${hours} ${hours === 1 ? lang.hour : lang.hours}` 
            : `${lang.starts_in} ${hours} ${hours === 1 ? lang.hour : lang.hours}`;
        }
        else if (days < 30) {
          return navigator.language.startsWith("en") 
            ? `${lang.starts_in} ${days} ${days === 1 ? lang.day : lang.days}` 
            : `${lang.starts_in} ${days} ${days === 1 ? lang.day : lang.days}`;
        }
        else if (months < 12) {
          return navigator.language.startsWith("en") 
            ? `${lang.starts_in} ${months} ${months === 1 ? lang.month : lang.months}` 
            : `${lang.starts_in} ${months} ${months === 1 ? lang.month : lang.months}`;
        }
        else {
          return navigator.language.startsWith("en") 
            ? `${lang.starts_in} ${years} ${years === 1 ? lang.year : lang.years}` 
            : `${lang.starts_in} ${years} ${years === 1 ? lang.year : lang.years}`;
        }
      } else {
        // Event is in the past
        if (minutes < 60) {
          return navigator.language.startsWith("en") 
            ? `${lang.started} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes} ${lang.ago}` 
            : `${lang.started} ${lang.ago} ${minutes} ${minutes === 1 ? lang.minute : lang.minutes}`;
        }
        else if (hours < 24) {
          return navigator.language.startsWith("en") 
            ? `${lang.started} ${hours} ${hours === 1 ? lang.hour : lang.hours} ${lang.ago}` 
            : `${lang.started} ${lang.ago} ${hours} ${hours === 1 ? lang.hour : lang.hours}`;
        }
        else if (days < 30) {
          return navigator.language.startsWith("en") 
            ? `${lang.started} ${days} ${days === 1 ? lang.day : lang.days} ${lang.ago}` 
            : `${lang.started} ${lang.ago} ${days} ${days === 1 ? lang.day : lang.days}`;
        }
        else if (months < 12) {
          return navigator.language.startsWith("en") 
            ? `${lang.started} ${months} ${months === 1 ? lang.month : lang.months} ${lang.ago}` 
            : `${lang.started} ${lang.ago} ${months} ${months === 1 ? lang.month : lang.months}`;
        }
        else {
          return navigator.language.startsWith("en") 
            ? `${lang.started} ${years} ${years === 1 ? lang.year : lang.years} ${lang.ago}` 
            : `${lang.started} ${lang.ago} ${years} ${years === 1 ? lang.year : lang.years}`;
        }
      }
      
  };
    


const formattedTime =getRelativeTime(timeDifference)
const avatarSrc = item.type === "webinar"
    ? (Array.isArray(item?.media) && item?.media?.length > 0 && item?.media[0])
        ? `${_imagesURL}${item?.media[0]}`
        : Avatar
    : item?.user?.image
    ? `${_imagesURL}${item?.user?.image}`
    : Avatar;   
    const onClickFeedCard = ()=>{
      if(item.type==="webinar"){
        // navigate("/live-webinars")
        updatePostDetail(item)
      }else{
        navigate(`/clinical-details/${item.id}`)
      }
    }
return (
        <div onClick={onClickFeedCard} className="d-flex px-3 justify-content-between manue-hover cursor rounded" style={{
            paddingTop: "0.76rem", paddingBottom: "0.76rem"
        }}>
            <div className="d-flex gap-2 text-dark ">
                <ImageComponent
                    src={avatarSrc}   
                    compClassName="rounded-circle"
                    img_height="50px"
                    img_width="50px"
                    loader_height="50px"
                    loader_width="50px"
                    roundShimmerSize={48}
                />
                <div className="align-self-center">
                    <p className="fs-14 roboto-medium suggest_name mb-0">{item.title}</p>
                    <small className="roboto-regular fs-12">{formattedTime}</small>
                </div>
            </div>
            <div className="align-self-center">
                <IoIosMore color="#8A93A1" size={20} />
            </div>
        </div>
    );
};

// import React from 'react';
// import ImageComponent from "../common/ImageComponent";
// import { IoIosMore } from "react-icons/io";
// import { _imagesURL } from '../../constants/API';
// import Avatar from "../../assets/images/icon/placeholder_Awatar.png";

// const categoriesConfig = {
//     "Live Webinars": {
//         id: 1,
//         name: "Live Webinars",
//         color: "rgba(255, 149, 0, 1)",
//         type: 'webinar',
//         isLive: true,
//     },
//     "Live Clinical Sessions": {
//         id: 2,
//         name: "Live Clinical Sessions",
//         color: "rgba(255, 59, 48, 1)",
//         type: 'clinical-session',
//         isLive: true,
//     },
//     "Upcoming Webinars": {
//         id: 3,
//         name: "Upcoming Webinars",
//         color: "rgba(0, 122, 255, 1)",
//         type: 'webinar',
//         isLive: false,
//     },
//     "Upcoming Clinical Sessions": {
//         id: 4,
//         name: "Upcoming Clinical Sessions",
//         color: "rgba(0, 122, 255, 1)",
//         type: 'clinical-session',
//         isLive: false,
//     },
// };

// export default function LiveFeeds({ leftSideFeeds }) {
//     const { live, upcoming } = leftSideFeeds;

//     // Distribute items according to the specified rules
//     const distributeItems = (webinars, sessions) => {
//         const count = sessions.length;
//         let webinarsToShow = 3;
//         let sessionsToShow = Math.min(count, 3);

//         if (count === 1) {
//             webinarsToShow = 5;
//             sessionsToShow = 1;
//         } else if (count === 2) {
//             webinarsToShow = 4;
//             sessionsToShow = 2;
//         } else if (count >= 3) {
//             webinarsToShow = 3;
//             sessionsToShow = 3;
//         }

//         return {
//             webinars: webinars.slice(0, webinarsToShow),
//             sessions: sessions.slice(0, sessionsToShow)
//         };
//     };

//     const liveDistribution = distributeItems(live?.webinar ?? [], live?.['clinical-session'] ?? []);
//     const upcomingDistribution = distributeItems(upcoming?.webinar ?? [], upcoming?.['clinical-session'] ?? []);

//     // Prepare sections to render
//     let sections = [
//         { data: liveDistribution.webinars, type: "Live Webinars" },
//         { data: liveDistribution.sessions, type: "Live Clinical Sessions" },
//         { data: upcomingDistribution.webinars, type: "Upcoming Webinars" },
//         { data: upcomingDistribution.sessions, type: "Upcoming Clinical Sessions" }
//     ].filter(section => section.data.length > 0);

//     if (sections.length === 0) return null;

//     return (
//         <div className="bg-white d-xl-block d-none my-3" style={{
//             width: "335px",
//             height: "649px",
//             borderRadius: "8px",
//             boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.05)",
//             border: "0.5px solid rgba(0,0,0,0.25)",
//         }}>
//             <div className="p-3 border-bottom d-flex justify-content-between">
//                 <h4 className="fs-16 roboto-bold mb-0">Live Feeds</h4>
//                 <p className="fs-12 roboto-medium mb-0" style={{ color: "#8A93A1" }}>
//                     view all
//                 </p>
//             </div>
//             {sections.map((section, index) => (
//                 <>
//                     <CategorySection 
//                         key={index} 
//                         title={section.type} 
//                         items={section.data} 
//                         categoryId={categoriesConfig[section.type].id}
//                         color={categoriesConfig[section.type].color}
//                     />
//                     {index !== sections.length - 1 && <hr className="m-0" />} {/* Horizontal line between sections */}
//                 </>
//             ))}
//         </div>
//     );
// }

// const CategorySection = ({ title, items, categoryId, color }) => {
//     return (
//         <div className="category-section cursor-pointer">
//             <h6 className="px-3 m-2" style={{ color }}>{title}</h6>
//             {items.map((item, index) => (
//                 <React.Fragment key={item.id}>
//                     <LiveSectionCard item={item} borderColor={color} />
//                     {index !== items.length - 1 && <hr className="m-0" />}
//                 </React.Fragment>
//             ))}
//         </div>
//     );
// };

// const LiveSectionCard = ({ item, borderColor }) => {
//     const avatarSrc = item.type === "webinar"
//         ? (item?.media?.length > 0 ? `${_imagesURL}${item.media[0]}` : Avatar)
//         : (item?.user?.image ? `${_imagesURL}${item.user.image}` : Avatar);

//     return (
//         <div className="d-flex px-3 justify-content-between manue-hover rounded" style={{
//             paddingTop: "0.76rem", paddingBottom: "0.76rem"
//         }}>
//             <div className="d-flex gap-2 text-dark w-100">
//                 <ImageComponent
//                     src={avatarSrc}
//                     compClassName="rounded-circle"
//                     img_height="50px"
//                     img_width="50px"
//                     loader_height="50px"
//                     loader_width="50px"
//                     roundShimmerSize={48}
//                 />
//                 <div className="align-self-center">
//                     <p className="fs-14 roboto-medium suggest_name mb-0">{item.title}</p>
//                     <small className="roboto-regular fs-12">{item.scheduled}</small>
//                 </div>
//             </div>
//             <div className="align-self-center">
//                 <IoIosMore color="#8A93A1" size={20} />
//             </div>
//         </div>
//     );
// };


