import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { IoIosPlayCircle } from "react-icons/io";
import play_lesson from "../../assets/images/icon/play_lesson.svg"
const CoursePurchaseCard = () => {
    return (
      <div
        className="card shadow-sm rounded-3 h-100" // h-100 ensures full height
        style={{ maxWidth: "300px" }}
      >
        <div className="card-body d-flex flex-column">
          <h6 className="card-title text-muted">Instructor</h6>
          <div className="d-flex align-items-center mb-3">
          <div
          className="bg-white  rounded-circle"
          style={{
            border: "1px solid #1DC1DD",
            padding: "2px",
            marginRight: "16px",
            marginTop: "1rem",
            maxHeight: "100px",
            maxWidth: "100px",
          }}
        >
          <Link to={`${PROFILE}`}>
            <ImageComponent
              src={
               "https://via.placeholder.com/50"
              }
              loader_height="92px"
              loader_width="92px"
              roundShimmerSize={92}
              compClassName="rounded-circle"
              img_width={92}
              img_height={92}
            />
          </Link>
        </div>
            {/* <img
              src="https://via.placeholder.com/50"
              alt="Instructor"
              className="rounded-circle me-3"
              width="50"
              height="50"
            /> */}
            <div className="mt-2">
              <h6 className="mb-0">Dr Frank Castle</h6>
              <small className="text-muted">Ophthalmology (Uveitis)</small>
            </div>
          </div>
          <p className="fw-semibold">This course includes</p>
          <ul className="list-unstyled mb-4">
            <li className="d-flex align-items-center mb-2">
              <IoIosPlayCircle size={35} className="me-2"/>
              <span>37 Hours & 38 Minutes</span>
            </li>
            <hr className="text-muted"/>
            <li className="d-flex align-items-center">
              <img className="me-2" src={play_lesson}/>
              <span>17 Chapters & 39 Videos</span>
            </li>
            <hr className="text-muted"/>
          </ul>
          <h5 className="fw-bold mt-auto">$0 - Free</h5>
          <button className="btn btn-dark mt-2 w-100">Enroll Now - Free</button>
        </div>
      </div>
    );
  };
  

export default CoursePurchaseCard