// token.js

// Function to get the token from localStorage
export const getToken = () => {
    try {
        const signupData = JSON.parse(localStorage.getItem("SignupData"));
        return signupData?.data?.token || null; // Return the token if available
    } catch (error) {
        console.error("Error parsing SignupData from localStorage:", error);
        return null;
    }
};

// Function to set the token in localStorage
export const setToken = (token) => {
    try {
        const signupData = JSON.parse(localStorage.getItem("SignupData")) || {};
        signupData.data = { ...signupData.data, token };
        localStorage.setItem("SignupData", JSON.stringify(signupData));
    } catch (error) {
        console.error("Error setting token in SignupData:", error);
    }
};

// Function to clear the token from localStorage
export const clearToken = () => {
    try {
        const signupData = JSON.parse(localStorage.getItem("SignupData")) || {};
        if (signupData.data) {
            delete signupData.data.token;
        }
        localStorage.setItem("SignupData", JSON.stringify(signupData));
    } catch (error) {
        console.error("Error clearing token in SignupData:", error);
    }
};
