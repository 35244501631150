import React from "react";
import {
  ShimmerTitle,
  ShimmerBadge,
  ShimmerCircularImage,
} from "react-shimmer-effects";

const WithdrawShimmer = () => {
  return (
    <>
      <div className="withdrawShimmer">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              <ShimmerBadge width={170} />
            </h3>
            <h4>
              <ShimmerBadge width={200} />
            </h4>
          </div>
          <div>
            <ShimmerCircularImage size={70} />
          </div>
        </div>
        <div className="border_top_bottom">
          <div className="d-flex justify-content-between">
            <h2>
            <div className="shimmer-badge_div">
              <ShimmerBadge width={200} />
              </div>
            </h2>
            <h6>
                <div className="shimmer-badge_div">
              <ShimmerBadge width={150} />
              </div>
            </h6>
          </div>
          <p>
            <ShimmerTitle line={3} variant="secondary" />
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <h2>
            <ShimmerBadge width={150} />
          </h2>
          <h1>
            <ShimmerBadge width={150} />
          </h1>
        </div>
      </div>
    </>
  );
};

export default WithdrawShimmer;
