import React from 'react'
import { ShimmerCircularImage, ShimmerSimpleGallery, ShimmerText, ShimmerTitle } from 'react-shimmer-effects'

const SubCategoryCardShimmer = () => {
  return (
   <>
        <div className="d-flex gap-2 my-2">
          
          <>
            {[1, 2, 3, 4, 5,6,7,8].map((_, index) => (
             <div
             key={index}
             className="shimmer-category-card"
             style={{
               width: 'fit-content',
               backgroundColor: 'rgb(255, 255, 255)', // Match the background color of the card
               borderRadius: '10px',
               padding: '10px', // Match padding for the content
               display: 'flex',
               alignItems: 'center',
               gap: '10px',
               boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // Add a shadow for a card-like effect
             }}
           >
             {/* Built-in Shimmer effect for the circular image */}
             {/* <div
               style={{
                 width: '40px',
                 height: '40px',
                 borderRadius: '50%',
                 overflow: 'hidden',
               }}
             >
              
               <ShimmerSimpleGallery
                 card
                 imageType="circle"
                 imageWidth={40}
                 imageHeight={40}
                 contentCenter
               />
             </div> */}
          <div className='mt-2'>
     <ShimmerCircularImage size={28} />
     </div>
             {/* Shimmer for the title and subtitle */}
             <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
               <div
                 style={{
                   width: '150px',
                   height: '15px',
                   backgroundColor: '#E0E0E0',
                   borderRadius: '5px',
                 }}
               ></div>
               {/* <ShimmerTitle line={2}  className={"custom-shimmer"}/> */}
               
               <div
                 style={{
                   width: '100px',
                   height: '10px',
                   backgroundColor: '#E0E0E0',
                   borderRadius: '5px',
                 }}
               ></div>
             </div>
           </div>
            ))}
          </>
          </div>
          <div className="d-flex gap-2 my-2">
        
        <>
          {[1, 2, 3, 4, 5,6,7,8].map((_, index) => (
           <div
           key={index}
           className="shimmer-category-card"
           style={{
             width: 'fit-content',
             backgroundColor: 'rgb(255, 255, 255)', // Match the background color of the card
             borderRadius: '10px',
             padding: '10px', // Match padding for the content
             display: 'flex',
             alignItems: 'center',
             gap: '10px',
             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // Add a shadow for a card-like effect
           }}
         >
           {/* Built-in Shimmer effect for the circular image */}
           {/* <div
             style={{
               width: '40px',
               height: '40px',
               borderRadius: '50%',
               overflow: 'hidden',
             }}
           >
             <ShimmerSimpleGallery
               card
               imageType="circle"
               imageWidth={40}
               imageHeight={40}
               contentCenter
             />
           </div> */}
           <div className='mt-2'>
     <ShimmerCircularImage size={28} />
     </div>
           {/* Shimmer for the title and subtitle */}
           <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
             <div
               style={{
                 width: '150px',
                 height: '15px',
                 backgroundColor: '#E0E0E0',
                 borderRadius: '5px',
               }}
             ></div>
             <div
               style={{
                 width: '100px',
                 height: '10px',
                 backgroundColor: '#E0E0E0',
                 borderRadius: '5px',
               }}
             ></div>
           </div>
         </div>
          ))}
        </>
        </div>
   </>
  )
}

export default SubCategoryCardShimmer