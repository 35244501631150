import { Carousel } from "react-responsive-carousel";
import ImageComponent from "../common/ImageComponent";

export default function CourseBanner({ banner_images }) {
  return (
    <div className="shadow-sm rounded-3">
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
      >
        {banner_images.map((image, index) => (
          <ImageComponent
            key={index}
            src={image}
            compClassName="rounded-3"
            img_width="100%"
            img_height="299px"
            loader_width="100%"
            loader_height="299px"
          />
        ))}
      </Carousel>
    </div>
  );
}
