import { useContext, useState } from "react";
import {
  MdArrowForwardIos,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import NewCoursesCard from "./NewCoursesCard";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import { Language } from "../../App";

export default function TrendingCourses({ courses, loading }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const{lang} = useContext(Language)
  const itemsPerPage = 4;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? Math.max(courses?.length - itemsPerPage, 0)
        : prevIndex - itemsPerPage
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= courses?.length - itemsPerPage ? 0 : prevIndex + itemsPerPage
    );
  };
 const navigate = useNavigate();

  const handleSeeAllClick = () => {
    // Navigate to the 'courses/:title' route, with a specific title, e.g., 'All Courses'
    navigate('/courses/all/trending');
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0 fs-20 roboto-bold">{lang.trending_new_courses}</h4>
        <div className="d-flex align-items-center gap-2">
          <h5 className="mb-0 fs-16 roboto-bold cursor" onClick={handleSeeAllClick}>{lang.see_all_text}</h5>
          <MdArrowForwardIos size={20} />
        </div>
      </div>
      <div className="my-3 d-flex gap-2 flex-wrap position-relative">
        <div
          className="d-flex align-items-center justify-content-center rounded-circle bg-white p-2 position-absolute start-0 top-50 translate-middle-y cursor"
          style={{ border: "2px solid #1DC1DD", marginLeft: "-13px" }}
          onClick={handlePrev}
        >
          <MdOutlineArrowBackIos size={17} />
        </div>
        {loading &&
          [1, 2, 3, 4].map((_, index) => (
            <div key={index} style={{ width: "224px" }}>
              <ShimmerPostItem imageHeight={200} card title />
            </div>
          ))}
        {courses
          ?.slice(currentIndex, currentIndex + itemsPerPage)
          ?.map((course) => (
            <NewCoursesCard key={course.id} course={course} />
          ))}
        <div
          className="d-flex align-items-center justify-content-center rounded-circle bg-white p-2 position-absolute end-0 top-50 translate-middle-y cursor"
          style={{ border: "2px solid #1DC1DD", marginRight: "-13px" }}
          onClick={handleNext}
        >
          <MdOutlineArrowForwardIos size={17} />
        </div>
      </div>
    </>
  );
}
