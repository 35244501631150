import { Col, Row } from "reactstrap";
import { _imagesURL, BaseURL } from "../../constants/API";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio";
import SearchedPostHeader from "./SearchedPostHeader";
import SearchResultCard from "./SearchResultCard";
import { ReadMore } from "../../pages/Feed";
import { useContext } from "react";
import { toast } from "react-toastify";
import { Language, LoginUserData, PostDetailContext } from "../../App";

export default function WebinarResultComp({ data, keyword, loading }) {
  return (
    <SearchResultCard
      title="Webinars"
      link={`/search/webinars?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      {data?.map((webinar, index) => (
        <WebinarCard key={index} webinar={webinar} />
      ))}
    </SearchResultCard>
  );
}

export const WebinarCard = ({ webinar }) => {
  const { updatePostDetail } = useContext(PostDetailContext);
  return (
    <div className="p-2">
      <SearchedPostHeader postDetails={webinar} />
      {webinar?.media?.map((image, index) => (
        <div onClick={()=>updatePostDetail(webinar)}>
        <PostImagePreview image={image} key={index} />
        </div>
      ))}
      <WebinarCaption value={webinar} />
    </div>
  );
};

export const PostImagePreview = ({ image }) => {
  return (
    <ImageWithAspectRatio
      height={570 / image.sizes}
      src={_imagesURL + image}
      alt="..."
    />
  );
};

export const WebinarCaption = ({ value }) => {
  const { splashData } = useContext(LoginUserData);
  const { lang } = useContext(Language);

  const handleReserveWebinar = () => {
    console.log("Reserve Webinar");
  };
  return (
    <>
      <Row className="p-2">
        <Col>
          <h4>
            {value?.title && value?.title?.length > 26
              ? value?.title?.slice(0, 26) + "..."
              : value?.title}
          </h4>
          <ReadMore text={value?.caption || ""} />
        </Col>
      </Row>
      <Row className="justify-content-between px-2">
        <Col className="align-self-center">
          <p className="mb-0 text-warning">{lang?.scheduled_on}</p>
          <small className="mb-0">
            {value?.schedule_time}
            {" on "}
            {value?.schedule_date}
          </small>
        </Col>
        <Col className="text-end align-self-center">
          {value?.status !== 0 && (
            <button
              className="px-4 py-2 fw-bold text-white btn btn-warning"
              onClick={
                value?.user?.id === splashData?.user?.id
                  ? value?.status === 0
                    ? null
                    : value?.status === 2
                    ? "Webinar Ended"
                    : () => {
                        if (value?.user?.id === splashData?.user?.id) {
                          window.open(value?.meeting_host_url, "_blank");
                        } else {
                          if (value?.isHost) {
                            window.open(value?.meeting_host_url, "_blank");
                          } else {
                            window.open(value?.meeting_url, "_blank");
                          }
                        }
                      }
                  : value?.status === 0
                  ? value?.isReserve
                    ? () => handleReserveWebinar(value?.id)
                    : () => handleReserveWebinar(value?.id)
                  : value?.status === 1
                  ? value?.isReserve
                    ? () => {
                        if (value?.user?.id === splashData?.user?.id) {
                          window.open(value?.meeting_host_url, "_blank");
                        } else {
                          if (value?.meeting_url !== "") {
                            window.open(value?.meeting_url, "_blank");
                          } else {
                            toast.info(lang.not_start_yet);
                          }
                        }
                      }
                    : () => handleReserveWebinar(value?.id)
                  : () => toast.info(lang.webinar_is_ended)
              }
            >
              {value?.user?.id === splashData?.user?.id
                ? value?.status === 0
                  ? lang.not_start_yet
                  : value?.status === 2
                  ? lang.webinar_ended
                  : lang.webinar_started
                : value?.status === 0
                ? value?.isReserve
                  ? lang.unreserve
                  : lang.reserve_seat
                : value?.status === 1
                ? value?.isReserve
                  ? lang.join_webinar
                  : lang.reserve_webinar
                : lang.webinar_ended}
            </button>
          )}
        </Col>
      </Row>
    </>
  );
};
