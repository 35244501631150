import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EnrollPopup = ({ isOpen, toggle }) => {
    const navigate = useNavigate()
  const handleEnroll = () => {
    alert('You are now enrolled in this course!');
    navigate("/paypal-payment")
    toggle(); // Close the modal after enrolling
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Enroll in Course</ModalHeader>
      <ModalBody>
        <p className='fw-bold'>Do you want to enroll in this course?</p>
        <p>After enrolling in this course, you will have access to all the content.</p>
      </ModalBody>
      <ModalFooter className="d-flex gap-2">
  <button style={{backgroundColor:"#031316",color:"white",border: "none", }} onClick={toggle} className="flex-grow-1 p-3 rounded">
    Cancel
  </button>
  <button style={{backgroundColor:"#1dc1dd",color:"white",border: "none", }} onClick={handleEnroll} className="flex-grow-1 p-3 rounded">
    Enroll Now
  </button>
</ModalFooter>
    </Modal>
  );
};

export default EnrollPopup;
