import React from "react";

import ClinicalSessionCard from "./ClinicalSessionCard";
import ShimmerClinicalSessionCard from "./ShimmerClinicalSessionCard";
import { useQuery } from "react-query";
import { fetchClinicalSessionData } from "../../services/coursesApi";

const LiveSessions = ({ count }) => {
  const { data, isLoading, isError,refetch } = useQuery({
    queryKey: ["clinicalSessionData"],
    queryFn: () => fetchClinicalSessionData({type: "live"}),
    refetchOnWindowFocus: false, 
  });
  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-wrap justify-content-start gap-3">
        {new Array(count).fill(0).map((_, index) => (
          <div className="clinical_card" key={index}>
            <ShimmerClinicalSessionCard/>
          </div>
        ))}
      </div>
      ) : (
        
            <div className="mt-2">
            <div className="d-flex flex-wrap justify-content-start gap-3">
              {data?.data&&data?.data?.map((session, index) => (
                <div key={index} className="clinical_card">
                  <ClinicalSessionCard session={session}/>
                </div>
              ))}
            </div>
          </div>
      )}
        
     
    </>
  );
};

export default LiveSessions;
