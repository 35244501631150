import React from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';

const CourseHeader = ({title,description,buttonTitle,onClick}) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="card shadow-sm border-0 rounded">
        <div className="card-body text-center p-4">
          <h3 className="mb-3 roboto-bold">{title}</h3>
          <p className="mb-3  text-gray"
          dangerouslySetInnerHTML={{ __html: description }}
          >
           {/* {description} */}
          </p>
          <button onClick={() => navigate(-1)} className="btn btn-info btn-lg mt-3 text-white px-4"
           style={{ borderRadius: '20px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',border: "1px solid #32b5cf" }}
          >
         <span> {buttonTitle}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseHeader;
