import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { ShimmerCategoryItem } from 'react-shimmer-effects';
import ScrollToTop from '../../../components/courses/See-All-Components/ScrollToTop';
import CourseHeader from '../../../components/courses/See-All-Components/CourseHeader';
import NavBar from '../../../components/NavBar';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from "react-infinite-scroller"; // Import InfiniteScroll

import { toast } from 'react-toastify';
import { fetchSuggestedEvents } from '../../../services/eventsApis';
import { _imagesURL } from '../../../constants/API';
import { Language } from '../../../App';



const SeeAllSuggestedEvents = () => {
  const navigate = useNavigate();
  const {lang} = useContext(Language) 
  const details = {
    title: lang.suggested_events,
    description: lang.suggested_events_para,
    buttonTitle: lang.go_back_to_main_page
  };
  // Infinite query for suggested events
  const {
    data: eventsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['suggestedEvents'],
    queryFn: ({ pageParam = 1 }) => fetchSuggestedEvents({ page: pageParam }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage?.data?.current_page + 1;
      return nextPage <= lastPage?.data?.last_page ? nextPage : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error(error.message);
    },
  });
  // Flatten paginated data
  const events = eventsData?.pages?.flatMap((page) => page?.data?.data) || [];

  return (
    <>
      <ScrollToTop />
      <Container fluid style={{ overflowX: "hidden" }}>
        <Row>
          <NavBar />
        </Row>
        <Row>
          <div className="mt-5">
            <div className="container my-5">
              <CourseHeader
                title={details.title}
                description={details.description}
                buttonTitle={details.buttonTitle}
                onClick={() => navigate("/main-page")}
              />
              <h4 className="mb-0 mt-4 fs-20 roboto-bold">{details.title}</h4>
              <Row className="mt-4" style={{ overflow: "hidden" }}>
                {isLoading ? (
                  // Render ShimmerContentBlock for loading
                  [1,2,3,4].map((_, index) => (
                    <Col md={6} className="mb-4" key={index}>
                      <div className='suggested_shimmer' style={{backgroundColor:"white",padding:"20px"}}>
                        <ShimmerCategoryItem
                          hasImage
                          imageType="thumbnail"
                          imageWidth={188}
                          imageHeight={115}
                          title
                          className="shimmer-item"
                        />
                      </div>
                    </Col>
                  ))
                ) : events.length === 0 ? (
                  // No data message
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "200px", width: "100%" }}>
                    <h3 className="text-muted">{lang.no_data_found}</h3>
                  </div>
                ) : (
                  // InfiniteScroll component with event cards
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                      if (!isFetchingNextPage) {
                        fetchNextPage();
                      }
                    }}
                    hasMore={hasNextPage}
                    loader={
                      <Row className="mt-1">
                        {[1,2,3,4].map((_, index) => (
                          <Col md={6} className="mb-4" key={index}>
                            <div className='suggested_shimmer' style={{backgroundColor:"white",padding:"20px"}}>
                              <ShimmerCategoryItem
                                hasImage
                                imageType="thumbnail"
                                imageWidth={188}
                                imageHeight={115}
                                title
                                className="shimmer-item"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    }
                  >
                    <Row className="mt-2" >
                      {events.map((event) => (
                        <Col onClick={() => navigate(`/event-details/${event?.id}`)} md={6} className="mb-4 cursor" key={event?.id} >
                          <div className="rounded bg-white p-3 h-100">
                            <div className="d-flex align-items-center">
                              <div style={{ border: "1px solid rgb(29, 193, 221)" }} className="rounded overflow-hidden">
                                <img
                                  src={`${_imagesURL}${event?.cover}`}
                                  className="rounded object-cover d-block"
                                  alt="event cover"
                                  width="188"
                                  height="115"
                                />
                              </div>
                              <div className="ms-3">
                                <h5 className="fs-16 roboto-bold mb-1">
                                  {event?.title?.length > 40 ? event?.title?.substring(0, 40) + "..." : event?.title}
                                </h5>
                                <p className="fs-14 roboto-medium text-muted mb-0">
                                  {event?.start_date} - {event?.end_date}
                                </p>
                                <p className="fs-14 roboto-regular text-muted mb-0 py-1">
                                  {event?.description?.length > 60 ? event?.description.substring(0, 60) + "..." : event?.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </InfiniteScroll>
                )}
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SeeAllSuggestedEvents;


