import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import './withdrawRequestModal.css'; // Import custom styles if needed
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { requestPayments } from '../../../services/walletApi';

const WithdrawRequestModal = ({ isOpen, toggle }) => {
  const [amount, setAmount] = useState('');

  const handleChange = (e) => {
    setAmount(e.target.value);
  };
  const { mutate, isLoading } = useMutation(requestPayments, {
    onSuccess: (data) => {
      toast.success('Withdrawal request sent successfully!');
      toggle(); // Close the modal after successful request
    },
    onError: (error) => {
      toast.error('Error sending withdrawal request:', error);
    },
  });
  const handleSendRequest = () => {
    if (amount) {
        mutate(amount); // Call the API with the entered amount
      } else {
        toast.error('Please enter a valid amount');
      }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Withdraw Request</ModalHeader>
      <ModalBody>
        <p>Please enter the amount that you want to withdraw from your wallet.</p>
        <div className="input-container mt-3">
          <Input
            type="number"
            placeholder="€ 0"
            value={amount}
            onChange={handleChange}
            className="withdraw-input"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='px-4' color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <button className='btn px-4' style={{backgroundColor:"#1DC1DD",color:"#ffff"}} onClick={handleSendRequest}>
          Send Request
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default WithdrawRequestModal;
