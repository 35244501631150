import { Carousel } from "react-responsive-carousel";
import SearchedPostHeader from "./SearchedPostHeader";
import SearchResultCard from "./SearchResultCard";
import { Language, LoginUserData, PostDetailContext } from "../../App";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio";
import VideoWithAspectRatio from "../common/VideowithAspectRatio.tsx";
import { _imagesURL, BaseURL,adminBaseURL, RepostApi, GetCommentsApi } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png"
import InfiniteScroll from "react-infinite-scroller";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { ReadMore } from "../../pages/Feed";
import PostFooter from "../feeds/PostFooter";
import axios from "axios";
import { toast } from "react-toastify";
import { likePostApi, savePostApi, usersLikedPostApi } from "../../services/feedApis.js";
import { authorize } from "../../shared/authorize.js";
import { PROFILE } from "../../constants/RoutesUrl.js";
import { Link, useNavigate } from "react-router-dom";
import ImageComponent from "../common/ImageComponent.jsx";
import CommentCanvas from "../../pages/post-details/comment-details/CommentCanvas.jsx";

export default function PostsResultsComp({ data, keyword, loading }) {
  const{lang} = useContext(Language) 

  return (
    <SearchResultCard
      title={lang.posts}
      link={`/search/posts?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      <div>
        {data?.map((post, index) => (
          <PostsCard key={index} post={post} />
        ))}
      </div>
    </SearchResultCard>
  );
}

export const PostsCard = ({ post }) => {
  const { splashData } = useContext(LoginUserData);
  const{lang} = useContext(Language) 
  const [data, setData] = useState({});
  const { updatePostDetail } = useContext(PostDetailContext);
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likePage, setLikePage] = useState(1);
  const [postId, setPostId] = useState("");
  const [page, setPage] = useState(1);
  const [likeUsers, setLikeUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [postModal, setPostModal] = useState(false);

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const navigate = useNavigate()
  const handleRepost = async () => {
    try {
      setData({ ...data, isShare: !data.isShare });
      const response = await axios.get(
       `${RepostApi}${post?.id}`,authorize()
      );
      if (response.data.status) {
        toast.success(response.data.action);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = async () => {
    try {
      setData({ ...data, isSave: !data.isSave });
      const response = await savePostApi({post_id:data.id});
      if (!response?.data?.status) {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLike = async () => {
    try {
      const updatedData = {
        ...data,
        isLike: !data.isLike,
        likes_count: data.isLike ? data.likes_count - 1 : data.likes_count + 1,
      };
      setData(updatedData);
      await likePostApi({post_id:data.id});
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setData(post);
  }, [post]);
  const handleLikeUsers = useCallback(
    async (otherId) => {
      setLikeSpinner(true);
      setLikeUsersModal(true);
      try {
        const response = await
          axios.get(
            `${adminBaseURL}user/post/likes/${otherId}`, authorize()
          );
        if (response.data.status) {
          setLikeUsers(response.data.data);
        } else {
          toast.error(response.data.error);
          setLikeUsersModal(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLikeSpinner(false);
      }
    },
    [user_id]
  );

  const handleNewLikeUsers = useCallback(async () => {
    try {
      const response = await usersLikedPostApi({ post_id: postId, page })
      if (response.data.status) {
        const update = likeUsers.data.concat(response.data.data.data);
        setLikeUsers((prevUsers) => ({ ...prevUsers, data: update }));
        setLikePage((prevPage) => prevPage + 1);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  }, [likePage, likeUsers, postId, user_id]);
  const handleGetComments = useCallback(
    async (post_id) => {
      setIsLoading(true);
      const id = post_id || postId;
      try {
        const response = await axios.get(
          `${GetCommentsApi}${id}?page=1`
          , authorize());
        if (response.data.status) {
          // setPostComments(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postId, user_id]
  );
  return (
    <div className="border border-inherit p-2">
      <SearchedPostHeader postDetails={data} />
      <PostCarousel post={data} />
      {data?.caption && (
        <>
          <Row className="p-2">
            <Col>
              <ReadMore text={data.caption} />
            </Col>
          </Row>
          <hr className="m-0" />
        </>
      )}
      <PostFooter
        likes_count={data?.likes_count}
        setPostId={setPostId}
        original_id={data?.original_id}
        handleLikeUsers={handleLikeUsers}
        like_users_images={data?.like_users_images}
        handleLike={handleLike}
        isLike={data?.isLike}
        handleGetComments={handleGetComments}
        setPostModal={setPostModal}
        comments_count={data?.comments_count}
        handleRepost={handleRepost}
        isShare={data?.isShare}
        isSave={data?.isSave}
        handleSave={handleSave}
      />
      <Modal
        isOpen={likeUsersModal}
        toggle={() => setLikeUsersModal(!likeUsersModal)}
        centered
        zIndex={9}
      >
        <ModalHeader toggle={() => setLikeUsersModal(false)}>
          {lang?.liked_users}
        </ModalHeader>
        <ModalBody>
          {likeSpinner ? (
            <Col className="my-4 text-center">
              <Spinner />
            </Col>
          ) : (
            <div>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewLikeUsers}
                hasMore={likeUsers.current_page !== likeUsers.last_page}
                loader={
                  <Col className="text-center">
                    <Spinner />
                  </Col>
                }
                useWindow={false}
              >
                {likeUsers.data &&
                  likeUsers.data.map((value, index) => (
                    <>
                      <Link
                        onClick={() => {
                          navigate(PROFILE + "/" + value.id);
                        }}
                        className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                        key={index}
                      >
                        <div className="d-flex gap-2">
                          <div
                            className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                            style={{
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? _imagesURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="roboto-bold text-dark fs-17 search-post-name">
                              {value.name}
                            </div>
                            <p className="roboto-regular mb-0 fs-12 search-post-name">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </ModalBody>
      </Modal>
      {postModal && <CommentCanvas
        postModal={postModal}        // Controls the visibility of the canvas
        setPostModal={setPostModal}   // Function to toggle the modal’s visibility
        postId={postId}
      />
     }
    </div>
  );
};

export const PostCarousel = ({ post }) => {
  const { updatePostDetail } = useContext(PostDetailContext);
  const [playingVideo, setPlayingVideo] = useState(-1);
  const handleVideoPlay = (index) => {
    setPlayingVideo(index); // Set the current video as playing
  };
  const indicatorStyles = useMemo(
    () => ({
      background: "#fff",
      width: 20,
      height: 4,
      display: "inline-block",
      margin: "0 4px",
      borderRadius: "1px",
    }),
    []
  );
  return (
    <Row
      className="pt-3"
      onClick={() => {
        updatePostDetail(post);
      }}
    >
      <Col>
        {post.type === "video" ? (
          <>
            {post.media &&
              post.media.map((media, index) => (
                <VideoWithAspectRatio
                  key={index}
                  index={index}
                  setPlayingVideo={handleVideoPlay} // Pass setter to update playing video
                  playingVideo={playingVideo} // Current playing video index
                  src={_imagesURL + media}
                  aspectRatio={post.sizes}
                />
              ))}
          </>
        ) : post.media && post.media.length === 1 ? (
          post.media.map((media, index) => (
            <ImageWithAspectRatio
              key={index}
              height={555 / post.sizes}
              src={_imagesURL + media}
              alt="..."
            />
          ))
        ) : (
          <Carousel
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <IoIosArrowDroprightCircle
                  className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                  size={30}
                  color="white"
                  onClick={(e) => {
                    e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                    onClickHandler();
                  }}
                />
              )
            }
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <IoIosArrowDropleftCircle
                  className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                  size={30}
                  style={{ zIndex: "1" }}
                  color="white"
                  onClick={(e) => {
                    e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                    onClickHandler();
                  }}
                />
              )
            }
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <>
                  {isSelected ? (
                    <li
                      style={{
                        ...indicatorStyles,
                        background: "#00c5de",
                      }}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                    />
                  ) : (
                    <li
                      style={indicatorStyles}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                        onClickHandler();
                      }}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    />
                  )}
                </>
              );
            }}
            autoPlay={false}
            infiniteLoop={false}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
          >
            {post.media &&
              post.media.map((data, index) => (
                <ImageWithAspectRatio
                  key={index}
                  height={555 / post.sizes}
                  src={_imagesURL + data}
                  alt="..."
                />
              ))}
          </Carousel>
        )}
      </Col>
    </Row>
  );
};
