import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "./RoutesUrl";
import { PostDetailContext } from "../App";
import PostDetailModal from "../components/common/PostDetailModal";

export const CountContext = createContext();

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const { postDetail, isOpen, postModalClose } = useContext(PostDetailContext);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [count, setCount] = useState(null);

  const updateCount = (val) => {
    setCount(val);
  };

  const checkUser = () => {
    const userToken = JSON.parse(localStorage.getItem("SignupData"));
    if (!userToken?.data?.id) {
      setIsLoggedIn(false);
      return navigate(LOGIN);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUser();
  }, [isLoggedIn]);
  return (
    <>
      {isLoggedIn ? (
        <CountContext.Provider value={{ count, updateCount }}>
          {isOpen&&<PostDetailModal isOpen={isOpen} onClose={postModalClose} post={postDetail} />}
          {props.children}
        </CountContext.Provider>
      ) : null}
    </>
  );
};
export default ProtectedRoute;
