import React, { useEffect, useContext, useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Spinner from "../Spinner";
import PlusIcon from "../../assets/images/icon/plus.png";
import axios from "axios";
import {
  AddCourseSectionContentApi,
  adminBaseURL,
  DeleteCourseSectionContentApi,
  EditCourseSectionContentApi,
  GetCourseSectionContentApi,
} from "../../constants/API";
import { Link } from "react-router-dom";
import { COURSE_CONTENT } from "../../constants/RoutesUrl";
import { Language } from "../../App";
import { getToken } from "../../shared/token";

const CourseSectionContent = () => {
  const section_id = sessionStorage.getItem("section_id");
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;
  const { lang } = useContext(Language);

  const [spin, setSpin] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(false);
  const [addCourseMedia, setAddMediaSection] = useState(false);
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [editCourseMedia, setEditCourseMedia] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const authorize ={
        
    headers: {
        Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },

}
  const handleAddMedia = () => {
    setIsLoading(true);
    axios
      .post(`${adminBaseURL}user/course/save/video`, {
        section_id,
        title: mediaTitle,
        description: mediaDescription,
      },authorize)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setDataRefetch(!dataRefetch);
          setAddMediaSection(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const handleEditMedia = () => {
    setIsLoading(true);
    axios
      .post(`${adminBaseURL}user/course/update/video`, {
        video_id: editData.id,
        title: editData.title,
        description: editData.description,
      },authorize)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setDataRefetch(!dataRefetch);
          setEditCourseMedia(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const handleDelete = (id) => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/video/delete/${id}`,authorize)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          setDeleteModal(false);
          setDataRefetch(!dataRefetch);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSpin(true);
    axios
      .get(`${adminBaseURL}user/course/section/${section_id}`,authorize)
      .then((res) => {
        setData(res.data.data);
        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  }, [dataRefetch]);
  return (
    <>
      <Row>
        {spin ? (
          <div className="vh-100 w-100">
            <Spinner />
          </div>
        ) : (
          <Col
            xxl={9}
            className="bg-white p-4 ms-5"
            style={{ borderRadius: "12px" }}
          >
            <Row className="pb-3 justify-content-end">
              <Col lg={8} className="d-flex justify-content-between">
                <h3 className="fs-20 fs-md-16 roboto-bold">{lang?.course_media}</h3>
                <img
                  src={PlusIcon}
                  width={25}
                  height={25}
                  alt="picture"
                  className="cursor"
                  onClick={() => setAddMediaSection(true)}
                />
              </Col>
            </Row>
            {data.map((value, index) => (
              <>
                <Row
                  key={index}
                  className="py-2 mb-3"
                  style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                  }}
                >
                  <Link
                    to={COURSE_CONTENT}
                    onClick={() => {
                      sessionStorage.setItem("courseMediaContentId", value.id);
                    }}
                    className="col-xl-8 col-lg-7 d-flex gap-3 text-dark"
                  >
                    <div className="my-3 ps-2">
                      <h4 className="fs-18 fs-md-16 roboto-bold">{value.title}</h4>
                      <span
                        className="fs-18 fs-md-16 roboto-medium py-2"
                        style={{ color: "#2C2C2Cs" }}
                      >
                        {value.description}
                      </span>
                    </div>
                  </Link>
                  <Col xl={4} lg={5} className="d-flex">
                    <Row className="justify-content-between align-self-center">
                      <Col>
                        <button
                          className="btn px-4 py-2 text-white fs-20 fs-md-16 roboto-medium"
                          style={{ background: "#69C34D" }}
                          onClick={() => {
                            setEditData(value);
                            setEditCourseMedia(true);
                          }}
                        >
                          {lang?.edit}
                        </button>
                      </Col>
                      <Col>
                        <button
                          className="btn-red px-4 rounded border-0 py-2"
                          onClick={() => {
                            setDeleteId(value.id);
                            setDeleteModal(true);
                          }}
                        >
                          {lang?.delete}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
          </Col>
        )}
      </Row>
      {/* Add Modal */}
      <Modal
        isOpen={addCourseMedia}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9}
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 fs-md-16 roboto-bold">{lang?.add_media}</h4>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">{lang?.media_title}</label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2"
                disabled={isLoading}
                value={mediaTitle}
                onChange={(e) => setMediaTitle(e.target.value)}
                placeholder={lang?.enter_media_title}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.short_description}
              </label>
              <textarea
                onChange={(e) => setMediaDescription(e.target.value)}
                value={mediaDescription}
                disabled={isLoading}
                className="form-control mt-2"
                placeholder={lang?.write_media_desc}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                }}
                cols="30"
                rows="6"
              ></textarea>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <button
                disabled={
                  isLoading ||
                  !mediaTitle ||
                  !mediaDescription ||
                  mediaTitle.length > 150
                }
                className="border-0 btn-blue py-3 w-100"
                style={{ borderRadius: "6px" }}
                onClick={() => {
                  handleAddMedia();
                }}
              >
                {isLoading ? <div className="loader"></div> : lang?.save}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="bg-white py-3 w-100 fs-16 roboto-bold"
                style={{ border: "1px solid #707070", borderRadius: "6px" }}
                onClick={() => {
                  setMediaDescription("");
                  setMediaTitle("");
                  setAddMediaSection(false);
                }}
              >
                {lang?.cancel}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Edit Modal */}
      <Modal
        isOpen={editCourseMedia}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9}
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 fs-md-16 roboto-bold">{lang?.edit_media}</h4>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">{lang?.media_title}</label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2"
                disabled={isLoading}
                value={editData.title}
                onChange={(e) => {
                  const update = {
                    ...editData,
                    title: e.target.value,
                  };
                  setEditData(update);
                }}
                placeholder={lang?.enter_media_title}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.short_description}
              </label>
              <textarea
                value={editData.description}
                disabled={isLoading}
                onChange={(e) => {
                  const update = {
                    ...editData,
                    description: e.target.value,
                  };
                  setEditData(update);
                }}
                className="form-control mt-2"
                placeholder={lang?.write_media_desc}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                }}
                cols="30"
                rows="6"
              ></textarea>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <button
                disabled={
                  isLoading ||
                  !editData.description ||
                  !editData.title ||
                  editData.title.length > 150
                }
                className="border-0 btn-blue py-3 w-100"
                style={{ borderRadius: "6px" }}
                onClick={() => {
                  handleEditMedia();
                }}
              >
                {isLoading ? <div className="loader"></div> : lang?.save}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="bg-white py-3 w-100 fs-16 roboto-bold"
                style={{ border: "1px solid #707070", borderRadius: "6px" }}
                onClick={() => {
                  setEditData({});
                  setEditCourseMedia(false);
                }}
              >
                {lang?.cancel}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Delete Modal */}
      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div>
            <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
              {lang?.do_want_delete_media}
            </h2>
            <p className="fs-18 fs-md-16 roboto-regular">
              {lang?.do_want_delete_media_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="bg-white text-dark border-1 border rounded px-md-3 py-2"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded px-md-3 py-2"
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CourseSectionContent;
