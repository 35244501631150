import React, { useState } from "react";
import "./courseAccordion.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import pad_lock from "../../assets/images/icon/uil_padlock.svg"

const CourseChapters = () => {
  const [expandedChapter, setExpandedChapter] = useState(null);

  const chapters = [
    {
      title: "Foundations of Cybersecurity",
      lectures: 5,
      duration: "19 Mins",
    },
    {
      title: "Play It Safe: Manage Security Risks",
      lectures: 2,
      duration: "19 Mins",
    },
    {
      title: "Connect and Protect: Networks and Network Security",
      lectures: 3,
      duration: "19 Mins",
    },
    {
      title: "Tools of the Trade: Linux and SQL",
      lectures: 3,
      duration: "19 Mins",
    },
    {
      title: "Assets, Threats, and Vulnerabilities",
      lectures: 3,
      duration: "19 Mins",
    },
    {
      title: "Sound the Alarm: Detection and Response",
      lectures: 1,
      duration: "19 Mins",
    },
  ];

  const toggleChapter = (index) => {
    setExpandedChapter(index === expandedChapter ? null : index);
  };

  return (
    <div className="container" style={{ marginTop: "-75px", marginBottom: "2px",
      marginLeft: "78px",
      marginRight: "452px" }}>
      <h5 className="fw-bold text-primary">Course Chapters</h5>
      <p className="text-muted">23 Sections • 123 Videos • 22h 28m Total length</p>
      <div className="accordion chap_accordion" id="courseChaptersAccordion">
        {chapters.map((chapter, index) => (
          <div className="accordion-item border-0 mb-2" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button d-flex align-items-center text-dark shadow-sm rounded"
                type="button"
                style={{ backgroundColor: "#e8f9fc" }}
                onClick={() => toggleChapter(index)}
                aria-expanded={expandedChapter === index}
                aria-controls={`collapse${index}`}
              >
                {/* Dynamic Chevron Icon */}
                {expandedChapter === index ? (
                  <FaChevronUp className="me-3" style={{ fontSize: "1.2rem" }} />
                ) : (
                  <FaChevronDown className="me-3" style={{ fontSize: "1.2rem" }} />
                )}
                {/* Title and Lecture Details */}
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <span>{chapter.title}</span>
                  <div className="text-muted small">
                    <img src={pad_lock} alt="" /> {"  "}
                    {chapter.lectures} Lectures • {chapter.duration}
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${
                expandedChapter === index ? "show" : ""
              }`}
              aria-labelledby={`heading${index}`}
              data-bs-parent="#courseChaptersAccordion"
            >
              <div className="accordion-body">
                <p className="text-muted">Details about this chapter...</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseChapters;




// import React from "react";

// const CourseChapters = () => {
//   const chapters = [
//     {
//       title: "Foundations of Cybersecurity",
//       lectures: 5,
//       duration: "19 Mins",
//     },
//     {
//       title: "Play It Safe: Manage Security Risks",
//       lectures: 2,
//       duration: "19 Mins",
//     },
//     {
//       title: "Connect and Protect: Networks and Network Security",
//       lectures: 3,
//       duration: "19 Mins",
//     },
//     {
//       title: "Tools of the Trade: Linux and SQL",
//       lectures: 3,
//       duration: "19 Mins",
//     },
//     {
//       title: "Assets, Threats, and Vulnerabilities",
//       lectures: 3,
//       duration: "19 Mins",
//     },
//     {
//       title: "Sound the Alarm: Detection and Response",
//       lectures: 1,
//       duration: "19 Mins",
//     },
//   ];

//   return (
//     <div className="container " style={{marginTop: "-75px" ,marginBottom:"2px"}} >
//       <h5 className="fw-bold">Course Chapters</h5>
//       <p className="text-muted">
//         23 Sections • 123 Videos • 22h 28m Total length
//       </p>
//       <div className="accordion" id="courseChaptersAccordion">
//         {chapters.map((chapter, index) => (
//           <div className="accordion-item border-0 mb-2" key={index}>
//             <h2 className="accordion-header" id={`heading${index}`}>
//               <button
//                 className="accordion-button text-dark shadow-sm rounded d-flex justify-content-between"
//                 type="button"
//                 style={{backgroundColor: "#e8f9fc"}}
//                 data-bs-toggle="collapse"
//                 data-bs-target={`#collapse${index}`}
//                 aria-expanded={index === 0 ? "true" : "false"}
//                 aria-controls={`collapse${index}`}
//               >
//                 <span>{chapter.title}</span>
//                 <div className="text-muted small">
//                   <i className="bi bi-lock me-2"></i>
//                   {chapter.lectures} Lectures • {chapter.duration}
//                 </div>
//               </button>
//             </h2>
//             <div
//               id={`collapse${index}`}
//               className={`accordion-collapse collapse ${
//                 index === 0 ? "show" : ""
//               }`}
//               aria-labelledby={`heading${index}`}
//               data-bs-parent="#courseChaptersAccordion"
//             >
//               <div className="accordion-body">
//                 <p className="text-muted">Details about this chapter...</p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CourseChapters;
