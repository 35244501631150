import { useContext } from "react";
import { Language } from "../../App";

export const GetNotificationBody = ({ type, notificationType }) => {
    const { lang } = useContext(Language);
    const messages = {
      social: {
        follow: lang.started_following_you,
        comment: lang.commented_on_your_post,
        like: lang.liked_your_post,
        repost: lang.reposted_your_post,
      },
      webinar: {
        reserve: lang.reserved_your_webinar,
        invite: (isSingle) =>
          isSingle ? lang.invited_you_to_his_webinar : lang.invited_you_to_their_webinar,
        start: lang.your_webinar_has_been_started,
      },
      clinical: {
        reserve: lang.reserved_your_clinical_study,
        comment: lang.commented_on_your_clinical_study_post,
        like: lang.liked_your_clinical_study_post,
        repost: lang.reposted_your_clinical_study_post,
      },
      course: {
        purchase: lang.purchased_your_course,
      },
      event:{
        join:lang.joined_event,
        start:lang.event_has_been_started
      },
      "clinical-session": {
        invite: lang.invited_you_to_his_clinical_session,
        started: lang.clinical_session_has_been_started,
      },
      wallet: {
        payment: lang.made_a_payment_to_your_wallet,
        refund: lang.received_a_refund_in_your_wallet,
      },
    };
  
    const typeMessages = messages[type];
    if (!typeMessages) return ""; // Fallback if the type is not recognized
  
    const message = typeMessages[notificationType];
    if (!message) return ""; // Fallback if the notificationType is not recognized
  
    // Handle dynamic messages (like "invite" in webinars)
    return typeof message === "function" ? message(type === "webinar" && notificationType === "invite") : message;
  };
  
  