
import { useContext, useState } from "react";
import { Language } from "../../../App";
import {
    Col, 
    Modal,
    ModalFooter,
    ModalHeader, 
    Row,
  } from "reactstrap";
const AddMultipleOptionModal = ({ isOpen, toggle, saveOptions }) => {
    const { lang } = useContext(Language);
    const [optionsData, setOptionsData] = useState([""]);
  
    const addOption = () => {
      const newOption = "";
      const updatedOptionsData = [...optionsData];
      updatedOptionsData.push(newOption);
      setOptionsData(updatedOptionsData);
    };
  
    const removeOption = (index) => {
      const updatedOptionsData = [...optionsData];
      updatedOptionsData.splice(index, 1);
      setOptionsData(updatedOptionsData);
    };
  
    const save = () => {
      saveOptions(optionsData.length); // Pass the count of options to the parent
      toggle();
    };
  
    return (
      <Modal isOpen={isOpen} centered  size="lg">
        <ModalHeader toggle={toggle}>Add Options</ModalHeader>
        <div className="p-3">
          {optionsData &&
            optionsData.map((value, index) => (
              <Row key={index}>
                <Col>
                  <Row>
                    <Col>
                      <label className="pb-1 fs-15 roboto-medium">
                        {lang?.option + " " + (index + 1)}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        className="py-2 form-control"
                        placeholder={lang?.option + " " + (index + 1)}
                        value={value}
                        onChange={(e) =>
                          setOptionsData((prev) =>
                            prev.map((option, i) =>
                              i === index ? e.target.value : option
                            )
                          )
                        }
                      />
                    </Col>
  
                    <Col md={1} className="align-self-center">
                      <button
                        className="rounded border-0 fw-bold px-2"
                        onClick={() => removeOption(index)}
                      >
                        x
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          <Row className="mt-3">
            <Col className="text-end">
              <button
                className="bg-white rounded text-black fw-bold px-4 py-1"
                style={{ border: "1px solid #1DC1DD" }}
                onClick={addOption}
              >
                {lang?.add_option}
              </button>
            </Col>
          </Row>
        </div>
        <ModalFooter className="gap-3">
          <button className="btn btn-secondary px-5" onClick={toggle}>
            {lang?.cancel}
          </button>
          <button className="btn-blue border-0 py-2 px-5" onClick={save}>
            {lang?.save}
          </button>
        </ModalFooter>
  
      </Modal>
    );
  };

  export default AddMultipleOptionModal