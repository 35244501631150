import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchHashtags from "../../components/search/SearchHashtags";
import { Filter } from "./Filter";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import { useLocation } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { Language, LoginUserData } from "../../App";
import { getAllSearchAgainstKeywordApi } from "../../services/searchApis";
import { toast } from "react-toastify";
import { ClinicalStudiesCard } from "../../components/search/ClinicalStudiesResults";
import { ShimmerSocialPost } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroller";

export default function ClinicalSessionsSearch() {
  const query = useLocation().search;
  const{lang}= useContext(Language)
  const keyword = decodeURIComponent(query.split("=")[1]);
  const { splashData } = useContext(LoginUserData);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const[date,setDate] = useState(null)
  const [sortBy,setSortBy] = useState(null)
  let sort = sortBy

  let specialization = selectedSpecializations
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage,refetch } =
    useInfiniteQuery({
      queryKey: ["search-clinical-studies", keyword],
      queryFn: ({ pageParam = 1 }) =>
        getAllSearchAgainstKeywordApi({keyword, type:"studies", pageParam,specialization,date,sort}),
      enabled: !!keyword,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const studies = data?.pages?.flatMap((page) => page.data.data);
  const leftAds = data?.pages?.flatMap((page) => page?.ads?.search_left);
  const rightAds = data?.pages?.flatMap((page) => page?.ads?.search_right);
  const hashTags = data?.pages?.flatMap((page) => page.hash_tags);
  useEffect(() => {
  
    setIsRefetching(true); // Show loading state when filters change
    refetch().finally(() => setIsRefetching(false)); // Hide loading state after refetch
  }, [selectedSpecializations,date,sort, refetch]);
  const storedValue = JSON.parse(localStorage.getItem("contest_obj"));

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
          <div className="position-relative px-0">
            <Filter
              title={lang.clinical_studies}
              options={[
                // { title: "Category", options: splashData?.home?.categories },
                {
                  title: lang.specialization,
                  options: splashData?.home?.subcategories,
                },
                { title: lang.sort_by, options: [lang.newest_to_oldest,lang.oldest_to_newest] },
                { title: lang.date, options: [] },
                // { title: "User", options: ["Doctor", "Hospital"] },
              ]}
              onSpecializationSelect={(specializations) => setSelectedSpecializations(specializations.map(spec => spec.value))}
              onSelectDate={(date)=>setDate(date)}
              onSortBy={(sort)=>setSortBy(sort)}
           
           />
          </div>
        </Row>
        <div className="d-flex justify-content-center gap-3">
          <div>
            <div
              className="position-sticky"
              style={{ width: "335px", top: "8.2rem" }}
            >
              <FeedAdvertisement ads={leftAds} new_contest_obj={storedValue}/>
            </div>
          </div>
          <div>
            <ClinicalSessionsList
              loading={isLoading||isRefetching}
              data={studies}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
          <div className="d-flex flex-column gap-3" style={{ width: "335px" }}>
            <SearchHashtags data={hashTags} />
            <FeedAdvertisement ads={rightAds} new_contest_obj={storedValue}/>
          </div>
        </div>
      </Container>
    </>
  );
}

const ClinicalSessionsList = ({
  loading,
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const{lang} = useContext(Language)
  return (
    <div
      className="bg-white border rounded"
      style={{ marginTop: "1rem", width: "570px" }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">{lang.clinical_studies}</h5>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (!isFetchingNextPage) {
            fetchNextPage();
          }
        }}
        hasMore={hasNextPage}
        loader={<ShimmerSocialPost type="image" />}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <ShimmerSocialPost type="image" key={index} />
            ))}
          </>
        ) : (
          data?.map((post, index) => (
            <ClinicalStudiesCard key={index} post={post} />
          ))
        )}
      </InfiniteScroll>
    </div>
  );
};
