import React, { useState, useEffect, useContext } from "react";
import ImageComponent from "../common/ImageComponent";
import { _imagesURL, BaseURL } from "../../constants/API";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import { EVENT_DETAILS } from "../../constants/RoutesUrl";
import { Language } from "../../App";

export default function UpcomingEventsCard({ thisMonth, upcomingMonth }) {
  const [loading, setLoading] = useState(true);
const {lang} = useContext(Language)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const colors = [
    {
      bg: "#E1F9FF",
      border: "#2FA4E4",
    },
    {
      bg: "#FFEAD5",
      border: "#FE844F",
    },
    {
      bg: "#FFF7DA",
      border: "#B08943",
    },
    {
      bg: "#FEECFF",
      border: "#984FFF",
    },
    {
      bg: "#FFF3EE",
      border: "#EE020E",
    },
  ];

  return (
    <div
      className="bg-white rounded shadow-sm position-sticky"
      style={{ width: "335px", height: "88vh", top: "5rem" }}
    >
      <div
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
        className="p-3"
      >
        <h6 className="roboto-medium mb-0 fs-16">{lang.upcoming_events}</h6>
      </div>
      <div className="overflow-y-auto scroll-hide" style={{ height: "82.3vh" }}>
        {loading ? (
          <div className="p-3">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
              <ShimmerCategoryItem
                key={index}
                hasImage
                imageType="thumbnail"
                imageWidth={90}
                imageHeight={90}
                text
                contentCenter
              />
            ))}
          </div>
        ) : (
          <div>
            {thisMonth.length > 0 && (
              <>
                <div className="p-3">
                  <p className="roboto-medium fs-12 text-gray mb-0">
                    {lang.this_month}
                  </p>
                </div>
                <div className="px-3 d-flex flex-column gap-1">
                  {thisMonth.map((event, index) => (
                    <EventCard
                      key={event.id}
                      event={event}
                      color={colors[index % colors.length]}
                    />
                  ))}
                </div>
              </>
            )}
            {upcomingMonth.length > 0 && (
              <>
                <div className="p-3">
                  <p className="roboto-medium fs-12 text-gray mb-0">
                    {lang.upcoming_month}
                  </p>
                </div>
                <div className="d-flex flex-column gap-1 px-3">
                  {upcomingMonth.map((event, index) => (
                    <EventCard
                      key={event.id}
                      event={event}
                      color={colors[index % colors.length]}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const EventCard = ({ event, color }) => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex flex-column p-2 mb-3 cursor"
      style={{
        flex: "1",
        borderRadius: "8px",
        backgroundColor: color.bg,
        borderLeft: `4px solid ${color.border}`,
        gap: "8px",
        color: "#031316",
      }}
      onClick={() => {
        navigate(EVENT_DETAILS + event.id);
      }}
    >
      <div
        className="d-flex flex-row align-items-start justify-content-start w-100 fs-14"
        style={{ gap: "8px" }}
      >
        <div>
          <ImageComponent
            src={_imagesURL + event.cover}
            compClassName="rounded object-fit-cover"
            img_height="47px"
            img_width="47px"
            loader_height="47px"
            loader_width="47px"
          />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start w-100 pt-2">
          <div
            className="w-100 roboto-medium fs-14"
            style={{ lineHeight: "20px" }}
          >
            {event.title}
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center fs-12 roboto-regular"
        style={{
          lineHeight: "17px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flexShrink: "0",
        }}
      >
        {event.start} - {event.end}
      </div>
      <div
        className="roboto-regular fs-12"
        style={{
          lineHeight: "110%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flexShrink: "0",
          overflow: "hidden",
          width: "270px",
        }}
      >
        {event.description}
      </div>
    </div>
  );
};
