import React, { useContext, useState, useEffect, useCallback } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { Language } from "../../App";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import { authorize } from "../../shared/authorize";

const ChatMediaModal = ({ isOpen, toggle, currentId }) => {
  const { lang } = useContext(Language);
  const [groupMedia, setGroupMedia] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");

  const fetchGroupMedia = useCallback(async () => {
    if (!currentId || !isOpen) return; // Guard condition
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${adminBaseURL}user/group-chat/conversation/media/${currentId}?page=${page}`,
        authorize()
      );

      const newMedia = response?.data?.data?.data || []; // Handle undefined data
      // setGroupMedia((prevMedia) => [...prevMedia, ...newMedia]);

      setGroupMedia((prevMedia) => {
        const existingKeys = new Set(
          prevMedia.map((item) => `${item.attachment}-${item.time}`)
        );
        const filteredMedia = newMedia.filter(
          (item) => !existingKeys.has(`${item.attachment}-${item.time}`)
        );
        return [...prevMedia, ...filteredMedia];
      });
      // Determine if more data is available
      setHasMore(response.data.current_page < response.data.last_page);
    } catch (error) {
      console.error("Failed to fetch group media", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentId, page, isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchGroupMedia();
    }
  }, [isOpen, fetchGroupMedia]);

  const loadMore = () => setPage((prevPage) => prevPage + 1);

  const handleImageClick = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    setIsImageModalOpen(true);
  };

  return (
    <>
      {/* Main Media Modal */}
      <Modal isOpen={isOpen} centered toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <h3 className="fs-18 fs-md-15 text-center m-0 text-ellips-149px">
            {lang?.media_photo_videos}
          </h3>
        </ModalHeader>
        <ModalBody>
          <div className="job-scroll">
            <div className="container-fluid">
              {isLoading && page === 1 ? ( // Show spinner only for the first load
                <div className="vh-100 d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={groupMedia.length}
                  next={loadMore}
                  hasMore={hasMore}
                  className="scroll-hide"
                  loader={
                    <div className="text-center py-2">
                      <Spinner />
                    </div>
                  }
                >
                  <div className="row text-center">
                    {groupMedia.length > 0 ? (
                      groupMedia.map((item) => (
                        <div
                          key={item.id}
                          className="col-md-3 my-1 p-1 text-center"
                        >
                          <div className="card p-1">
                            <img
                              src={_imagesURL + item.attachment} // Assuming `attachment` has the URL
                              alt={item.name || "Media"}
                              className="groupimageshadow rounded object-fit-cover cursor"
                              style={{ height: "250px", width: "100%" }}
                              onClick={() =>
                                handleImageClick(_imagesURL + item.attachment)
                              }
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-md-12 justify-content-center align-items-center text-center">
                        <p>
                          {lang?.no_photos_available || "No media available."}
                        </p>
                      </div>
                    )}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Image View Modal */}
      <Modal
        isOpen={isImageModalOpen}
        toggle={() => setIsImageModalOpen(!isImageModalOpen)}
        centered
        zIndex={1050}
      >
        <img
          src={clickedImageUrl}
          alt="..."
          className="img-fluid rounded"
          style={{ maxHeight: "80vh" }}
        />
      </Modal>
    </>
  );
};

export default ChatMediaModal;
