import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN } from "../../../constants/RoutesUrl";
import Pick from "../../../assets/images/icon/image.png";
import axios from "axios";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
  adminBaseURL,
  BaseURL,
  EmailVErificationApi,
  SignupApi,
} from "../../../constants/API";
import user from "../../../assets/images/auth/login_email.svg";
import lock from "../../../assets/images/auth/lock.svg";
import email_icon from "../../../assets/images/auth/login_email.svg";
import { CiLocationOn } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { FaHospitalAlt } from "react-icons/fa";
import Authlogo from "../../../assets/images/auth/newAuthLogo.svg";
import pending from "../../../assets/images/auth/pending.svg";
import { Language } from "../../../App";

function Doctor() {
  const { lang } = useContext(Language);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    hospitalName: "",
    medicalId: "",
    email: "",
    password: "",
    country: "",
    city: "",
    id_front: null,
    id_back: null,
  });
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [fetchingCities, setFetchingCities] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<FaRegEye />);
  const [modalPending, setModalPending] = useState(false);

  useEffect(() => {
    // Scroll to top when the page is loaded
    window.scrollTo(0, 0);
  }, []);

  // States for image previews
  const [frontImagePreview, setFrontImagePreview] = useState(null);
  const [backImagePreview, setBackImagePreview] = useState(null);

  // States for modals
  const [frontIdModal, setFrontIdModal] = useState(false);
  const [backIdModal, setBackIdModal] = useState(false);

  // Function to handle image file input
  const handleFileInputChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        [type]: file, // Update the specific type (id_front or id_back)
      }));

      // Preview the image
      const reader = new FileReader();
      reader.onloadend = () => {
        if (type === "id_front") {
          setFrontImagePreview(reader.result);
        } else if (type === "id_back") {
          setBackImagePreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const countriesData = localStorage.getItem("countriesData");
    if (countriesData) {
      setCountries(JSON.parse(countriesData));
    }
  }, []);

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(type === "password" ? <FaRegEyeSlash /> : <FaRegEye />);
  };

  const fetchCities = (country) => {
    setFetchingCities(true);

    setFormData((prevFormData) => ({
      ...prevFormData,
      city: "", 
    }));
    
    const url = adminBaseURL + "app/country/" + country;

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log("Response data:", data);
        setCities(data.data);
      })
      .catch((err) => console.error("Fetch error:", err))
      .finally(() => setFetchingCities(false));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = lang?.new_firstname_valid;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = lang?.new_lastname_valid;
    }
    if (!formData.userName.trim()) {
      newErrors.userName = lang?.new_username_valid;
    }
    if (!formData.hospitalName.trim()) {
      newErrors.hospitalName = lang?.new_hospitalname_valid;
    }
    if (!formData.email.trim()) {
      newErrors.email = lang?.new_emailaddress_valid;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = lang?.please_enter_valid_email;
    }
    if (!formData.password.trim()) {
      newErrors.password = lang?.new_password_valid;
    } else if (formData.password.length < 6) {
      newErrors.password = lang?.password_should_least_6char_long;
    }

    if (!formData.country) {
      newErrors.country = lang?.new_selectcountry_valid;
    }
    if (!formData.city) {
      newErrors.city = lang?.new_selectcity_valid;
    }

    // Image validation
    if (!formData.id_front) {
      newErrors.id_front = lang?.new_frontid_valid;
    } else if (!["image/jpeg", "image/png"].includes(formData.id_front.type)) {
      newErrors.id_front = lang?.new_frontid_valid_onlyimg;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setApiError("");

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const emailVerificationParams = {
        user_name: formData?.userName,
        email: formData?.email,
        doctor_id: formData?.medicalId,
      };

      try {
        // First API Call: Email Verification
        const emailVerificationResponse = await axios.post(
          EmailVErificationApi,
          emailVerificationParams
        );
        console.log(emailVerificationResponse);
        if (!emailVerificationResponse.data.status) {
          console.log(emailVerificationResponse.data);
          setApiError(
            emailVerificationResponse.data.message ||
              "Email verification failed. Please try again."
          );
          return;
        }

        // Second API Call: Signup
        const signupParams = {
          name: `${formData.firstName} ${formData.lastName}`,
          firstname: formData.firstName,
          lastname: formData.lastName,
          user_name: formData.userName,
          hospitalname: formData.hospitalName,
          doctor_id: formData.medicalId,
          email: formData.email,
          password: formData.password,
          country: formData.country,
          city: formData.city,
          user_type: "doctor",
          device: "website",
          device_name: "web_abc",
          token: "web_abc",
          id_front: formData.id_front,
          id_back: formData?.id_back,
          timezone: timeZone,
        };

        const signupResponse = await axios.post(SignupApi, signupParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Form submitted successfully:", signupResponse.data);

        if (signupResponse.data.status) {
          setModalPending(true); // Open success modal if the submission is successful
        } else {
          setApiError(
            signupResponse.data.action ||
              "An error occurred while submitting the form."
          );
        }
      } catch (error) {
        console.error(
          "Error in form submission:",
          error.response?.data || error.message
        );
        setApiError(
          error.response?.data.errors?.[0] ||
            error.response?.data?.action ||
            "An error occurred. Please try again."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  // Function to toggle the image preview modal
  const toggle = () => setFrontIdModal(!frontIdModal);

  const FrontIdModalexternalCloseBtn = (
    <button
      type="button"
      className="close btn-secondary border-0 rounded"
      style={{ position: "absolute", top: "15px", right: "15px" }}
      onClick={toggle}
    >
      &times;
    </button>
  );
  const backIdModalexternalCloseBtn = (
    <button
      type="button"
      className="close btn-secondary border-0 rounded"
      style={{ position: "absolute", top: "15px", right: "15px" }}
      onClick={() => setBackIdModal(null)}
    >
      &times;
    </button>
  );

  console.log(formData.city)

  return (
    <div>
      <Modal
        className="inst-box"
        isOpen={modalPending}
        centered
        toggle={() => setModalPending(true)}
      >
        <ModalBody className="px-4">
          <div className="d-flex flex-column justify-content-center">
            <img
              style={{ objectFit: "cover", width: "65px" }}
              src={pending}
              alt="PENDING"
            />
            <p className="mb-4 mt-4" style={{ fontFamily: "NotoSans-bold" }}>
              {lang?.new_approval_pending}
            </p>
            <p style={{ fontFamily: "NotoSans-medium", fontSize: "17px" }}>
              {lang?.new_approval_pending_desc}
            </p>
          </div>
        </ModalBody>
        <Link
          to="/"
          onClick={() => setModalPending(false)}
          style={{ cursor: "pointer" }}
          className=" text-decoration-none text-black"
        >
          <ModalFooter className="d-flex flex-column align-items-center justify-content-center">
            <button
              className="new_main_btn w-100 py-3"
              style={{ fontFamily: "NotoSans-bold" }}
            >
              {lang?.new_okay}
            </button>
          </ModalFooter>
        </Link>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="d-flex flex-column justify-content-center">
            <div
              className="setup-size pb-lg-0 pb-md-0 pb-sm-5 pb-xsm-5 pb-5"
              style={{ paddingTop: "75px" }}
            >
              <div className="log-box py-3 px-4">
                <div className="d-flex flex-column my-4 gap-2">
                  <h5 className="mb-0">{lang?.welcome_to} Medical Radar!</h5>
                  <p>{lang?.please_sign_up_account}</p>

                  <form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_firstname}</label>
                        {errors.firstName && (
                          <small className="text-danger">
                            {errors.firstName}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.new_enter_firstname}
                          value={formData.firstName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              firstName: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={user}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="USER"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_lastname}</label>
                        {errors.lastName && (
                          <small className="text-danger">
                            {errors.lastName}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.new_enter_lastname}
                          value={formData.lastName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              lastName: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={user}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="USER"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_username}</label>
                        {errors.userName && (
                          <small className="text-danger">
                            {errors.userName}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.enter_username}
                          value={formData.userName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              userName: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={user}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="USER"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_hospitalname}</label>
                        {errors.hospitalName && (
                          <small className="text-danger">
                            {errors.hospitalName}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.enter_hospital_name}
                          value={formData.hospitalName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              hospitalName: e.target.value,
                            });
                          }}
                        />
                        <FaHospitalAlt
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "14px",
                            opacity: "0.7",
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <label>{lang?.new_medicalid}</label>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.enter_medical_id}
                          value={formData.medicalId}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              medicalId: e.target.value,
                            });
                          }}
                        />
                        <FaHospitalAlt
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "14px",
                            opacity: "0.7",
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.email_address}</label>
                        {errors.email && (
                          <small className="text-danger">{errors.email}</small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type="text"
                          placeholder={lang?.enter_email_address}
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                          }}
                        />
                        <MdEmail
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "16px",
                            height: "16px",
                            opacity: "0.7",
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_pass}</label>
                        {errors.password && (
                          <small className="text-danger">
                            {errors.password}
                          </small>
                        )}
                      </div>
                      <div className="position-relative input-div">
                        <input
                          type={type}
                          placeholder={lang?.enter_password}
                          value={formData.password}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            });
                          }}
                        />
                        <img
                          src={lock}
                          className="position-absolute"
                          style={{
                            top: "18px",
                            left: "10px",
                            width: "12px",
                            opacity: "0.7",
                          }}
                          alt="LOCK"
                        />
                        <span
                          className="position-absolute"
                          style={{
                            right: "11px",
                            top: "15px",
                            color: "#7991A4",
                          }}
                          onClick={handleToggle}
                        >
                          {icon}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-3 gap-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>{lang?.new_country}</label>
                        {errors.country && (
                          <small className="text-danger">
                            {errors.country}
                          </small>
                        )}
                      </div>
                      <div className="position-relative">
                        <CiLocationOn
                          className="position-absolute input-icon"
                          size={20}
                        />
                        <select
                          style={{
                            padding: "13px 8px 13px 35px",
                            borderRadius: "10px",
                          }}
                          className="form-control"
                          onChange={(e) => {
                            const selectedCountry = e.target.value;
                            setFormData({
                              ...formData,
                              country: e.target.value,
                              city: "", 
                            });
                            fetchCities(selectedCountry);
                          }}
                        >
                          <option value="" disabled selected>
                            {lang?.select_country}
                          </option>
                          {countries.length === 0 && (
                            <option value="" disabled>
                              <h6>Loading ...</h6>
                            </option>
                          )}
                          {countries.map((value) => (
                            <option value={value.id}>{value.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {formData.country && (
                      <div className="d-flex flex-column mb-3 gap-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <label>{lang?.new_city}</label>
                          {errors.city && (
                            <small className="text-danger">{errors.city}</small>
                          )}
                        </div>
                        <div className="position-relative">
                          <CiLocationOn
                            className="position-absolute input-icon"
                            size={20}
                          />
                          <select
                            // onClick={fetchCities}
                            style={{ padding: "13px 8px 13px 35px" }}
                            className="form-control"
                            value={formData.city}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                city: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              {lang?.select_city}
                            </option>
                            {fetchingCities && (
                              <option value="" disabled>
                                <h6>Loading ...</h6>
                              </option>
                            )}
                            {!fetchingCities && cities.length === 0 && (
                              <option value="" disabled>
                                <h6>No city Found</h6>
                              </option>
                            )}
                            {cities.map((value) => (
                              <option value={value.id}>{value.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="mb-4">
                      <h4 className="fs-16 roboto-bold text-normal-black">
                        {lang?.we_need_verify_information}
                      </h4>
                      <p className="fs-16 roboto-regular text-normal-black">
                        {lang?.verify_information_desc1}
                        <br />
                        {lang?.verify_information_desccompl}
                        <br />
                        {lang?.verify_information_desc2}
                      </p>
                    </div>

                    <div className="mb-4 d-flex justify-content-center gap-3">
                      <div className="position-relative w-50">
                        {formData?.id_front && (
                          <div className="position-absolute end-0 me-3 mt-1">
                            <button
                              className="btn-blue border-0 px-2"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  id_front: null,
                                });
                                setFrontImagePreview(null);
                              }}
                            >
                              x
                            </button>
                          </div>
                        )}
                        <div
                          className="d-flex align-items-center justify-content-center bg-white cursor p-3 file-border rounded h-100 w-100"
                          onClick={() => {
                            if (formData?.id_front) {
                              setFrontIdModal(true);
                            } else {
                              document.getElementById("fileInput").click();
                            }
                          }}
                        >
                          <div className="text-center">
                            {frontImagePreview ? ( // Display the image preview if available
                              <div>
                                <img
                                  alt="picture"
                                  className="img-fluid"
                                  src={frontImagePreview}
                                />
                              </div>
                            ) : (
                              <>
                                {/* Display default content if no image is selected */}
                                <h3 className="fs-20 fs-md-15 roboto-bold text-blue">
                                  {lang?.id_front}
                                </h3>
                                <p className="fs-10 roboto-medium">
                                  ({lang?.new_required})
                                </p>
                                <img src={Pick} alt="picture" />
                              </>
                            )}
                          </div>
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            className="d-none"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              setFormData({
                                ...formData,
                                id_front: file, // Update the form data with the selected file
                              });

                              // Preview the image
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                setFrontImagePreview(reader.result);
                              };
                              reader.readAsDataURL(file);
                            }}
                          />
                        </div>
                      </div>

                      <div className="position-relative w-50">
                        {formData?.id_back && (
                          <div className="position-absolute end-0 me-3 mt-1">
                            <button
                              className="btn-blue border-0 px-2"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  id_back: null,
                                });
                                setBackImagePreview(null);
                              }}
                            >
                              x
                            </button>
                          </div>
                        )}
                        <div
                          className="d-flex align-items-center justify-content-center bg-white cursor p-3 file-border rounded h-100 w-100"
                          onClick={() => {
                            if (formData?.id_back) {
                              setBackIdModal(true);
                            } else {
                              document.getElementById("fileInput2").click();
                            }
                          }}
                        >
                          <div className="text-center">
                            {backImagePreview ? ( // Display the image preview if available
                              <div>
                                <img
                                  alt="picture"
                                  className="img-fluid"
                                  src={backImagePreview}
                                />
                              </div>
                            ) : (
                              <>
                                {" "}
                                {/* Display default content if no image is selected */}
                                <h3 className="fs-20 fs-md-15 roboto-bold text-blue">
                                  {lang?.id_back}
                                </h3>
                                <p className="fs-10 roboto-medium">
                                  {lang?.opti}
                                </p>
                                <img src={Pick} alt="picture" />
                              </>
                            )}
                          </div>
                          <input
                            type="file"
                            id="fileInput2"
                            accept="image/*"
                            className="d-none"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              setFormData({
                                ...formData,
                                id_back: file, // Update the form data with the selected file
                              });

                              // Preview the image
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                setBackImagePreview(reader.result);
                              };
                              reader.readAsDataURL(file);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.id_front && (
                      <small className="text-danger">{errors.id_front}</small>
                    )}

                    <p className="text-center text-danger">{apiError}</p>
                    <button
                      className="new_main_btn w-100 p-12 mb-lg-3 mb-md-2 mb-sm-2 mb-1"
                      type="submit"
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <span
                            className="spinner-border text-white"
                            style={{ width: "1.35rem", height: "1.35rem" }}
                          ></span>
                        </div>
                      ) : (
                        lang?.new_save_continue
                      )}
                    </button>

                    <p className="text-center mt-2 mb-0 res-pass ">
                      {lang?.already_account}
                      <Link
                        to="/"
                        className=" ms-1 text-center link-txt new_link"
                      >
                        {lang?.login}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            {/* Image Preview Modal */}
            <Modal
              isOpen={frontIdModal}
              toggle={toggle}
              external={FrontIdModalexternalCloseBtn}
              centered
              size={"lg"}
            >
              <ModalBody>
                {formData?.id_front && (
                  <div className="text-center">
                    <img
                      alt="picture"
                      className="img-fluid"
                      src={frontImagePreview}
                    />
                  </div>
                )}
              </ModalBody>
            </Modal>
            <Modal
              isOpen={backIdModal}
              toggle={() => setBackIdModal(!backIdModal)}
              external={backIdModalexternalCloseBtn}
              centered
              size={"lg"}
            >
              <ModalBody>
                {formData?.id_back && (
                  <div className="text-center">
                    <img
                      alt="picture"
                      className="img-fluid"
                      src={backImagePreview}
                    />
                  </div>
                )}
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Doctor;
