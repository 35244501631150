
import React, { useContext, useState } from 'react';
import { ShimmerPostItem } from "react-shimmer-effects";
import { useLocation } from 'react-router-dom';
import Categoryicon from "../../assets/images/icons/category_icon.svg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import SubCategoryCardShimmer from './SubCategoryCardShimmer';
import { Language } from '../../App';
export default function SubcategoryCards({ categories=[], onCategoryDataFetched, defaultCoursesData, defaultHeading, loading }) {
  const location = useLocation();
  
  // State to track the currently active category
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [isLoadingCategories, setIsLoadingCategories] = useState(loading);
  const {lang} = useContext(Language) 

  // Function to handle clicking on a category card
  const handleCategoryClick = (categoryId, name) => {
    if (activeCategoryId === categoryId) {
      // Deactivate category if the user clicks on the already active one
      setActiveCategoryId(null);
      onCategoryDataFetched(defaultCoursesData, defaultHeading, false, null);
      return;
    }
    
    // Set the clicked category as active
    setActiveCategoryId(categoryId);
    onCategoryDataFetched(null, name, isLoadingCategories, categoryId); // Only passing the selected categoryId and name to the parent
  };

  // Check if the current route is '/courses/all/:title' to hide the header conditionally
  const shouldHideHeader = location.pathname.startsWith(`/courses/all/trending`);
  const shouldHideHeaderDoc = location.pathname.startsWith(`/courses/all/following-doctor`);
  const shouldHideHeaderPopular = location.pathname.startsWith(`/courses/all/popular-courses`);
  const shouldHideHeaderPurchased = location.pathname.startsWith(`/courses/all/purchased-courses`);
  function divideArrayInTwoParts(arr) {
    const middleIndex = Math.floor(arr.length / 2);  // Find the middle index
    const firstHalf = arr.slice(0, middleIndex);     // First half from index 0 to middle
    const secondHalf = arr.slice(middleIndex);       // Second half from middle to end
    return [firstHalf, secondHalf];                  // Return both halves
  }
  
  const [firstPart, secondPart] = divideArrayInTwoParts(categories);
  

  
  const categoryCards1 = firstPart?.map((category) => (
    <>
    <CategoryCard 
      key={category.id} 
      category={category} 
      active={activeCategoryId === category.id} // Pass active status
      onClick={() => handleCategoryClick(category.id, category.name)} // Handle click event
    />
    </>
  ));
  const categoryCards2 = secondPart?.map((category) => (
    <>
    <CategoryCard 
      key={category.id} 
      category={category} 
      active={activeCategoryId === category.id} // Pass active status
      onClick={() => handleCategoryClick(category.id, category.name)} // Handle click event
    />
    </>
  ));
  return (
    <>
      <div className="mb-3">
        {!(shouldHideHeader || shouldHideHeaderDoc || shouldHideHeaderPopular || shouldHideHeaderPurchased) && (
          <h4 className="mb-0 fs-20 roboto-bold">{lang.explore_course_categories}</h4>
        )}
      </div>
      
      <div className="w-100 overflow-x-scroll scroll-hide">
      
          {loading ? (
            
       <SubCategoryCardShimmer/>
            
          ) : (
            // Render category cards once data is loaded
            <>
             <div className="d-flex gap-2 my-2">
            {categoryCards1}
            </div>
            <div className="d-flex gap-2 my-2">
            {categoryCards2}
            </div>
           
            </>
          
          )}
        </div>
      
    </>
  );
}



const CategoryCard = ({ category, active, onClick }) => {
  return (
    <div
      onClick={onClick} // Add onClick handler
      className={` br-8 shadow-sm p-3 cursor ${active ? "active-category" : ""}`}
      style={{ width: "fit-content", backgroundColor: active ? "#0DCAF0" : "#ffffff" }}
    >
      <div className="d-flex align-items-center gap-3">
        <img src={Categoryicon} width={42} height={42} alt="..." />
        <div>
          <h4 className="mb-1 fs-12 roboto-bold" style={{ whiteSpace: "nowrap",color:active&&"white" }}>
            {category.name}
          </h4>
          <p
            style={{ whiteSpace: "nowrap",color:active&&"white" }}
            className="mb-0 fs-12 roboto-medium "
          >
            {category.courses_count} Courses
          </p>
        </div>
      </div>
    </div>
  );
};


