import React, { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import axios from "axios";
import { adminBaseURL } from "../../constants/API";
import { authorize } from "../../shared/authorize";
import { ChatsContext } from "./ChatsProvider";
import { toast } from "react-toastify";
import { selectedChatContext } from "../../pages/chat/ChatModule";
import { Language } from "../../App";

const LeaveGroupModal = ({ isOpen, toggle, currentId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { fetchChatss } = useContext(ChatsContext);
  const { setSelectedChat } = useContext(selectedChatContext);
const{lang} = useContext(Language)
  const handleLeaveGroup = async () => {
    setIsLoading(true); // Show spinner and disable button
    try {
      // Replace with your actual API endpoint and payload
      const response = await axios.get(adminBaseURL+"user/group-chat/leave/"+currentId,authorize()); 
      console.log("Group Left Successfully:", response.data);

      // Optionally, you can handle success actions here
      toggle(); // Close modal after successful API call
      toast.success("Group Left Successfully")
      fetchChatss()
      setSelectedChat(null)
    } catch (error) {
      console.error("Failed to leave group:", error);
      toast.error(error)
      // Show an error toast or alert
    } finally {
      setIsLoading(false); // Hide spinner
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{lang.confirm_leave_group}</ModalHeader>
      <ModalBody>
        <p className="text-center">
          {lang.do_you_want_to_leave_group}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} disabled={isLoading}>
          {lang.cancel}
        </Button>
        <button className="btn px-4" style={{backgroundColor:"#1dc1dd",color:"white"}} onClick={handleLeaveGroup}>


          {isLoading ? <Spinner size="sm" /> : lang.yes}
          </button>
      </ModalFooter>
    </Modal>
  );
};

export default LeaveGroupModal;

