import { useContext } from "react";
import { Language } from "../../App";

export default function CoursesTabMenuCard({ title, count, active, onClick }) {
  const {lang} = useContext(Language)
  return (
    <div
      className="rounded-4 shadow-sm p-3 cursor"
      onClick={onClick}
      style={{
        background: active ? "#1DC1DD" : "#ffffff",
        color: active ? "white" : "black",
      }}
    >
      <div className="d-flex align-items-center gap-3">
        <div className={`rounded-4 p-2 ${active ? "bg-white" : "bg-dark"}`}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.2442 8.94173L24.0074 10.7423M15.7964 14.3194L19.1766 15.2204M15.965 24.4528L17.3165 24.8141C21.1415 25.8341 23.054 26.3426 24.5613 25.4771C26.0672 24.6129 26.5801 22.7103 27.6043 18.908L29.0536 13.5289C30.0792 9.72514 30.5906 7.82398 29.7208 6.32514C28.851 4.82631 26.9399 4.31773 23.1135 3.29914L21.762 2.93789C17.937 1.91789 16.0245 1.40931 14.5186 2.27489C13.0112 3.13906 12.4984 5.04164 11.4727 8.84398L10.0249 14.2231C8.99922 18.0268 8.48639 19.928 9.35764 21.4268C10.2275 22.9242 12.14 23.4342 15.965 24.4528Z"
              stroke={active ? "#1DC1DD" : "#ffffff"}
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.9987 28.675L14.6501 29.0434C10.8336 30.0818 8.92674 30.6017 7.42224 29.7191C5.92057 28.8379 5.40774 26.8999 4.38632 23.0211L2.9399 17.5358C1.91707 13.6584 1.40565 11.7189 2.27407 10.1918C3.0249 8.87002 4.6654 8.91819 6.7904 8.91819"
              stroke={active ? "#1DC1DD" : "#ffffff"}
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div>
          <p
            className={`fs-16 roboto-medium mb-1 ${
              active ? "text-white" : "text-muted"
            }`}
          >
            {title}
          </p>
          <h5 className="fs-20 roboto-bold">{count} {lang.courses}</h5>
        </div>
      </div>
    </div>
  );
}
