import React from 'react';
import './shimmerStyles.css'; // Make sure to include the shimmer CSS styles here

const ShimmerClinicalType = () => {
    return (
        <div
            className="shimmer-clinical-type-card shimmer-clinical-type-rounded shimmer-clinical-type-shadow shimmer-clinical-type-padding"
            style={{
                width: '100%', 
                height: '80px', 
                background: '#ffffff',
            }}
        >
            <div className="d-flex align-items-center gap-3">
                {/* Simulating the icon */}
                <div
                    className="shimmer-clinical-type-icon"
                    style={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#f0f0f0', // Shimmer color
                    }}
                ></div>
                {/* Simulating the text */}
                <div style={{ flexGrow: 1 }}>
                    <div className="shimmer-clinical-type-text mb-2" style={{ width: '120px', height: '16px', backgroundColor: '#f0f0f0' }}></div>
                    <div className="shimmer-clinical-type-text" style={{ width: '80px', height: '20px', backgroundColor: '#f0f0f0' }}></div>
                </div>
            </div>
        </div>
    );
};

export default ShimmerClinicalType;
