import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { Language } from "../../App";

const ClearSearchPopup = ({ isOpen, toggle, onConfirm,isClearing }) => {
  const{lang} = useContext(Language)
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}> <strong>{lang.clear_search_history}</strong></ModalHeader>
      <ModalBody>
      {lang.clear_search_para}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
         {lang.cancel}
        </Button>
        <button className="btn" disabled={isClearing} style={{backgroundColor:"#4ACDE4",color:"white"}} onClick={onConfirm}>
        {isClearing?<Spinner/>:lang.clear}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ClearSearchPopup;
