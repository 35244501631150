import React, { Fragment, useContext, useEffect, useState } from "react";
import SpecSpinner from "../manage_account/changeInterest/SpecSpinner";
import JobsPreview from "./JobsPreview";
import { AllJobsApi } from "../../constants/API";
import { toast } from "react-toastify";
import { Language } from "../../App";

const AllJobs = () => {
  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = SplashData?.user?.id;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const { lang } = useContext(Language);

  // Functions
  useEffect(() => {
    const clearSessionStorageOnReload = (event) => {
      sessionStorage.removeItem("prevJobsData");
      delete event["returnValue"];
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  useEffect(() => {
    let jsonData = null;

    try {
      const prevJobsData = sessionStorage.getItem("prevJobsData");
      jsonData = prevJobsData ? JSON.parse(prevJobsData) : null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    if (jsonData !== null) {
      setAllJobs(jsonData);
    } else {
      setIsLoading(true);
      fetch(`${AllJobsApi}${user_id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((res) => {
          if (res.status) {
            sessionStorage.setItem(
              "prevJobsData",
              JSON.stringify(res.data.data)
            );
            setAllJobs(res.data.data);
            setIsLoading(false);
          } else {
            toast.error(res.error);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setIsLoading(false);
        });
    }
  }, []);
  return (
    <>
      <div>
        <h1 className="fs-20 fs-sm-16 roboto-bold">{lang?.all_jobs}</h1>
      </div>
      {isLoading ? (
        <div className="profilejobspinner align-items-start">
          <SpecSpinner />
        </div>
      ) : (
        allJobs?.map((value, index) => {
          return (
            <Fragment key={index}>
              <JobsPreview
                id={value.id}
                user_id={value.user_id}
                job={value?.title}
                hospital_name={value?.hospital_name}
                location={value?.short_location}
                date={value?.time}
                description={value?.description}
                publisher_name={value?.user?.name}
                publisher_username={value?.user?.user_name}
                publisher_img={value?.user?.image}
              />
            </Fragment>
          );
        })
      )}
    </>
  );
};

export default AllJobs;
