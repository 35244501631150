// api.js

import { toast } from 'react-toastify';
import { authorize } from '../shared/authorize';
import { apiAdmin } from './api';
import { useMutation } from 'react-query';

export const fetchPayments = async () => {
    try {
  const response = await apiAdmin.get('user/web/payments', authorize());
  return response.data;}
  catch(error){
    toast.error('Error fetching Payments:', error);
        throw error;
  }
};
export const requestPayments = async (amount) => {
    try {
  const response = await apiAdmin.post('user/web/payments/withdraw/request',{amount}, authorize());
  return response.data;}
  catch(error){
    toast.error('Error fetching Payments:', error);
        throw error;
  }
};

export const savePaypalEmail = async (code) => {
    try {
      const response = await apiAdmin.post(
        'user/paypal/profile',
        { code },
        authorize()
      );
      return response.data;
    } catch (error) {
      toast.error('Error saving PayPal email:', error);
      throw error;
    }
  };
  
  // Function to remove PayPal account
  export const removePaypalAccount = async () => {
    try {
      const response = await apiAdmin.get('user/paypal/profile/remove', authorize());
      return response.data;
    } catch (error) {
      toast.error('Error removing PayPal account:', error);
      throw error;
    }
  };
  export const useRemovePaypalAccount = () => {
    return useMutation({
      mutationFn: removePaypalAccount,
      onError: (error) => {
        toast.error('Error removing PayPal account:', error);
      },
      onSuccess: (data) => {
        toast.success('PayPal account removed successfully');
      },
    });
  };
